import { useCallback, useContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { Button } from "@mui/material";
import GridRollover from "../../cui/components/GridRollOver/GridRollOver";
import { ModalTaxPortal } from "../../cui/components/ModalTaxPortal/ModalTaxPortal";
import { ModalGridRolloverFiles } from "../../cui/components/ModalGridRolloverFiles/ModalGridRolloverFiles";
import { ModalRolloverStatusDetails } from "../../cui/components/ModalRolloverStatusDetails/ModalRolloverStatusDetails";
import { useSnackbar } from "../../hooks";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import useRolloverRequest from "../../hooks/useRolloverRequest";

import { refreshFlagAtom } from "../../constants";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { ServiceMessage } from "../../api-client-nswag/taxportal-client";
import { SelectorType } from "../../cui/components/SelectorsHQ/SelectorsHQ.types";
import { NOTIFICATION_MESSAGES, ROLLOVER_OPTIONS, SERVICE_OPTIONS, PAGE_CAPTIONS } from "./Rollovers.constants";
import "./Rollovers.scss";
import { SelectBox, TextBox } from "devextreme-react";

const { MODAL_TITLE, ROLLOVER_PAGE_TITLE } = PAGE_CAPTIONS;
const { SCHEDULE, INSER_TOPIC, CANCEL_SCHEDULE } = SERVICE_OPTIONS;
const { INFORMATION_REQUEST, FILES, PROJECT_TRACKING } = ROLLOVER_OPTIONS;
const { OPENING_MESSAGE, INFORMATION_REQUEST_MSG, PROJECT_TRACKING_MSG, FILES_MSG_1, FILES_MSG_2, FILES_MSG_3_CS, CLOSING_MESSAGE, DEFAULT_SCHEDULE_DAYS } =
  NOTIFICATION_MESSAGES;
export default function Rollovers() {
  const { user } = useContext(OidcIdentityContext);
  const refreshFlag = useRecoilValue(refreshFlagAtom);
  
  const [selectedRollover, setSelectedRollovers] = useState<any>();
  const [newTaxYear, setNewTaxYear] = useState<any>('');
  const [isNewTaxYearValid, setNewTaxYearValid] = useState<boolean>(false);

  var siteID = sessionStorage.getItem("siteId") as string;
  const { data, fetchData, clientID: mdmClientId, scheduleDays, rolloverOptions, taxYear, newTaxValue, appName } = useRolloverRequest(parseInt(siteID));
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);
  const [isDisplayRolloverFilesModal, setIsDisplayRolloverFilesModal] = useState<boolean>(false);
  const [isDisplayStatusDetailsModal, setIsDisplayStatusDetailsModal] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<number>();
  const [selectedDetailsId, setSelectedDetailsId] = useState<number>();
  const [selection, setSelection] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const rolloverDropdown = rolloverOptions || []

  const rolloverOptionsDropdown = rolloverDropdown.map((el: any) => ({ ...el, name: ROLLOVER_OPTIONS[el.name] }));
  const rolloverOptionsArr: any[] = Array.from(rolloverDropdown);
  const optionSelected = rolloverOptionsArr?.some((value: any) => value.id === selectedRollover);
  const isValid = /^\d{4}$/.test(newTaxYear);

  const handleOpenModal = () => setIsDisplayModal(true);
  const handleCloseModal = () => setIsDisplayModal(false);

  const handleOpenRolloverFilesModal = (e: any) => {
    setSelectedFileId(e.row.data.id);
    setIsDisplayStatusDetailsModal(false);
    setIsDisplayRolloverFilesModal(true);
  };

  const handleCloseRolloverFilesModal = () => {
    setSelectedFileId(0);
    setIsDisplayRolloverFilesModal(false);
  };

  const handleOpenStatusDetailsModal = (e: any) => {
    setSelectedDetailsId(e.row.data.id);
    setIsDisplayRolloverFilesModal(false);
    setIsDisplayStatusDetailsModal(true);
  };

  const handleCloseStatusDetailsModal = () => {
    setSelectedDetailsId(0);
    setIsDisplayStatusDetailsModal(false);
  };

  const getSelectedOptionsObj = (option?: string, id?: number) => {
    return rolloverOptionsArr.filter((item) => (option ? item.name === option : Number(item['id']) == id));
  }

  const requestRollover = useCallback((selected?: string, processTrackId?: number) => {
      handleCloseModal();
      let data: ServiceMessage[] = [];
      if(selected) setNewTaxYear(newTaxValue);
      const selectedObj = selected ? getSelectedOptionsObj(selected, undefined) : getSelectedOptionsObj(undefined, selectedRollover);
      const isFileData = selectedObj.some((item) => item.name === 'FILES');
      if(isFileData && !selected && appName==='TP') {
        data = getDataToSend(selectedObj);
        showSnackbar({ message: SnackbarMessage.SCHEDULE_ROLLOVER });
        callService(SCHEDULE, { siteId: parseInt(siteID), data: data, currentTaxYear: taxYear, newTaxYear: newTaxYear});
        return;
      } else if(selected) {
        data = getDataToSend(selectedObj, processTrackId);
      } else {
        data = getDataToSend(selectedObj);
      }
      showSnackbar({ message: SnackbarMessage.ROLLOVER_PROGRESS });
      if (data.length) callService(INSER_TOPIC, { data });
      setNewTaxYear('');
    },
    [rolloverOptionsArr, selectedRollover]
  );

  const requestChecked = () => {
    requestRollover();
  };

  const cancelRollover = useCallback((rolloverId: number) => {
    showSnackbar({ message: SnackbarMessage.CANCEL_SCHEDULE });
    callService(CANCEL_SCHEDULE, { siteId: parseInt(siteID), rolloverId });
  }, []);

  const callService = async (option: string, params: any) => {
    try {
      const { siteId, rolloverId, data } = params;
      if (option === SCHEDULE) await taxPortalClientApi.taxPortal_ScheduleRollover(siteId, data, taxYear, newTaxYear);
      if (option === INSER_TOPIC) await taxPortalClientApi.taxPortal_InsertTopic(data);
      if (option === CANCEL_SCHEDULE) await taxPortalClientApi.taxPortal_CancelScheduledRollover(siteId, rolloverId);
      if (option !== INSER_TOPIC) fetchData();
    } catch (err: any) {
      const { status, response } = err;
      if (status === 409) {
        showSnackbar({ message: response, severity: "error" });
      } else {
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      }
    } finally {
      handleCloseModal();
    }
  };

  const getDataToSend = (rolloverSelected: SelectorType[], processTrackId?: number) => {
    return rolloverSelected.map(
      ({ id }): ServiceMessage => ({
        eventType: Number(id),
        mdmClientId,
        siteId: parseInt(siteID),
        userName: user?.profile.preferred_username,
        year: Number(taxYear),
        newTaxYear: processTrackId? Number(newTaxValue) : Number(newTaxYear),
        processTrackId,
      })
    );
  };

  const onRolloverChanged = useCallback((e: any) => {
    setSelectedRollovers(e.value);
  }, [selectedRollover]);

  const onNewTaxYearChanged = useCallback((e: any) => {
    setNewTaxYear(e.value);
    if(Number(e.value) > Number(taxYear)) {
      setNewTaxYearValid(true);
    } else {
      setNewTaxYearValid(false);
    }
  },[taxYear]);

  useEffect(() => {
    const optSelected = getSelectedOptionsObj(undefined, selectedRollover).map((opt) => opt.name);
    optSelected.map((option) => {
      if (option === 'FILES') {
        setSelection('FILES');
      } else {
        setSelection(option);
      }
    });
  }, [isDisplayModal, selectedRollover]);

  useEffect(() => {
    fetchData();
  }, [siteID, refreshFlag]);

  return (
    <section className="hq-page">
      <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>
        <header className="hq-page__rollover-header">
          <div className="rollover-container">
            <div className="tax-container">
              <div className="col">
                <h2 className="hq-title">Current Tax Year</h2>
                <div className="dx-field-value">
                  <TextBox
                    inputAttr={taxYear}
                    value={taxYear ? String(taxYear) : ''}
                    disabled={true}
                    width={120}
                  />
                </div>
              </div>
              <div className="col">
                <h2 className="hq-title">New Tax Year</h2>
                <div className="dx-field-value">
                  <TextBox
                    onValueChanged={onNewTaxYearChanged}
                    value={newTaxYear}
                    width={120}
                    valueChangeEvent="input"
                  />
                </div>
              </div>
            </div>
            <div className="rollover_options">
              <SelectBox
                id="rollOver"
                dataSource={rolloverOptionsDropdown}
                displayExpr="name"
                valueExpr="id"
                placeholder='Select a rollover process'
                onValueChanged={onRolloverChanged}
                width={220}
              />
              <Button onClick={handleOpenModal} variant="contained" className="hq_button" disabled={!(isValid && selectedRollover && isNewTaxYearValid)}>
                Go
              </Button>
            </div>
          </div>
        </header>
        <GridRollover
          data={data}
          handleOpenModal={handleOpenRolloverFilesModal}
          handleOpenStatusDetailsModal={handleOpenStatusDetailsModal}
          refreshFunction={fetchData}
          requestRollover={requestRollover}
          cancelRollover={cancelRollover}
        />
      </div>
      <ModalTaxPortal
        title={MODAL_TITLE}
        btnOpenText="Continue"
        maxWidth="xs"
        isDisplayModal={isDisplayModal}
        handleContinue={requestChecked}
        handleClose={handleCloseModal}
      >
        <div className="rollover-msg">
          <p>
            {OPENING_MESSAGE}
            {(selection === 'INFORMATION_REQUEST') && <>{INFORMATION_REQUEST_MSG}</>}
            {(selection === 'PROJECT_TRACKING') && <>{PROJECT_TRACKING_MSG}</>}
            {(selection === 'FILES' && appName === 'TP') && (
              <>
                {FILES_MSG_1.replace("%days%", String(scheduleDays))} {FILES_MSG_2}
              </>
            )}
            {(selection === 'FILES' && appName === 'CS') && (
              <>
                {FILES_MSG_3_CS}
              </>
            )}
          </p>
          <p>{CLOSING_MESSAGE}</p>
        </div>
      </ModalTaxPortal>
      <ModalGridRolloverFiles
        fileId={selectedFileId!}
        handleClose={handleCloseRolloverFilesModal}
        isDisplayModal={isDisplayRolloverFilesModal}
      />
      <ModalRolloverStatusDetails
        rolloverId={selectedDetailsId!}
        handleClose={handleCloseStatusDetailsModal}
        isDisplayModal={isDisplayStatusDetailsModal}
      />
    </section>
  );
}
