import * as React from "react";
import { styled } from "@mui/material/styles";
import { Chip, Paper, TextField, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TagFaces as TagFacesIcon, Close as CloseIcon } from "@mui/icons-material";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";
import { snackbarState } from "../../../cui/commonComponents/CustomSnackbar";

interface ChipData {
  key: number;
  label: string;
}

interface ChipProps {
  flag: boolean;
  setFlag: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export default function ChipsArray({ flag, setFlag, selectedData }: ChipProps) {
  const { user } = React.useContext(OidcIdentityContext);
  const userEmail = user?.profile.preferred_username || "";
  const appName = useRecoilValue(getAppName);
  const setSnackbar = useSetRecoilState(snackbarState);

  const [chipData, setChipData] = React.useState<ChipData[]>([{ key: 0, label: userEmail }]);
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);

  const resetState = () => {
    setChipData([{ key: 0, label: userEmail }]);
    setInputValue("");
    setError(false);
  };

  const handleDelete = (chipToDelete: ChipData) => {
    setChipData((prevChips) => prevChips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const handleAddChip = () => {
    if (!inputValue.trim()) return;
    
    if (!validateEmail(inputValue)) {
      setError(true);
      return;
    }
    setChipData((prevChips) => [...prevChips, { key: prevChips.length, label: inputValue.trim() }]);
    setInputValue("");
    setError(false);
  };

  const handleAssignUsers = async () => {
    resetState();
    setFlag(false);
    
    const roleName = appName === "CS" ? "CS - L2 Support" : "L2Support";
    setSnackbar({ open: true, message: `Assigning user to ${roleName} in progress...`, severity: "info" });

    try {
      await taxPortalClientApi.cEM_AddUsersToL2SupportInCEM(roleName, selectedData[0].mdmClientId, chipData.map((item) => item.label));
      setSnackbar({ open: true, message: `User Assignment successful`, severity: "success" });
    } catch {
      setSnackbar({ open: true, message: `User Assignment failed`, severity: "error" });
    }
  };

  return (
    <Dialog open={flag} onClose={() => setFlag(false)} fullWidth maxWidth="sm" className="cui-c_modal">
      <DialogTitle className="cui-c_modal-title">L2Support</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          resetState();
          setFlag(false);
        }}
        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ padding: "0 16px 10px 16px" }}>
        <TextField
          placeholder="User Email..."
          variant="filled"
          size="medium"
          value={inputValue}
          sx={{ margin: "0 0 8px 8px" }}
          error={error}
          helperText={error && "Invalid email format"}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleAddChip()}
        />

        <Paper sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", listStyle: "none", p: 0.5, m: 1 }} component="ul">
          {chipData.map((data) => (
            <ListItem key={data.key}>
              <Chip label={data.label} onDelete={() => handleDelete(data)} icon={data.label === "React" ? <TagFacesIcon /> : undefined} />
            </ListItem>
          ))}
        </Paper>
      </DialogContent>
      
      <DialogActions className="cui-c_modal-footer">
        <Button onClick={handleAssignUsers}>Add User</Button>
      </DialogActions>
    </Dialog>
  );
}
