import { useState, useEffect, useContext, useRef } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { Button, FormControl, IconButton, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import useSnackbar from "../../hooks/useSnackbar";

import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllSitesInfoDTO,
  SelectName,
  ServiceBusEnum,
  ServiceMessage,
  SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { dataGridConfig, defaultPageSize } from "../../constants";
import { updateSites } from "./UpdateSiteInfo.types";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { convertUTCTimeToLocal } from "../../util/Utils";
import { calculateGridHeight } from "../../util/Utils";
import "./UpdateSiteInfo.scss";
import AppNameToggle, { getAppName } from "../../cui/commonComponents/AppNameToggle";
import { useRecoilValue } from "recoil";
import { isEmpty, isNull } from "lodash";

type DatagridRefType = DataGrid;

export const UpdateSites = () => {
  const { user, logout } = useContext(OidcIdentityContext);
  const [data, setData] = useState<updateSites>({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropDown, setDropDown] = useState("");
  const { showSnackbar } = useSnackbar();
  const { INFORMATION_REQUEST, PROJECT_STATUS, DOCUMENT_METADATA, LEGAL_ENTITIES, SYNC_MDM, THIRD_PARTY_ORG, CONTROVERSY_TRACKER, POPULATE_MEMBERFIRM_DROPDOWN, UPDATE_K1TRACKER_ENTITY_DROPDOWN, UPDATE_DELIVERABLES_ENTITY_DROPDOWN } =
    SelectName;
  const dataGridRef = useRef<DatagridRefType>(null);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppName);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);

  const fetchSites = async () => {
    try {
      const syncType = (() => {
        if (dropDown === INFORMATION_REQUEST) return SyncTypeEnum.IR_CHOICES;
        if (dropDown === PROJECT_STATUS) return SyncTypeEnum.SYNC_ASSIGNMENT;
        if (dropDown === DOCUMENT_METADATA) return SyncTypeEnum.DM_CHOICES;
        if (dropDown === LEGAL_ENTITIES) return SyncTypeEnum.SYNC_ENTITY_ISHEET;
        if (dropDown === SYNC_MDM) return SyncTypeEnum.SYNC_MDM;
        if (dropDown === THIRD_PARTY_ORG) return SyncTypeEnum.PUSH_ORG_TO_ISHEET;
        if (dropDown === POPULATE_MEMBERFIRM_DROPDOWN) return SyncTypeEnum.POPULATE_MEMBERFIRM_DROPDOWN;
        if (dropDown === CONTROVERSY_TRACKER) return SyncTypeEnum.CONTROVERSY_TRACKER;
        if (dropDown === UPDATE_K1TRACKER_ENTITY_DROPDOWN) return SyncTypeEnum.UPDATE_K1TRACKER_ENTITY_DROPDOWN;
        if (dropDown === UPDATE_DELIVERABLES_ENTITY_DROPDOWN) return SyncTypeEnum.UPDATE_DELIVERABLES_ENTITY_DROPDOWN;
        return null;
      })();
      if (syncType) {
        setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
        const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
        const data = await taxPortalClientApi.taxPortal_GetAllSites(
          syncType,
          null,
          appName !== null ? appName : "PERF",
          startIndex,
          defaultPageSize
        );
        setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const handleSyncClick = async () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.SYNC_MDM,
        mdmClientId: e.mdmClientId,
      };
    });
    try {
      await taxPortalClientApi.taxPortal_InsertTopic(data);
      showSnackbar({ message: SnackbarMessage.SYNC_MDM });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  const handleUpdateClick = async (e: any): Promise<void> => {
    try {
      showSnackbar({ message: SnackbarMessage.UPDATE_SITE });

      const dropDownSelect: Record<string, ServiceBusEnum> = {
        [SYNC_MDM]: ServiceBusEnum.SYNC_MDM,
        [INFORMATION_REQUEST]: ServiceBusEnum.IR_CHOICES,
        [PROJECT_STATUS]: ServiceBusEnum.SYNC_CEM_HQ,
        [DOCUMENT_METADATA]: ServiceBusEnum.DM_CHOICES,
        [LEGAL_ENTITIES]: ServiceBusEnum.SYNC_ENTITY_ISHEET,
        [THIRD_PARTY_ORG]: ServiceBusEnum.PUSH_ORG_TO_ISHEET,
        [POPULATE_MEMBERFIRM_DROPDOWN]: ServiceBusEnum.POPULATE_MEMBERFIRM_DROPDOWN,
        [CONTROVERSY_TRACKER]: ServiceBusEnum.CONTROVERSY_TRACKER,
        [UPDATE_K1TRACKER_ENTITY_DROPDOWN]: ServiceBusEnum.UPDATE_K1TRACKER_ENTITY_DROPDOWN,
        [UPDATE_DELIVERABLES_ENTITY_DROPDOWN]: ServiceBusEnum.UPDATE_DELIVERABLES_ENTITY_DROPDOWN,
      };

      const selectedValue = dropDownSelect[dropDown];

      if (selectedValue) {
        const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
        const data = selectedData.map(
          (e: GetAllSitesInfoDTO): ServiceMessage => ({
            siteId: e.hqSiteId,
            userName: user?.profile.preferred_username,
            eventType: selectedValue,
            mdmClientId: e.mdmClientId,
          })
        );
      
        await taxPortalClientApi.taxPortal_InsertTopic(data);
      }
      setSelectedRows([]);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  const cellRender = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.lastModified)}</div>;
  };
  const names: SelectName[] = [
    SYNC_MDM,
    INFORMATION_REQUEST,
    PROJECT_STATUS,
    DOCUMENT_METADATA,
    LEGAL_ENTITIES,
    THIRD_PARTY_ORG,
    POPULATE_MEMBERFIRM_DROPDOWN,
    CONTROVERSY_TRACKER,
    UPDATE_K1TRACKER_ENTITY_DROPDOWN,
    UPDATE_DELIVERABLES_ENTITY_DROPDOWN,
  ];
  const cellRenderForDate = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.siteCreated)}</div>;
  };

  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const onSelectionChanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };
  const handleChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
    dataGridRef.current!.instance.clearSelection();
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchSites();
    },
  };

  useEffect(() => {
    setDropDown(SYNC_MDM);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchTerm === null || searchTerm?.length === 0) {
      fetchSites();
    }
    // eslint-disable-next-line
  }, [dropDown, pageNumber, appName, searchTerm]);

  const fetchSitesBySearchTerms = async () => {
    try {
      const syncType = (() => {
        if (dropDown === INFORMATION_REQUEST) return SyncTypeEnum.IR_CHOICES;
        if (dropDown === PROJECT_STATUS) return SyncTypeEnum.SYNC_ASSIGNMENT;
        if (dropDown === DOCUMENT_METADATA) return SyncTypeEnum.DM_CHOICES;
        if (dropDown === LEGAL_ENTITIES) return SyncTypeEnum.SYNC_ENTITY_ISHEET;
        if (dropDown === SYNC_MDM) return SyncTypeEnum.SYNC_MDM;
        if (dropDown === THIRD_PARTY_ORG) return SyncTypeEnum.PUSH_ORG_TO_ISHEET;
        if (dropDown === POPULATE_MEMBERFIRM_DROPDOWN) return SyncTypeEnum.POPULATE_MEMBERFIRM_DROPDOWN;
        if (dropDown === CONTROVERSY_TRACKER) return SyncTypeEnum.CONTROVERSY_TRACKER;
        if (dropDown === UPDATE_K1TRACKER_ENTITY_DROPDOWN) return SyncTypeEnum.UPDATE_K1TRACKER_ENTITY_DROPDOWN;
        if (dropDown === UPDATE_DELIVERABLES_ENTITY_DROPDOWN) return SyncTypeEnum.UPDATE_DELIVERABLES_ENTITY_DROPDOWN;
        return null;
      })();
      if (syncType) {
        setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });

        const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(
          syncType,
          appName !== null ? appName : "PERF",
          searchTerm
        );
        if (res != null) {
          setData({ pending: false, fulfilled: true, sites: res.data, totalCount: res.totalCount });
        } else {
          setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });
        }
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchSitesBySearchTerms();
    }
    // eslint-disable-next-line
  }, [dropDown, appName, searchTerm]);

  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <div>
        <div
          style={{
            display: "flex",
            margin: "10px 0 10px 0",
          }}
        >
          <h2>Entity, Project, Phase</h2>
          <AppNameToggle />
        </div>
      </div>

      <DataGrid
        height={calculateGridHeight(270)}
        onSelectionChanged={onSelectionChanged}
        selectedRowKeys={selectedRows}
        {...dataGridConfig}
        dataSource={data.sites}
        noDataText={data.pending ? "Loading..." : "No data"}
        allowColumnResizing={true}
        ref={dataGridRef}
      >
        <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />
        {/* <SearchPanel visible={true} width={240} placeholder="Search..." /> */}
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {/* <Paging enabled={true} defaultPageSize={20} /> */}
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <Toolbar>
          {/* <Item location="after">
            <Button onClick={handleSyncClick} disabled={!selectedRows.length} variant="outlined">
              Sync MDM
            </Button>
          </Item> */}
          <Item location="after">
            <FormControl size="small" variant="outlined">
              <Select
                value={dropDown}
                style={{ height: 36, fontSize: "14px" }}
                defaultValue="Sync MDM"
                onChange={handleChange}
              >
                {names.map((name) => (
                  <MenuItem
                    disabled={(appName === "CS" && name !== "Sync MDM" && name !== "Member Firm Organizations")
                              || (appName === "TP" && name === "Member Firm Organizations")
                    }
                    key={name}
                    value={name}
                    style={{ fontSize: "14px" }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item location="after">
            <Button onClick={handleUpdateClick} disabled={!selectedRows.length} variant="outlined">
              Update Sites
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
        </Toolbar>
        <Scrolling columnRenderingMode="virtual" />
        <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"}></Column>
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"}></Column>
        <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"}></Column>
        <Column dataField="hqClientName" caption={"SiteName"}></Column>
        <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"}></Column>
        <Column
          width="190px"
          cellRender={cellRenderForDate}
          dataField="Site Created Date / Time"
          caption={"Site Created Date / Time"}
        />
        <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
        <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
        <Column
          width="190px"
          cellRender={cellRender}
          dataField="Last Update Date/Time"
          caption={"Last Update Date/Time"}
        />
      </DataGrid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "5px 0 0 0",
        }}
      >
        <Pagination
          onChange={(e: any, pageNumber: any) => {
            setpageNumber(pageNumber);
          }}
          count={Math.ceil((data.totalCount as number) / defaultPageSize)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};
