import { useCallback, useRef, useState, useEffect, useContext } from "react";
import { TreeView } from "devextreme-react";
import CheckBox from "devextreme/ui/check_box";
import RadioGroup from "devextreme/ui/radio_group";
import { ItemClickEvent, Node, SelectionChangedEvent } from "devextreme/ui/tree_view";
import { Backdrop, Button, CircularProgress } from "@mui/material";

import { FilesInspection, MoveHighQFiles, MoveIManageFiles } from "../../api-client-nswag/taxportal-client";
import { ModalCopyFiles } from "../../cui/components/ModalCopyFiles/ModalCopyFiles";
import { Tree } from "../../cui/components/TreeView/TreeView";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { useContainerDimensions, useSnackbar } from "../../hooks";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { IManageComponentProps, IManageFilesDTO, IManageFilesVars, iManageProps, taxPortalProps } from "./IManageFilesTypes";
import { OidcIdentityContext } from "@rsmus/react-auth";
import "./IManageFiles.scss";
import IManageFilesDialog from "../../cui/components/IManageFiles/IManageFilesDialog";
import ForwardIcon from "@mui/icons-material/Forward";
import { Typography, Divider, Box } from "@mui/material";
import { IMANAGEFILE_SWAP_TITLE, IMANAGEFILE_TITLE } from "../../constants";
import { getFilePath,getFolderPath,findHighQEntity } from "./IManageTaxPortalHelper";

type TreeViewRefType = TreeView;

export default function IManageFiles({
  onItemExpanded,
  showMoreLabel,
  filesToMove,
  foldersToShow,
  siteID,
  onSourceBoolChange,
}: IManageComponentProps) {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerDimensions(componentRef);
  const { showSnackbar } = useSnackbar();
  const { user } = useContext(OidcIdentityContext);
  const iManageTreeRef = useRef<TreeViewRefType>(null);
  const taxPortalTreeRef = useRef<TreeViewRefType>(null);
  const [isDisplayOverlay, setIsDisplayOverlay] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Node<any>[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<Node<any>[]>([]);
  const [selectedFoldersIManage, setSelectedFoldersIManage] = useState<Node<any>[]>([]);
  const [filesInspection, setFilesInspection] = useState<FilesInspection>({
    all: [],
    duplicated: [],
  });
  const [itemEdited, setItemEdited] = useState<Array<number>>([]);
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isBlurEnabled, setIsBlurEnabled] = useState(false);
  const arePairSelected = !selectedFiles.length || !selectedFolder.length;
  const isOneSelected = !!selectedFoldersIManage.length || !!selectedFolder.length;
  const [isSwap, setisSwap] = useState(false);
  const abortController = new AbortController();
  const [open, setOpen] = useState(false);
  const [iManageSource, setiManageSource] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    unselectAll();
    swapSource();
  };

  const onItemCollapsed = (e: ItemClickEvent<any>) => {
    showMoreLabel(e);
    e.component.beginUpdate();
    const collapse = (current: any) => {
      if (current.expanded) {
        e.component.collapseItem(current.id);
      }
      for (var i = 0; i < current.items.length; i++) {
        collapse(current.items[i]);
      }
    };
    if (e.itemData!.items) {
      e.itemData!.items.forEach((node) => collapse(node));
    }
    e.component.endUpdate();
  };

  const handleChangedFiles = (e: SelectionChangedEvent<any>) => {
    setSelectedFiles(e.component.getSelectedNodes().filter((item: Node<any>) => item.itemData!.itemType === "file"));
    setSelectedFoldersIManage(e.component.getSelectedNodes());
  };

  const handleChangedFolder = (e: SelectionChangedEvent<any>) => {
    setSelectedFolder(e.component.getSelectedNodes());
  };

  const filesDuplicated = useCallback(
    (isFileDuplicated: boolean): MoveIManageFiles[] =>
      filesInspection?.all.filter((file) => file?.isDuplicated === isFileDuplicated),
    [filesInspection.all]
  );

  const moveFilesService = useCallback(
    async (data: MoveIManageFiles[]) => {
      const filesToMoveCleaned = data.map(({ isEdited, isOmitted, ...rest }) => rest);
      const moveFilesRequest = {
        data: filesToMoveCleaned,
        siteId: siteID,
        userEmail: user?.profile.preferred_username,
      };
      showSnackbar({ message: SnackbarMessage.MOVE_IMANAGE_FILES });
      try {
        setIsDisplayOverlay(true);
        const response = await taxPortalClientApi.taxPortal_MoveIManageFilesToTaxPortal(
          moveFilesRequest,
          iManageSource,
          abortController.signal
        );
        const { message, success } = response;
        unselectAll();
        showSnackbar({ message, severity: success ? "success" : "info" });
      } catch (error: any) {
        setIsDisplayOverlay(true);

        if (error.status === IManageFilesVars.CONFLICT_ERROR) {
          const { response } = error;
          showSnackbar({ message: response.message, severity: "error" });
          setFilesInspection({
            ...filesInspection,
            all: response.filesForInspection,
          });
          setIsDisplayModal(true);
        } else {
          showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
      } finally {
        setIsDisplayOverlay(false);
      }
    },
    [filesInspection, siteID, iManageSource]
  );

  const moveFilesFromHighQtoIManage = useCallback(
    async (data: MoveHighQFiles[]) => {

      const filesToMoveCleaned = data.map(({ isEdited, isOmitted, ...rest }) => rest);
      const moveFilesRequest = {
        data: filesToMoveCleaned,
        siteId: siteID,
        userEmail: user?.profile.preferred_username,
      };
      showSnackbar({ message: SnackbarMessage.MOVE_TAXPORTAL_FILES });
      try {
        setIsDisplayOverlay(true);
        const response = await taxPortalClientApi.taxPortal_MoveTaxPortalFilesToIManage(
          moveFilesRequest,
          abortController.signal
        );
        const { message, success } = response;
        unselectAll();
        showSnackbar({ message, severity: success ? "success" : "info" });
      } catch (error: any) {
        setIsDisplayOverlay(true);
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      } finally {
        setIsDisplayOverlay(false);
      }
    },
    [siteID, iManageSource]
  );

  const unselectAll = () => {
    iManageTreeRef.current!.instance.unselectAll();
    taxPortalTreeRef.current!.instance.unselectAll();
  };

  const handleCopyRequest = async () => {
    if (iManageSource) {
      const data = selectedFiles.map((item: Node<any>): MoveIManageFiles => {
        return {
          sourceDocumentId: item.itemData!.id as string,
          sourceContainerId: item.parent!.itemData!.id as string,
          targetParentFolderId: selectedFolder[0].itemData!.id as string,
          fileName: item.itemData!.text as string,
          documentId: 0,
        };
      })
      moveFilesService(data);
    }
    else {
      const data = selectedFiles.map((item: Node<any>): MoveHighQFiles => {
        const fullFilePath = getFilePath(item.itemData!, foldersToShow.data);
        const hqEntity = findHighQEntity(item.itemData!, foldersToShow.data);
        const hqEntityId = hqEntity?.id ? parseInt(hqEntity.id, 10) : 0;
        const { id: workspaceName, path: destinationFolderPath } = getFolderPath(selectedFolder[0]);
 
        return {
          siteId: siteID,
          hQFileId: item.itemData!.id as string,
          hQItemId: item.itemData!.hqItemId as string,
          fileName: item.itemData!.text as string,
          workspaceName,
          hQEntityID: hqEntityId,
          hQParentFolderId: item.parent!.itemData!.id as string || '',
          hQParentFolderPath: fullFilePath,
          hQEntityName: hqEntity?.text as string,
          destinationFolderPath
        };
      });
      moveFilesFromHighQtoIManage(data);
    }
  };


 


  const handleCloseModal = () => {
    setItemEdited([]);
    setIsDisplayModal(false);
    setIsBlurEnabled(false);
  };

  const renderChecksEnabledOnFilesOnly = (e: any) => {
    const checkBoxEl = e.itemElement.parentElement.querySelector(".dx-checkbox");
    const checkBox = CheckBox.getInstance(checkBoxEl);
    checkBox.option("iconSize", 14);

    if (e.itemData!.itemType === "folder") {
      checkBox.option("disabled", true);
      checkBoxEl.style.background = "#ddd";
    }
  };

  const renderRadioButtons = (e: any) => {
    const checkBoxEl = e.itemElement.parentElement.querySelector(".dx-checkbox");
    const checkBox = CheckBox.getInstance(checkBoxEl);

    const isLevelAllowed = (e.itemData.level >= 1 && e.itemData.level <= 3);
    const shouldDisable = !iManageSource && isLevelAllowed;

    if (shouldDisable) {
      e.itemElement.style.pointerEvents = "none";

      const toggleButton = e.itemElement.querySelector(".dx-treeview-toggle-item-visibility");
      if (toggleButton) {
        toggleButton.style.pointerEvents = "auto";
        toggleButton.style.opacity = "1";
      }
      checkBox.option("disabled", true);
    }


    if (!shouldDisable) {
      const radioGroupEl = document.createElement("DIV");
      e.itemElement.parentElement.prepend(radioGroupEl);

      const radioGroup = new RadioGroup(radioGroupEl, {
        items: [{ value: true }],
        valueExpr: "value",
        value: checkBox.option("value"),
        onValueChanged(args: any) {
          checkBox.option("value", args.value);
        }
      });

      const defaultHandler: any = checkBox.option("onValueChanged");
      checkBox.option("onValueChanged", (args: any) => {
        radioGroup.option("value", args.value);
        defaultHandler(args);
      });
    }

    checkBox.option("visible", false);
  };

  const renderTreeViewItem = (item: any) => {
    if (item.text === "Show More" || item.text === "Hide") {
      return (
        <span>
          <b>{item.text}</b>
        </span>
      );
    } else {
      return (
        <>
          <i className={`dx-icon dx-icon-${item.icon}`}></i>
          <span>{item.text}</span>
        </>
      );
    }
  };

  iManageProps.noDataText = filesToMove.pending ? "Loading data..." : "No data to display";
  iManageProps.items = filesToMove.data;
  iManageProps.selectedCounter = String(selectedFiles.length);
  iManageProps.onSelectionChanged = isSwap ? handleChangedFolder : handleChangedFiles;
  iManageProps.selectionMode = isSwap ? "single" : "multiple";
  iManageProps.onItemRendered = isSwap ? renderRadioButtons : renderChecksEnabledOnFilesOnly;
  iManageProps.onItemExpanded = onItemExpanded;
  iManageProps.onItemCollapsed = onItemCollapsed;
  iManageProps.itemRender = renderTreeViewItem;
  iManageProps.width = width - 10;

  taxPortalProps.noDataText = foldersToShow.pending ? "Loading data..." : "No data to display";
  taxPortalProps.items = foldersToShow.data;
  taxPortalProps.onSelectionChanged = isSwap ? handleChangedFiles : handleChangedFolder;
  taxPortalProps.onItemRendered = isSwap ? renderChecksEnabledOnFilesOnly : renderRadioButtons;
  taxPortalProps.selectionMode = isSwap ? "multiple" : "single";
  taxPortalProps.onItemCollapsed = onItemCollapsed;
  taxPortalProps.width = width - 10;

  const swapSource = () => {
    setisSwap((prev) => !prev);
  };
  const handleSwapClick = async () => {
    isOneSelected ? setOpen(true) : swapSource();
  };

  useEffect(() => {
    if (iManageProps.onItemRendered === renderChecksEnabledOnFilesOnly) {
      onSourceBoolChange(true, false);
      setiManageSource(true);
    } else {
      onSourceBoolChange(false, true);
      setiManageSource(false);
    }
  }, [isSwap]);

  useEffect(() => {
    setFilesInspection({
      all: filesInspection.all,
      duplicated: filesDuplicated(true),
      copy: filesDuplicated(false),
      initial: filesDuplicated(true),
    });
  }, [filesDuplicated]);

  const props = {
    filesInspection,
    isDisplayModal,
    setFilesInspection,
    setIsDisplayModal,
    itemEdited,
    setItemEdited,
    onHandleService: iManageSource ? moveFilesService : moveFilesFromHighQtoIManage,
    handleCloseModal,
    isBlurEnabled,
    setIsBlurEnabled,
  };

  return (
    <>
      <div>
        <Box sx={{ paddingLeft: 2 }}>
          <Typography variant="h6" component="h1" gutterBottom sx={{ fontSize: "15px !important", paddingTop: 2 }}>
            {iManageSource ? IMANAGEFILE_TITLE : IMANAGEFILE_SWAP_TITLE}
          </Typography>
          <Divider sx={{ background: "#099cde" }} />
        </Box>
      </div>
      <div className="hq-page">
        <div className="hq-imanage-files">
          <div className="hq-tree">
            <Tree {...taxPortalProps} reference={taxPortalTreeRef} />
          </div>
          <Button variant="outlined" className="button-centre" onClick={handleSwapClick}>
            {iManageSource ? (
              <ForwardIcon sx={{ color: "black", transform: "rotate(180deg)" }} />
            ) : (
              <ForwardIcon sx={{ color: "black" }} />
            )}
          </Button>
          <div className="hq-tree" ref={componentRef}>
            <Tree {...iManageProps} reference={iManageTreeRef} />
          </div>
        </div>
        <Button
          onClick={handleCopyRequest}
          disabled={arePairSelected}
          variant="outlined"
          className="hq_button button-right"
        >
          Copy
        </Button>
        <Button onClick={unselectAll} disabled={!isOneSelected} variant="outlined" className="hq_button button-right">
          Clear selection
        </Button>
      </div>
      <ModalCopyFiles {...props} />
      <Backdrop open={isDisplayOverlay}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <IManageFilesDialog open={open} handleClose={handleClose} handleContinue={handleContinue} />
    </>
  );
}
