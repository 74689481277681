import React, { useState, useCallback } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChipsArray from "./ChipsArray";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarState } from "../../../cui/commonComponents/CustomSnackbar";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color: theme.palette.mode === "dark" ? theme.palette.grey[300] : "rgb(55, 65, 81)",
    boxShadow: theme.shadows[3],
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface L2UserOptionProps {
  selectedData: { mdmClientId: number }[];
}

const L2UserOption: React.FC<L2UserOptionProps> = ({ selectedData }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [flag, setFlag] = useState(false);
  const open = Boolean(anchorEl);
  const setSnackbar = useSetRecoilState(snackbarState);
  const appName = useRecoilValue(getAppName);

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const showSnackbar = useCallback((message: string, severity: "info" | "success" | "error") => {
    setSnackbar({ open: true, message, severity });
  }, [setSnackbar]);

  const handleUnassign = useCallback(async () => {
    handleMenuClose();
    const roleName = appName === "CS" ? "CS - L2 Support" : "L2Support";
    showSnackbar(`Unassigning user from ${roleName} in progress...`, "info");
    try {
      await taxPortalClientApi.cEM_RemoveUsersToL2SupportInCEM(selectedData[0].mdmClientId, roleName);
      showSnackbar("User unassignment successful", "success");
    } catch {
      showSnackbar("User unassignment failed", "error");
    }
  }, [appName, selectedData, handleMenuClose, showSnackbar]);

  return (
    <div>
      <Button
        id="l2-user-button"
        aria-controls={open ? "l2-user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleMenuOpen}
        endIcon={<KeyboardArrowDownIcon />}
      >
        L2Support User
      </Button>
      <StyledMenu
        id="l2-user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{ "aria-labelledby": "l2-user-button" }}
      >
        <MenuItem disabled={!Boolean(selectedData.length === 1)} onClick={() => setFlag(!flag)}>
          Assign Me
        </MenuItem>
        <MenuItem disabled={!Boolean(selectedData.length === 1)} onClick={handleUnassign}>
          Unassign all L2
        </MenuItem>
      </StyledMenu>
      <ChipsArray setFlag={setFlag} flag={flag} selectedData={selectedData} />
    </div>
  );
};

export default L2UserOption;
