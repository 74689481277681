import React, { useContext, useEffect, useState } from "react";
import SharedLayoutContext from "./cui/components/layouts/sharedLayout/SharedLayout.context";
import { homeNavigationMenuData } from "./pages/home/Home.data";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import Cookies from "js-cookie";

import "./App.scss";
import Home from "./pages/home/Home";
import {
  Authenticated,
  Anonymous,
  OidcIdentityContext,
} from "@rsmus/react-auth";
import { Header, userAction } from "@rsmus/rsm-catt-ui";
import { User } from "oidc-client-ts";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { CattThemeProvider, Theme } from "./cui/components/CattThemeProvider";
import SharedLayout from "./cui/components/layouts/sharedLayout/SharedLayout";
import { CreateFolders } from "./pages/CreateFolders/createFolders";
import CreateSitesAutomate from "./pages/CreateSites/CreateSite&SetUp";
import { AddClients } from "./pages/CreateSites/pages/Addclients";
import { Clients } from "./pages/CreateSites/pages/Clients";
import SitesWithLegalEntities from "./pages/CreateSites/SitesWithLegalEntities";
import IManageFilesContainer from "./pages/IManageFiles/IManageFilesContainer";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import { CashTaxDashboard } from "./pages/powerBI/CorporateSight/CashTaxDashboard";
import { CS_GlobalDirect } from "./pages/powerBI/CorporateSight/CS_GlobalDirect";
import { CS_USDirect } from "./pages/powerBI/CorporateSight/CS_USDirect";
import { EFilingTracker } from "./pages/powerBI/CorporateSight/E-Filing Tracker";
import { PBIPlanningIdeas } from "./pages/powerBI/CorporateSight/PBIPlanningIdeas";
import { ProjectTrackerFull } from "./pages/powerBI/CorporateSight/ProjectTrackerFull";
import { ProjectTrackerLite } from "./pages/powerBI/CorporateSight/ProjectTrackerLite";
import { PowerBIReportIM } from "./pages/powerBI/PowerBIReportIM";
import { PowerBISiteIM } from "./pages/powerBI/PowerBISiteIM";
import Rollovers from "./pages/Rollovers/Rollovers";
import { ScheduleFiles } from "./pages/scheduleFiles/ScheduleFiles";
import SiteAdminAccess from "./pages/SiteAdmin/SiteAdminAccess";
import { SiteMetrics } from "./pages/SiteMetrics/SiteMetrics";
import { SiteSettingsManager } from "./pages/SiteSettingsManager/SiteSettingsManager";
import { ThirdpartyOrganization } from "./pages/ThirdPartyOrganisation/ThirdPartyOrganisation";
import { UpdateMetadataLocationFiles } from "./pages/UpdateMetadataLocationFiles/UpdateMetadataLocationFiles";
import { UpdateSites } from "./pages/UpdateScreen/UpdateSiteInfo";
import { UpgradeSites } from "./pages/UpgradeSites/UpgradeSites";
import { ExternalApiClient } from "./ExternalApiClient/ExternalApiClient";
import {CentralControversyTracker} from "./pages/CentralControversyTracker/CentralControversyTracker";
import {
  AlertColor,
  snackbarState,
} from "./cui/commonComponents/CustomSnackbar";
import {
  permissionFlag,
  refreshFlagAtom,
  upgradeSitesResponseAtom,
} from "./constants";
import { SnackbarMessage } from "./hooks/useSnackbar.types";
import { applicationTitle } from "@rsmus/rsm-catt-ui";

import appLogo from "./cui/assets/serviceIcon/si_trade.svg";
import SignOut from "./pages/signOut/SignOut";
import { isEmpty, isNull } from "lodash";
import { envProperties } from "./util/EnvironmentProperties";
import { CreateHubConnection, StartHub } from "./signalr/signalr-connection";
import { CEMUserClients, CEMUserDetails, CEMUserRecord } from "./api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "./api-client-nswag/taxportal-client-runtime";
import { GlobalTaxInformation } from "./pages/powerBI/GlobalTaxInformation";

export const allPermissions = atom({
  key: "allPermissions",
  default: {
    CreateSites: false,
    CreateSitesAutomate: false,
    CreateTasks: false,
    Assignments: false,
    PowerBI: false,
    CSPowerBI: false,
    AssignUsers: false,
    CreateFolders: false,
    UpdateMetadataLocationFiles: false,
    ScheduleFiles: false,
    Rollovers: false,
    IManageFiles: false,
    SiteSettingsManager: false,
    UpgradeSites: false,
    ThirdpartyOrganization: false,
    LeaderShip: false,
    CentralControversyTracker: false
  },
});
export const appPermission = atom({
  key: "appPermission",
  default: [""],
});
export const clientPermission = atom({
  key: "clientPermission",
  default: [""],
});
export const flagbool = atom<boolean>({
  key: "flagbool",
  default: false,
});

export const userPermissions = atom<string[]>({
  key: "userPermission",
  default: [],
});

export const flagUsageMetrics = atom<boolean>({
  key: "flagUsageMetrics",
  default: false,
});

export const externalClients = atom<CEMUserClients[]>({
  key: "externalClients",
  default: [],
});
export const CountryCode = atom<string | null | undefined>({
  key: "CountryCode",
  default: null,
});

export const App: React.FunctionComponent = () => {
  const { user, logout } = useContext(OidcIdentityContext);
  const setSnackbar = useSetRecoilState(snackbarState);
  const setRefreshFlag = useSetRecoilState(refreshFlagAtom);
  const [countryCode,setCountryCode] = useRecoilState(CountryCode)
  const applicationTitle: applicationTitle = {
    app: "Tax Portal Automation Center",
    xp: "",
    appTag: "TP",
    appIco: "trending_up",
    appIcoVariant: "outlined",
    logo: appLogo,
    clientLogo: null,
  };
  const userActions: userAction[] = [
    {
      url: "https://dev-tpac.rsmus.com/home",
      text: "TPAC Dev",
    },
  ];
  const [flag, setflag] = useState(false);
  const urlIncludes = envProperties.reactAppHighQUrl;

  const { setNavigationMenuButtons } = useContext(SharedLayoutContext);
  const [permission, setPermission] = useRecoilState(allPermissions);
  const [appPermissions, setAppPermissions] = useRecoilState(appPermission);
  const [clientPermissions, setClientPermissions] =
    useRecoilState(clientPermission);
  const [flagPermision, setFlagPermision] = useRecoilState(flagbool);
  const [userInfo, setUserInfo] = useState({
    uuid: null,
    roles: [],
  });
  
  const [clientData, setClientData] = useRecoilState(externalClients);
  const [usageMetricsFlag, setUsageMetricsFlag] =
    useRecoilState(flagUsageMetrics);

  const [userPermission, setUserPermission] = useRecoilState(userPermissions);
  const setUpgradeSitesResponse = useSetRecoilState(upgradeSitesResponseAtom);
  const [url, seturl] = useState("");

  const handleSnackbar = (message: string, type: AlertColor) => {
    isJsonString(message)
      ? setUpgradeSitesResponse(message)
      : showSnackbar(message, type);
  };
  const isJsonString = (message: string) => {
    try {
      JSON.parse(message);
      return true;
    } catch (e) {
      return false;
    }
  };

  const showSnackbar = (message: string, type: AlertColor) => {
    setSnackbar({
      open: true,
      message: message,
      severity: type,
    });
    if (message.includes("UsageMetrics")) {
      setUsageMetricsFlag(true);
    }
    setRefreshFlag((prevState: any) => !prevState);
  };
  const apiClient = new ExternalApiClient();
  useEffect(() => {
    setNavigationMenuButtons(homeNavigationMenuData);
  });

  useEffect(() => {
    if (window.self !== window.top) {
      setflag(true);
      // The app is opened in an iframe
      console.log("App is in an iframe 193");
      //window.location.reload();
    } else {
      // The app is not in an iframe
      console.log("App is not in an iframe");
    }
    if (user !== undefined && !user.expired) {
      const hubConnection = CreateHubConnection(
        `${envProperties.ApiBackendUrl}/hubs/notifications`,
        user?.profile.preferred_username as string,
        user?.access_token as string
      );
      StartHub(hubConnection, handleSnackbar);
    }
  }, [user?.profile.rsmuid]);

  useEffect(() => {
    if (!isNull(document.referrer) && !isEmpty(document.referrer)) {
      const splitUri = urlIncludes?.split("/");
      if (
        document.referrer.length > 0 &&
        splitUri?.length > 2 &&
        (document.referrer.includes(splitUri[2]) ||
          document.referrer.includes(splitUri[5]))
      ) {
        setflag(true);
      } else {
        setflag(false);
      }
      if (document.referrer?.split("siteID=")[1]?.split("&")[0] !== undefined) {
        var siteId = document.referrer?.split("siteID=")[1]?.split("&")[0];
        if (!isNull(siteId) && !isEmpty(siteId)) {
          sessionStorage.setItem("siteId", siteId);
        }
      }
    } else {
      const url = document.location.href ? document.location.href : "";
      if (url?.includes("siteID=")) {
        const urlParams = new URL(url);
        const params = new URLSearchParams(urlParams.search);
        sessionStorage.setItem("siteId", params.get("siteID")!);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.profile.rsmuid) {
          const data = await apiClient.getCEMPermissions();
          setFlagPermision(true);
          if (data !== null && data.application !== undefined) {
            setAppPermissions(data?.application[0]?.permissions);
            setAppPermissions((prev) => [
              ...prev,
              ...data?.application[0]?.features
            ]);
          }
          if (data !== null && data.client !== undefined) {
            setClientPermissions(data?.client[0]?.permissions);
            setClientPermissions((prev) => [
              ...prev,
              ...data?.client[0]?.features,
            ]);
          }
          var combinedPermissions: any;
          var combinedRoles: any;
          if (data.application !== undefined) {
            combinedPermissions = new Set([
              ...(data?.application[0]?.permissions || []),
              ...(data?.application[0]?.features || []),
            ]);
            combinedRoles = new Set([...(data?.application[0]?.role || [])]);
          }
          if (data.client !== undefined) {
            combinedPermissions = new Set([
              ...(data?.client[0]?.permissions || []),
              ...(data?.client[0]?.features || []),
            ]);
            combinedRoles = new Set([...(data?.client[0]?.role || [])]);
          }

          if (data.client !== undefined && data.application !== undefined) {
            combinedPermissions = new Set([
              ...(data?.application[0]?.permissions || []),
              ...(data?.client[0]?.permissions || []),
              ...(data?.application[0]?.features || []),
              ...(data?.client[0]?.features || []),
            ]);
            combinedRoles = new Set([
              ...(data?.application[0]?.role || []),
              ...(data?.client[0]?.role || []),
            ]);
          }

          setUserInfo((e: any) => {
            return { ...e, roles: [...combinedRoles] };
          });

          setUserPermission(Array.from(combinedPermissions));
        }
      } catch (error) {
        console.log(error, "error");
        handleSnackbar(SnackbarMessage.ERROR, "error");
      }
    };
    fetchData();
  }, [user?.profile.rsmuid]);

  useEffect(() => {
    if (permissionFlag.permission === true) {
      if (appPermissions.includes("CreateSites")) {
        setPermission((e: any) => {
          return { ...e, CreateSites: true };
        });
      }
      if (appPermissions.includes("CreateSitesAutomate")) {
        setPermission((e: any) => {
          return { ...e, CreateSitesAutomate: true };
        });
      }
      if (appPermissions.includes("CreateTasks")) {
        setPermission((e: any) => {
          return { ...e, CreateTasks: true };
        });
      }
      if (appPermissions.includes("Assignments")) {
        setPermission((e: any) => {
          return { ...e, Assignments: true };
        });
      }
      if (
        clientPermissions.includes("PowerBI") ||
        appPermissions.includes("PowerBI")
      ) {
        setPermission((e: any) => {
          return { ...e, PowerBI: true };
        });
      }
      if (appPermissions.includes("CreateFolders")) {
        setPermission((e: any) => {
          return { ...e, CreateFolders: true };
        });
      }
      if (appPermissions.includes("AssignUsers")) {
        setPermission((e: any) => {
          return { ...e, AssignUsers: true };
        });
      }
      if (appPermissions.includes("UpdateMetadataLocationFiles")) {
        setPermission((e: any) => {
          return { ...e, UpdateMetadataLocationFiles: true };
        });
      }
      if (appPermissions.includes("ScheduleFiles")) {
        setPermission((e: any) => {
          return { ...e, ScheduleFiles: true };
        });
      }
      if (appPermissions.includes("Rollovers")) {
        setPermission((e: any) => {
          return { ...e, Rollovers: true };
        });
      }
      if (appPermissions.includes("SiteSettingsManager")) {
        setPermission((e: any) => {
          return { ...e, SiteSettingsManager: true };
        });
      }
      if (
        appPermissions.includes("CS PowerBI") ||
        clientPermissions.includes("CS PowerBI")
      ) {
        setPermission((e: any) => {
          return { ...e, CSPowerBI: true };
        });
      }
      if (appPermissions.includes("UpgradeSites")) {
        setPermission((e: any) => {
          return { ...e, UpgradeSites: true };
        });
      }
      if (appPermissions.includes("ThirdpartyOrganization")) {
        setPermission((e: any) => {
          return { ...e, ThirdpartyOrganization: true };
        });
      }
      if (appPermissions.includes("LeaderShip")) {
        setPermission((e: any) => {
          return { ...e, LeaderShip: true };
        });
      }
      if (appPermissions.includes("CentralControversyTracker")) {
        setPermission((e: any) => {
          return { ...e, CentralControversyTracker: true };
        });
      }
    } else {
      setPermission({
        CreateSites: true,
        CreateSitesAutomate: true,
        CreateTasks: true,
        Assignments: true,
        PowerBI: true,
        CSPowerBI: true,
        CreateFolders: true,
        AssignUsers: true,
        UpdateMetadataLocationFiles: true,
        ScheduleFiles: true,
        Rollovers: true,
        IManageFiles: true,
        SiteSettingsManager: true,
        UpgradeSites: true,
        ThirdpartyOrganization: true,
        LeaderShip: true,
        CentralControversyTracker: true
      });
    }
  }, [appPermissions, clientPermissions]);
  useEffect(() => {
    const stringUserInfo = JSON.stringify(userInfo);
    Cookies.set("OBUSIN", stringUserInfo, { sameSite: "None", secure: true });
    const deleteCookie = () => {
      Cookies.remove("OBUSIN", { path: "/", sameSite: "None", secure: true });
    };
    window.addEventListener("beforeunload", deleteCookie);
    return () => {
      window.removeEventListener("beforeunload", deleteCookie);
      deleteCookie();
    };
  }, [userInfo.roles, userInfo.uuid]);
  // const taskTemplateSelector = selector({
  // 	key:'charValueSelector',
  // 	get:({get})=>{
  // 	  const text = get(taskTemplateData);
  // 	  return text
  // 	}
  //   })

  const iframeContent = `
  <p>
    <script type="text/javascript">
      (function() {
        var walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = 'https://cdn.walkme.com/users/323277ea8a76498da728d2978cd1e2c6/test/walkme_323277ea8a76498da728d2978cd1e2c6_https.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = { smartLoad: true };
      })();
    </script>
  </p>
`;
  useEffect(() => {
    const checkTokenExpiry = () => {
      const currentTime = Math.floor(Date.now() / 1000);

      if (user?.expires_at !== undefined) {
        if (currentTime >= user?.expires_at) {
          console.log(`${window.location.origin}/signout`);
          window.location.href = `${window.location.origin}/signout`;
        }
      }
    };
    checkTokenExpiry();
    const intervalId = setInterval(checkTokenExpiry, 60000);
    return () => clearInterval(intervalId);
  }, [user?.expires_at]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.profile.rsmuid) {
          const userDetails: CEMUserDetails = {
            userId: user?.profile.rsmuid as string,
            userName: user?.profile.preferred_username as string,
          };
          const filterData: CEMUserRecord = await taxPortalClientApi.cEM_UpdateSitesForUser(userDetails); 
          setUserInfo((e:any)=>{
            return {...e, uuid: filterData.tpuuid}
          })
          setCountryCode(filterData.countryCode)
          if(filterData.cemUserClients !== undefined){
            setClientData(filterData.cemUserClients);
          }
        }
      } catch (error) {
        window.location.href = `${window.location.origin}/signout`; 
        handleSnackbar(SnackbarMessage.ERROR, "error");
      }
    };
    fetchData();
  }, [user?.profile.rsmuid]);

  const routerConfig = [
    {
      element: (
        <Authenticated>
          <>
            {(url?.split("siteID=")[1]?.split("&")[0] === undefined &&
              url?.split("siteID=")[1]?.split("&")[0] === null) ||
            flag === false ? (
              <Header
                appTitle={applicationTitle}
                userActionList={userActions}
                user={user as User}
                logout={logout}
              />
            ) : (
              <></>
            )}
          </>

          {flagPermision ? (
            <SharedLayout
              maxWidth={undefined}
              isNavigationBarEnabled={true}
              applicationTitle={applicationTitle}
              headerUserActionLinks={userActions}
              hqSiteId={url?.split("siteID=")[1]?.split("&")[0]}
              flag={flag}
            />
          ) : null}
        </Authenticated>
      ),
      children: [
        {
          path: "/",
          element: (
            <Home setUserInfo={setUserInfo} flag={flag} setflag={setflag} />
          ),
        },
        // {
        //   path: "/createTask",
        //   element: permission.CreateTasks ? <CreateTask /> : <Navigate to="/" />,
        // },
        {
          path: "/powerBI-information-tracker",
          element: <PowerBIReportIM />,
          loader: () => {
            return <PowerBIReportIM />;
          },
        },
        {
          path: "/powerBI-site-information-tracker",
          element: <PowerBISiteIM />,
          loader: () => {
            return <PowerBISiteIM />;
          },
        },
        {
          path: "/Planning-Ideas",
          element: <PBIPlanningIdeas />,
          loader: () => {
            return <PBIPlanningIdeas />;
          },
        },
        {
          path: "/Project-tracker-Lite",
          element: <ProjectTrackerLite />,
          loader: () => {
            return <ProjectTrackerLite />;
          },
        },
        {
          path: "/CS_GlobalDirect",
          element: <CS_GlobalDirect />,
          loader: () => {
            return <CS_GlobalDirect />;
          },
        },
        {
          path: "/CS_USDirect",
          element: <CS_USDirect />,
          loader: () => {
            return <CS_USDirect />;
          },
        },
        {
          path: "/CS_EFilingTracker",
          element: <EFilingTracker />,
          loader: () => {
            return <EFilingTracker />;
          },
        },
        {
          path: "/CS_ProjectTrackerFull",
          element: <ProjectTrackerFull />,
          loader: () => {
            return <ProjectTrackerFull />;
          },
        },
        {
          path: "/CashTaxDashboard",
          element: <CashTaxDashboard />,
          loader: () => {
            return <CashTaxDashboard />;
          },
        },
        {
          path: "/sites",
          element: permission.CreateSites ? (
            <SitesWithLegalEntities />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/CreateSitesAutomate",
          element: permission.CreateSitesAutomate ? (
            <CreateSitesAutomate />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/clients",
          element: permission.CreateSites ? <Clients /> : <Navigate to="/" />,
        },
        {
          path: "/addclients",
          element: permission.CreateSites ? (
            <AddClients />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/updateSites",
          element: permission.Assignments ? (
            <UpdateSites />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/siteSettingsManager",
          element: permission.SiteSettingsManager ? (
            <SiteSettingsManager />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/createFolder",
          element: permission.CreateFolders ? (
            <CreateFolders />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/siteAdminAccess",
          element: permission.AssignUsers ? (
            <SiteAdminAccess />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/updateMetadataLocationFiles",
          element: permission.UpdateMetadataLocationFiles ? (
            <UpdateMetadataLocationFiles />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/upgradeSites",
          element: permission.UpgradeSites ? (
            <UpgradeSites />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/scheduleFiles",
          element: permission.ScheduleFiles ? (
            <ScheduleFiles />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/thirdPartyOrganization",
          element: permission.ThirdpartyOrganization ? (
            <ThirdpartyOrganization />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/siteMetrics",
          element: permission.LeaderShip ? (
            <SiteMetrics />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "/rollovers",
          element: <Rollovers />,
          loader: () => {
            return <Rollovers />;
          },
        },
        {
          path: "globaltaxinformation",
          element: <GlobalTaxInformation />,
          loader: () => {
            return <GlobalTaxInformation />;
          },
        },
        
        {
          path: "/iManageFiles",
          element: <IManageFilesContainer />,
          loader: () => {
            return <IManageFilesContainer />;
          },
        },
        {
          path: "/centralControversyTracker",
          element: permission.CentralControversyTracker ? (
            <CentralControversyTracker />
          ) : (
            <Navigate to="/" />
          ),
        }
      ],
    },
    {
      element: <Anonymous> </Anonymous>,
      children: [
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ];

  const router = createBrowserRouter(routerConfig);

  return (
    <div className="home">
      {/* <iframe
      srcDoc={iframeContent}
      style={{ width: '100%', height: '500px', border: 'none' }}
    /> */}
      {window.location.href.includes("/signout") ? (
        <SignOut />
      ) : (
        <CattThemeProvider theme={Theme.BLUE}>
          <RouterProvider router={router} />
        </CattThemeProvider>
      )}

      {/* {useRecoilValue(taskTemplateSelector)} */}
    </div>
  );
};

export default App;
