import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DialougeProps } from "../../../pages/IManageFiles/IManageFilesTypes";
import { Button } from "@mui/material";
import { alert_Dialog_Discription } from "../../../constants";

const IManageFilesDialog = ({ open, handleClose, handleContinue }: DialougeProps) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
          },
        }}
      >
        <DialogContent sx={{ display: "flex", alignItems: "center", paddingBottom: "10px" }}>
          <InfoOutlinedIcon sx={{ color: "#009cde" }} />
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              paddingLeft: "10px !important",
              paddingBottom: "0px !important",
              paddingTop: "0px !important",
              backgroundColor: "#ffffff",
            }}
          >
            {"Swap Source"}
          </DialogTitle>
        </DialogContent>
        <DialogContent sx={{ borderTop: "1px solid #ebeceb" }}>
          <DialogContentText id="alert-dialog-description">{alert_Dialog_Discription}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#f0f0f0" }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleContinue} variant="contained" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IManageFilesDialog;
