import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  Toolbar,
  Item,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Button, FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import { dataGridConfig, defaultPageSize, refreshFlagAtom } from "../../constants";
import { SyncAllSitesColumnsConfig } from "./ControversyTrackerColumnConfig";
import "./CentralControversyTracker.scss";
import { calculateGridHeight } from "../../util/Utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { SyncCentralControversyTrackerData } from "./CentralControversyTracker.types";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllSitesDTO,
  SelectName,
  ServiceBusEnum,
  ServiceMessage,
  SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import useSnackbar from "../../hooks/useSnackbar";
import { isEmpty, isNull } from "lodash";

type DatagridRefType = DataGrid;
const initialGridData = { pending: true, AllSitesInfo: [], totalCount: 0 };
const { USER_SYNC, DELETED_RECORD_SYNC, LEGACY_RECORD_SYNC } = SelectName;
const dropDownOptions = [USER_SYNC, DELETED_RECORD_SYNC, LEGACY_RECORD_SYNC];

export default function SynchronizeCentralControversyTracker({ userEmail }: { userEmail: string }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [gridData, setGridData] = useState<SyncCentralControversyTrackerData>(initialGridData);
  const { showSnackbar } = useSnackbar();
  const [dropDown, setDropDown] = useState<string>(USER_SYNC);
  const dataGridRef = useRef<DatagridRefType>(null);
  const [selectedRows, setSelectedRows] = useState<GetAllSitesDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);

  const onChangePagination = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const fetchData = async () => {
    setGridData((prev: SyncCentralControversyTrackerData) => {
      return {
        ...prev,
        pending: true,
        AllSitesInfo: [],
      };
    });
    let eventType: SyncTypeEnum = SyncTypeEnum.CONTROVERSY_TRACKER_USERS_SYNC;
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      if (dropDown === SelectName.USER_SYNC) {
        eventType = SyncTypeEnum.CONTROVERSY_TRACKER_USERS_SYNC;
      } else if (dropDown === SelectName.DELETED_RECORD_SYNC) {
        eventType = SyncTypeEnum.CONTROVERSY_TRACKER_DELETED_RECORDS_SYNC;
      } else if (dropDown === SelectName.LEGACY_RECORD_SYNC) {
        eventType = SyncTypeEnum.CONTROVERSY_TRACKER_LEGACY_RECORD_SYNC;
      }
      const { data, totalCount } = await taxPortalClientApi.taxPortal_GetAllSites(
        eventType,
        null,
        "TP",
        startIndex,
        defaultPageSize
      );

      if (data) {
        setGridData({
          pending: false,
          AllSitesInfo: data,
          totalCount: totalCount,
        });
      } else {
        setGridData({ ...initialGridData, pending: false });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setGridData(initialGridData);
    }
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: () => {
      if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
        fetchSitesBySearchTerms();
      } else {
        fetchData();
      }
    },
  };
  const totalPages = useMemo(() => {
    return Math.ceil((gridData?.totalCount || 0) / defaultPageSize);
  }, [gridData?.totalCount]);

  const sendRequest = async (eventType: ServiceBusEnum) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map(
      (e: GetAllSitesDTO): ServiceMessage => ({
        siteId: e.hqSiteId,
        userName: userEmail,
        eventType,
        mdmClientId: e.mdmClientId,
        appName: "TP",
      })
    );
    try {
      await taxPortalClientApi.taxPortal_InsertTopic(data);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    } finally {
      dataGridRef.current!.instance.clearSelection();
    }
  };

  const handleRunClick = async () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    if (!selectedData.length) return;

    let eventType: ServiceBusEnum = ServiceBusEnum.CONTROVERSY_TRACKER_USERS_SYNC;

    if (dropDown === SelectName.USER_SYNC) {
      eventType = ServiceBusEnum.CONTROVERSY_TRACKER_USERS_SYNC;
      showSnackbar({
        message: SnackbarMessage.CONTROVERSY_TRACKER_USERS,
      });
    } else if (dropDown === SelectName.DELETED_RECORD_SYNC) {
      eventType = ServiceBusEnum.CONTROVERSY_TRACKER_DELETED_RECORDS_SYNC;
      showSnackbar({
        message: SnackbarMessage.CONTROVERSY_TRACKER_DELETED_RECORDS,
      });
    } else if (dropDown === SelectName.LEGACY_RECORD_SYNC) {
      eventType = ServiceBusEnum.CONTROVERSY_TRACKER_LEGACY_RECORD_SYNC;
      showSnackbar({
        message: SnackbarMessage.CONTROVERSY_TRACKER_LEGACY_RECORD,
      });
    }
    sendRequest(eventType);
  };

  const handleDropDownChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
    dataGridRef.current!.instance.clearSelection();
  };
  const onSelectionchanged = (e: any) => {
    setSelectedRows(e.selectedRowsData);
  };

  const fetchSitesBySearchTerms = async () => {
    setGridData({
      pending: true,
      AllSitesInfo: [],
      totalCount: 0,
    });
    try {
      let syncType: SyncTypeEnum = SyncTypeEnum.CONTROVERSY_TRACKER_USERS_SYNC;

      if (dropDown === SelectName.USER_SYNC) {
        syncType = SyncTypeEnum.CONTROVERSY_TRACKER_USERS_SYNC;
      } else if (dropDown === SelectName.DELETED_RECORD_SYNC) {
        syncType = SyncTypeEnum.CONTROVERSY_TRACKER_DELETED_RECORDS_SYNC;
      } else if (dropDown === SelectName.LEGACY_RECORD_SYNC) {
        syncType = SyncTypeEnum.CONTROVERSY_TRACKER_LEGACY_RECORD_SYNC;
      }
      if (syncType) {
        const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(syncType, "TP", searchTerm);
        if (res != null) {
          setGridData({
            pending: false,
            AllSitesInfo: res.data,
            totalCount: res.totalCount,
          });
        } else {
          setGridData({
            pending: false,
            AllSitesInfo: [],
            totalCount: 0,
          });
        }
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setGridData(initialGridData);
    }
  };

  useEffect(() => {
    if (searchTerm === null || searchTerm?.length === 0) {
      fetchData();
    }
  }, [pageNumber, dropDown, searchTerm]);

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchSitesBySearchTerms();
    }
  }, [dropDown, searchTerm]);

  return (
    <div>
      <div style={{ marginRight: "20px" }}>
        <DataGrid
          ref={dataGridRef}
          dataSource={gridData.AllSitesInfo || []}
          height={calculateGridHeight(270)}
          remoteOperations
          {...dataGridConfig}
          onSelectionChanged={onSelectionchanged}
          noDataText={gridData.pending ? "Loading..." : "No Data"}
        >
          <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
          <Scrolling columnRenderingMode="virtual" />
          <SearchPanel
            placeholder={"Global Search..."}
            onTextChange={(e) => {
              setSearchTerm(e);
            }}
            visible
          />
          <FilterRow visible />
          <HeaderFilter visible />
          <Paging enabled={false} />
          <Toolbar>
            <Item location="after">
              <FormControl size="medium" variant="outlined" sx={{ width: 300 }}>
                <Select
                  value={dropDown}
                  style={{ height: 36, fontSize: "14px" }}
                  defaultValue={USER_SYNC}
                  onChange={handleDropDownChange}
                >
                  {dropDownOptions.map((option) => (
                    <MenuItem key={option} value={option} style={{ fontSize: "14px" }}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item location="after">
              <Button onClick={handleRunClick} disabled={!selectedRows.length} variant="outlined">
                RUN
              </Button>
            </Item>
            <Item name="searchPanel" location="after" />
            <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
          </Toolbar>

          {SyncAllSitesColumnsConfig.map((col: any) => (
            <Column
              key={col.field}
              dataField={col.field}
              caption={col.caption}
              minWidth={col.minWidth}
              alignment={"left"}
              cellRender={col.cellRender}
            />
          ))}
        </DataGrid>

        <div className="grid-pagination">
          <Pagination onChange={onChangePagination} count={totalPages} variant="outlined" shape="rounded" />
        </div>
      </div>
    </div>
  );
}
