//@ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  SearchPanel,
  Toolbar,
  Item,
  MasterDetail,
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react";
import { Button, Pagination } from "@mui/material";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { clientData } from "./pages/Addclients";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../util/Utils";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { OidcIdentityContext } from "@rsmus/react-auth";
import {
  MDMLegalEntityDTO,
  GetAllRolesDTO,
  GetAllSitesInfoDTO,
  ServiceMessage,
} from "../../api-client-nswag/taxportal-client";
import SelectClientAndTemplate, {
  PermissionDataType,
} from "./selectClientAndTemplate/SelectClientAndTemplate";
import { DialogBox } from "./DialogBox";
import {
  ArchiveSitesDTO,
  ServiceBusEnum,
} from "../../api-client-nswag/taxportal-client";
import { SiteHistory } from "./siteHistory/SiteHistory";
import { isEmpty, isNull } from "lodash";
import { atom } from "recoil";
import AppNameToggle, {
  getAppName,
} from "../../cui/commonComponents/AppNameToggle";
import { SiteProcessData } from "./selectClientAndTemplate/SiteProcessData";
import { SiteProcessDataForCS } from "./selectClientAndTemplate/SiteProcessDataForCS";
import { statusState } from "./selectClientAndTemplate/SiteProcessData";
import { statusStateForCS } from "./selectClientAndTemplate/SiteProcessDataForCS";
import { SiteConfig } from "../../api-client-nswag/taxportal-client";
import SelectClientAndTemplateForIS from "./selectClientAndTemplate/SelectClientAndTemplateForIS";
import { envProperties } from "../../util/EnvironmentProperties";

import { defaultPageSize } from "../../constants";
import { getSites } from "./SitesWithLegalEntities";
import React from "react";
import L2UserOption from "./l2SupportOption/L2UserOption";
import SplitButton from "../../cui/commonComponents/SplitButton";
interface selectedOptionType {
  value: number;
  label: string | undefined;
}
interface gridLegalEntityType {
  pending: boolean;
  fulfilled: boolean;
  gridLegalData: MDMLegalEntityDTO[];
}
interface roleType {
  name?: string | undefined;
  roleId?: string | undefined;
}
interface GetSiteSettingsDTO {
  id: string | number;
  setting: string;
  description: string;
  value: string;
  actionDisplayName: string;
  actionUrl: string;
}

export const getSiteCreationResponse = atom({
  key: "getSiteCreationResponse",
  default: false,
});

export const getSiteExistResponse = atom({
  key: "getSiteExistResponse",
  default: "",
});

export const getExpandedRowData = atom({
  key: "getExpandedRowData",
  default: null,
});

export const getRoleData = atom<roleType[]>({
  key: "getRoleData",
  default: [{ name: null, RoleId: null }],
});

export const getTaxYearResponse = atom({
  key: "getTaxYearResponse",
  default: null,
});

export default function CreateSitesAutomate() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const dataGridRef = useRef(null);
  const [ds, setDs] = useState([]);
  const [data, setData] = useState<getSites>({
    pending: true,
    data: [],
    totalCount: 0,
  });
  const selection = useRecoilValue(clientData);
  const [selectedData, setSelectedData] = useState([]);
  const [tab, setTab] = useState(1);
  const { showSnackbar } = useSnackbar();
  const { showSuccessSnackbar } = useSnackbar();
  const { user } = useContext(OidcIdentityContext);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [selectedOption, setSelectedOption] =
    useState<selectedOptionType | null>(null);
  const [title, setTitle] = useState("");
  const appName = useRecoilValue(getAppName);
  const [status, setStatus] = useRecoilState(statusState);
  const [btnContinueDisable, setBtnContinueDisable] = useState(false);
  const [activeDiv, setActiveDiv] = useState("ACTIVE");
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [id, setId] = useRecoilState<number>(getExpandedRowData);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const templateRef = useRef<any>();
  const [roleData, setRolesData] = useRecoilState(getRoleData);
  const [isFlag, setIsFlag] = useRecoilState(getSiteCreationResponse);
  const [isDisplayDates, setisDisplayDates] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [siteConfig, setSiteConfig] = useState<SiteConfig>({});
  const [siteExistResponse, setIsSiteExistResponse] =
    useRecoilState(getSiteExistResponse);
  const [siteNameForIS, setsiteNameForIS] = useState("");
  const [pageNumber, setpageNumber] = useState(1);
  const taxYear = useRecoilValue(getTaxYearResponse);
  const taxYearForCS = useRecoilValue(getTaxYearResponse);
  const removeTaxYear = useResetRecoilState(getTaxYearResponse);
  const removeTaxYearForCS = useResetRecoilState(getTaxYearResponse);
  const [dataPermissionFinal, setDataPermissionFinal] = useState<
    PermissionDataType[]
  >([
    {
      mdmClientId: 0,
      clientId: 0,
      clientName: "",
      hqSiteId: 0,
      hqClientName: "",
      suppressFolderCreation: false,
      suppressDropdown: false,
      suppressProjectStatus: false,
      userName: "",
    },
  ]);
  const [siteSettings, setSiteSettings] = useState<GetSiteSettingsDTO[]>([]);
  const [selectionMode, setSelectionMode] = useState("multiple");
  const [selectedRowsData, setSelectedRowsData] =
    useState<selectedRowDataTypes | null>(null);

  const fetchData = async () => {
    try {
      setData({ pending: true, data: [], totalCount: 0 });
      const startIndex =
        pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.highQ_GetSiteFromTrack(
        activeDiv,
        appName !== null ? appName : "PERF",
        startIndex,
        defaultPageSize
      );
      setData({ pending: false, data: data.data, totalCount: data.totalCount });
    } catch (error) {}
  };

  const fetchDataBySearch = async () => {
    try {
      setData({ pending: true, data: [], totalCount: 0 });
      const startIndex =
        pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.highQ_GetSiteFromTrackSearch(
        activeDiv,
        searchTerm,
        appName !== null ? appName : "PERF"
      );
      setData({ pending: false, data: data.data, totalCount: data.totalCount });
    } catch (error) {}
  };

  useEffect(() => {
    if ((searchTerm === null || searchTerm?.length === 0) && shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [activeDiv, searchTerm, pageNumber, appName, shouldFetch]);

  useEffect(() => {
    setpageNumber(1);
    setShouldFetch(true);
  }, [appName, activeDiv]);

  useEffect(() => {
    setShouldFetch(true);
  }, [pageNumber, searchTerm]);

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchDataBySearch();
    }
    // eslint-disable-next-line
  }, [activeDiv, appName, pageNumber, searchTerm]);

  useEffect(() => {
    if (tab === 1) {
      setTitle("Select Client and Template");
    }
    if (tab === 2) {
      if (appName === "TP") setTitle("Select Tax Year");
      if (appName === "CS") setTitle("Select Dates");
    }
    if (tab === 3) {
      setTitle("Select Legal Entities");
    }
    if (tab === 4) {
      setTitle("Select Legal Entities Settings");
    }
    if (tab === 5) {
      setTitle("Customize site name");
    }
  }, [tab]);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const data = await taxPortalClientApi.taxPortal_GetHQSiteSettings();
        setSiteSettings(data);
      } catch (error) {
        console.error("Error fetching site settings:", error);
      }
    };
    fetchSiteSettings();
  }, []);

  // Function to handle div click
  const handleDivClick = (divNumber) => {
    setActiveDiv(divNumber);
  };

  const onSelectionchanged = (e: any) => {
    setSelectedData(e.selectedRowsData);
  };

  const datetime = (e: any) => {
    if (e.data.created === null) {
      return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div>
            <div>{convertUTCTimeToLocal(e.data.created)}</div>
          </div>
        </div>
      );
    }
  };

  const cellRender = (e: any) => {
    if (e.data.created === null) {
      return null;
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div>
            <div>{convertUTCTimeToLocal(e.data.archivedDate)}</div>
          </div>
        </div>
      );
    }
  };
  const cellRenderRestore = (e: any) => {
    return <div>{convertUTCTimeToLocal(e.data.restoredDate)}</div>;
  };
  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const SiteId = selectedData.map((e) => {
    return e.hqSiteId;
  });

  const cellRenderForStatus = (e: any) => {
    if (status != null && e.data.hqSiteId === status.siteId) {
      return <div title={status.status}>{status.status}</div>;
    } else {
      return <div title={e.data.message}>{e.data.status}</div>;
    }
  };
  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchData();
    },
  };

  const uniqueTags = [];
  data?.data?.map((x) => {
    if (uniqueTags.indexOf(x.name) === -1) {
      uniqueTags.push(x.name);
    }
  });

  const onClickRetrySiteCreationWithSelection = async () => {
    try {
      showSuccessSnackbar({ message: SnackbarMessage.RETRY_FOR_SITE_CREATION });
      const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
      const messageForRetry = selectedData.map((e: any) => {
        return {
          siteId: 0,
          userName: user?.profile.preferred_username,
          siteName: e.name,
          clientName: e.clientName,
          mdmClientId: e.mdmClientId,
        };
      });
      if (messageForRetry != null) {
        var response = await taxPortalClientApi.siteSetUp_CreateSiteVerify(
          appName,
          messageForRetry
        );
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  const HandleOnClickCreate = async () => {
    setIsDisplayModal(true);
  };

  const HandleOnClickSetUp = async () => {
    try {
      showSnackbar({ message: SnackbarMessage.SITE_SETUP });
      const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
        const ids = siteSettings.map((e: GetSiteSettingsDTO) => e.id) as
          | string[]
          | number[]
          | undefined;
        const message = {
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType: ServiceBusEnum.SYNC_MDM,
          appName: appName !== null ? appName : "PERF",
          mdmClientId: e.mdmClientId,
          settingsIds: ids,
        };
        siteSetUp(message);
      });
    } catch {}
  };
  const siteSetUp = async (message) => {
    await taxPortalClientApi.siteSetUp_SiteSetUp(message);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesData: GetAllRolesDTO[] =
          await taxPortalClientApi.taxPortal_GetAllRoles();
        setRolesData(rolesData);
      } catch (error) {}
    };
    fetchRoles();
  }, [setRolesData]);

  const handleClose = () => {
    setIsDisplayModal(false);
    setTab(1);
    setSelectedOption(null);
    setSelectionMode("multiple");
    setIsFlag(false);
    setChecked(false);
    setisChecked(false);
    setsiteNameForIS("");
    removeTaxYearForCS();
    removeTaxYear();
    localStorage.clear();
  };

  const listData = [
    {
      siteId: 0,
      userName: user?.profile.preferred_username as string,
      eventType: ServiceBusEnum.SITE_CREATION,
      status: "INPROGRESS",
      mdmClientId: selectedRowsData?.mdmClientId,
      templateId: selectedOption?.value?.toString(),
      templateName: selectedOption?.label,
      siteName: dataPermissionFinal[0]?.hqClientName,
      clientName: selectedRowsData?.name,
      appName: appName !== null ? appName : "PERF",
      siteConfig: {
        startDate:
          siteConfig?.startDate !== undefined ? siteConfig.startDate : null,
        endDate: siteConfig?.endDate !== undefined ? siteConfig.endDate : null,
      },
      isTestSite: isChecked,
      year: taxYear,
    },
  ];
  const listDataForIS = [
    {
      siteId: 0,
      userName: user?.profile.preferred_username as string,
      eventType: ServiceBusEnum.SITE_CREATION,
      status: "INPROGRESS",
      mdmClientId: 0,
      templateId: selectedOption?.value?.toString(),
      templateName: selectedOption?.label,
      siteName: siteNameForIS,
      clientName: "Internal Client",
      appName: appName !== null ? appName : "PERF",
      siteConfig: {
        startDate: null,
        endDate: null,
      },
      isTestSite: isChecked,
      year: taxYear,
    },
  ];

  const getFilteredAndSortedData = () => {
    const grid = dataGridRef.current?.instance;
    const filterExpr = grid.getCombinedFilter(true);
    const dataSource = grid.getDataSource();
    const loadOptions = dataSource.loadOptions();

    dataSource
      .store()
      .load({
        filter: filterExpr,
        sort: loadOptions.sort,
        group: loadOptions.group,
      })
      .then((result) => {
        setDs(result);
        setTimeout(() => {
          // Find the input field within the SearchPanel component
          const input = document.querySelector(
            ".dx-datagrid-search-panel input"
          );
          if (input) {
            input.focus();
          }
        }, 100);
      });
  };
  const handleChange = (e: boolean) => {
    setisChecked(e);
  };
  const handleContinueForIS = async () => {
    if (tab === 1) {
      setTab(tab + 1);
    }
    if (tab === 2) {
      setTab(tab + 1);
    }
    if (tab === 3 && appName === "IS") {
      try {
        const response = await taxPortalClientApi.taxPortal_CreateSites(
          listDataForIS
        );
        setIsSiteExistResponse(response);
        handleClose();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  const handleContinue = async () => {
    if (tab === 5 && tab < 6) {
      if (selectionMode === "none") {
        try {
          const response = await taxPortalClientApi.taxPortal_CreateSites(
            listData
          );
          const isExists = !isNull(response) && !isEmpty(response);
          if (isExists) {
            setIsFlag(true);
            setIsSiteExistResponse(response);
          } else {
            setIsDisplayModal(false);
            handleClose();
            setIsFlag(false);
            setChecked(false);
            setisChecked(false);
            localStorage.clear();
          }
        } catch (error) {}
        localStorage.clear();
      } else {
        try {
          await taxPortalClientApi
            .mDM_AddSelectedLegalEntities(dataPermissionFinal)
            .then(async () => {
              const response = await taxPortalClientApi.taxPortal_CreateSites(
                listData
              );
              const isExists = !isNull(response) && !isEmpty(response);
              if (isExists) {
                setIsFlag(true);
                setIsSiteExistResponse(response);
              } else {
                setIsDisplayModal(false);
                handleClose();
                setIsFlag(false);
                setChecked(false);
                setisChecked(false);
                localStorage.clear();
              }
              localStorage.clear();
            });
        } catch (error) {}
      }
    } else if (tab === 1 || tab === 2) {
      setTab(tab + 1);
    } else if (tab === 3 || tab === 4) {
      if (selectionMode === "none") {
        if (appName === "TP" || appName === "PERF") {
          setTab(tab + 2);
        } else if (appName === "CS") {
          setTab(tab + 2);
          if (tab === 4) setTab(tab + 1);
        }
      } else if (selectionMode === "multiple") {
        if (appName === "TP" || appName === "PERF") {
          setTab(tab + 1);
        } else if (appName === "CS") {
          setTab(tab + 2);
          if (tab === 4) setTab(tab + 1);
        }
      }
    }
  };
  const handleBack = () => {
    if (tab === 5 && selectionMode === "none") {
      setTab(tab - 2);
      setIsFlag(false);
    } else {
      if (appName === "TP" || appName === "PERF") setTab(tab - 1);
      else if (appName === "CS") {
        if (tab === 5) setTab(tab - 2);
        else setTab(tab - 1);
      } else setTab(tab - 1);
      setChecked(false);
      setisChecked(false);
    }
    if (
      tab === 3 &&
      (appName === "TP" || appName === "CS" || appName === "PERF")
    ) {
      setBtnContinueDisable(false);
    }
  };

  const onRowExpanded = (e) => {
    if (e) {
      setId(e.key.hqSiteId);
      e.component.collapseAll(-1);
    }
  };
  const handleCheckboxValueChanged = async (e, rowData) => {
    if (e.event === undefined) return;
    const updatedValue = e.value;
    if (!e && rowData.data.isCustomPermissionApplied === updatedValue) return;
    try {
      await taxPortalClientApi.taxPortal_UpdateIsCustomPermissionApplied(
        rowData.data.hqSiteId,
        e.value
      );
    
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  }; 

  const onRowCollapsed = (e) => {
    setStatus({ siteId: 0, status: "Active" });
  };

  const cellRenderSiteName = (e: any) => {
    return (
      <>
        {" "}
        {e?.row?.data?.hqSiteId === 0 ? (
          <div>{e?.row?.data?.name}</div>
        ) : (
          <div
            style={{
              color: "#009cde",
              textDecoration: "underline",
              cursor: "pointer",
              display: "inline",
            }}
            onClick={() =>
              window.open(
                `${envProperties.reactAppHighQSiteUrl}=${e?.row?.data?.hqSiteId}`
              )
            }
          >
            {e?.row?.data?.name}
          </div>
        )}
      </>
    );
  };
  const cellRenderMasterClientId = (e: any) => {
    return (
      <div
        style={{
          color: "#009cde",
          textDecoration: "underline",
          cursor: "pointer",
          display: "inline",
        }}
        onClick={() =>
          window.open(
            `${envProperties.reactAppMdmClientIdURL}=${e?.row?.data?.mdmClientId}`
          )
        }
      >
        {e?.row?.data?.mdmClientId}
      </div>
    );
  };

  useEffect(() => {
    if (taxYear != null && taxYear != undefined) {
    }
  }, [taxYear]);

  const syncIsheets = async (message) => {
    try{
      await taxPortalClientApi.taxPortal_UpdateIsheetDataTrigger(message);
    }
   catch(error)
   {
    console.log(error)
   }
  };
  const onSelect = (e: string) => {
    const isheetTypeMap: { [key: string]: string } = {
      "Sync Information Request Isheet": "Information Request List",
      "Sync Document Metadata Isheet": "Documents Metadata",
      "Sync Folder Metadata Isheet": "Folder metadata",
    };
    const isheetType = isheetTypeMap[e] || "";
    const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
      const message = {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        isheetType: isheetType,
        appName: appName !== null ? appName : "PERF",
        mdmClientId: e.mdmClientId,
      };
      syncIsheets(message)
  })
};

  return (
    <div style={{ margin: "16px" }}>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          margin: "10px 0 10px 0",
        }}
      >
        <h2>Create and Setup</h2>
        <AppNameToggle />
      </div>
      <div style={{ marginRight: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0 10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "14vw",
              margin: "10px 0 10px 0",
            }}
          >
            <div
              onClick={() => handleDivClick("ACTIVE")}
              style={{
                cursor: "pointer",
                color: activeDiv === "ACTIVE" ? "#fff" : "black",
                backgroundColor: activeDiv === "ACTIVE" ? "#009cde" : "#eee",
                borderRadius: "15px",
                padding: "5px 12px 5px 12px",
                transition: "background-color 0.3s, color 0.3s",
                marginLeft: "10px",
              }}
            >
              Active
            </div>
            <div
              onClick={() => handleDivClick("INPROGRESS")}
              style={{
                cursor: "pointer",
                color: activeDiv === "INPROGRESS" ? "#fff" : "black",
                backgroundColor:
                  activeDiv === "INPROGRESS" ? "#009cde" : "#eee",
                borderRadius: "15px",
                padding: "5px 12px 5px 12px",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              In Progress
            </div>
          </div>
        </div>
        {appName === "IS" ? (
          <DialogBox
            isDisplayModal={isDisplayModal}
            handleClose={handleClose}
            btnOpenText={tab === 3 ? "Finish" : "Next"}
            title={
              tab === 1
                ? "Select Internal Site Template"
                : tab === 2
                ? "Select Tax Year"
                : tab === 3
                ? "Customize site name"
                : ""
            }
            handleContinue={handleContinueForIS}
            btnBackText={tab !== 1 ? "Back" : ""}
            handleBack={handleBack}
            btnContinueDisable={btnContinueDisable}
            isCustomScreen={checked}
            handleChange={handleChange}
          >
            <SelectClientAndTemplateForIS
              tab={tab}
              setSelectedRowsData={setSelectedRowsData}
              selectedOption={selectedOption}
              selectedRowsData={selectedRowsData}
              setSelectedOption={setSelectedOption}
              setBtnContinueDisable={setBtnContinueDisable}
              setDataPermissionFinal={setDataPermissionFinal}
              selectionMode={selectionMode}
              setSelectionMode={setSelectionMode}
              appName={appName}
              setIsFlag={setIsFlag}
              setSiteConfig={setSiteConfig}
              siteConfig={siteConfig}
              siteNameForIS={siteNameForIS}
              setsiteNameForIS={setsiteNameForIS}
              setChecked={setChecked}
            />
          </DialogBox>
        ) : (
          <DialogBox
            isDisplayModal={isDisplayModal}
            handleClose={handleClose}
            btnOpenText={tab === 5 ? "Finish" : "Next"}
            title={title}
            handleContinue={handleContinue}
            btnBackText={tab !== 1 ? "Back" : ""}
            handleBack={handleBack}
            btnContinueDisable={btnContinueDisable}
            isCustomScreen={checked}
            handleChange={handleChange}
          >
            <SelectClientAndTemplate
              tab={tab}
              setSelectedRowsData={setSelectedRowsData}
              selectedOption={selectedOption}
              selectedRowsData={selectedRowsData}
              setSelectedOption={setSelectedOption}
              setBtnContinueDisable={setBtnContinueDisable}
              setDataPermissionFinal={setDataPermissionFinal}
              selectionMode={selectionMode}
              setSelectionMode={setSelectionMode}
              appName={appName}
              setIsFlag={setIsFlag}
              setSiteConfig={setSiteConfig}
              siteConfig={siteConfig}
              setChecked={setChecked}
            />
          </DialogBox>
        )}
        <DataGrid
          ref={dataGridRef}
          allowColumnResizing={true}
          dataSource={data?.data!}
          showBorders={true}
          onSelectionChanged={onSelectionchanged}
          height={calculateGridHeight(260)}
          remoteOperations={true}
          noDataText={data.pending ? "Loading..." : "No Data"}
          btnContinueDisable={btnContinueDisable}
          onRowExpanding={onRowExpanded}
          onRowCollapsed={onRowCollapsed}
        >
          <Selection
            mode="multiple"
            selectAllMode={"page"}
            showCheckBoxesMode={"always"}
          />
          {/* <Paging enabled={true} defaultPageSize={20} /> */}
          <Scrolling columnRenderingMode="virtual" />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            placeholder="Search..."
            onTextChange={(e: any) => {
              setSearchTerm(e);
            }}
          />
          <Toolbar>
            <Item>
              <L2UserOption selectedData={selectedData} />
            </Item>
            <Item location= "after">
            <>
            {appName === "TP" && (
            <SplitButton
                            onSelect={onSelect}
                            options={[
                              "Sync Isheets",
                              "Sync Information Request Isheet",
                              "Sync Document Metadata Isheet",
                              "Sync Folder Metadata Isheet",
                            ]}
                            selection={selectedData?.length}
                          ></SplitButton> )}
              </>
            </Item>
            <Item location="after">
              <>
                {activeDiv === "INPROGRESS" && (
                  <Button
                    variant="outlined"
                    onClick={onClickRetrySiteCreationWithSelection}
                  >
                    Retry
                  </Button>
                )}
              </>
            </Item>
            <Item location="after">
              <Button variant="outlined" onClick={HandleOnClickCreate}>
                Create Site
              </Button>
            </Item>
            <Item location="after">
              <Button
                variant="outlined"
                disabled={selectedData.length === 1 ? false : true}
                onClick={HandleOnClickSetUp}
              >
                Site Setup
              </Button>
            </Item>
            <Item name="searchPanel" location="after" />
            <Item
              widget="dxButton"
              options={refreshButtonOptions}
              name="alignRight"
            />
          </Toolbar>
          <Column
            minWidth={80}
            dataField="hqSiteId"
            caption="SiteID"
            alignment="left"
          ></Column>
          <Column
            minWidth={80}
            cellRender={cellRenderMasterClientId}
            dataField="mdmClientId"
            caption="Master ClientID"
            alignment="left"
          ></Column>
          <Column
            minWidth={300}
            dataField="clientName"
            caption="Master Client Name"
            alignment="left"
          ></Column>
          <Column
            minWidth={150}
            cellRender={cellRenderForStatus}
            dataField="status"
            caption="Status"
            alignment="left"
          ></Column>
          <Column
            minWidth={400}
            cellRender={cellRenderSiteName}
            dataField="name"
            caption="Site Name"
            alignment="left"
          ></Column>
          <Column
            dataField="isCustomPermissionApplied"
            caption="Custom Permissions"
            width={100}
            alignment="centre"
            cellRender={(rowData) => (
              <CheckBox
              value={!!rowData.data.isCustomPermissionApplied}
              onValueChanged={(e) => {
                e.event?.stopPropagation();
                handleCheckboxValueChanged(e, rowData);
              }}
            />
            )}
          ></Column>
          <Column
            minWidth={250}
            dataField="createdBy"
            caption="Created by "
            alignment="left"
          ></Column>
          <Column
            minWidth={200}
            cellRender={datetime}
            dataField="Created Date"
            caption="Created Date "
            alignment="left"
          ></Column>
          <MasterDetail
            enabled={activeDiv === "ACTIVE" ? true : false}
            ref={templateRef}
            component={
              appName === "TP" || appName === "PERF"
                ? SiteProcessData
                : SiteProcessDataForCS
            }
          ></MasterDetail>
        </DataGrid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 0 0 0",
          }}
        >
          <Pagination
            onChange={(e: any, pageNumber: any) => setpageNumber(pageNumber)}
            count={Math.ceil(data.totalCount / defaultPageSize)}
            variant="outlined"
            shape="rounded"
            page={pageNumber}
          />
        </div>
      </div>
    </div>
  );
}
