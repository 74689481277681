import { convertUTCTimeToLocal } from "../../util/Utils";
const cellRenderForDate = (e: string) => {
  return <div>{convertUTCTimeToLocal(e)}</div>;
};

export const columnsConfig = [
    { field: "hqSiteId", caption: "SiteId", minWidth: 100, isVisible: true },
    { field: "siteName", caption: "Site Name", minWidth: 300, isVisible: true },
    { field: "entity", caption: "Entity", minWidth: 150, isVisible: true },
    { field: "client_Name", caption: "Client Name", minWidth: 180, isVisible: true },
    { field: "tax_Type", caption: "Tax Type", minWidth: 150, isVisible: true },
    { field: "matter_Type", caption: "Matter Type", minWidth: 150, isVisible: false },
    { field: "description", caption: "Description", minWidth: 200, isVisible: false },
    { field: "jurisdiction", caption: "Jurisdiction", minWidth: 150, isVisible: false },
    { field: "authority_Name", caption: "Authority Name", minWidth: 180, isVisible: false },
    { field: "resolution_Status", caption: "Resolution Status", minWidth: 180, isVisible: false },
    { field: "client_Risk", caption: "Client Risk", minWidth: 150, isVisible: false},
    { field: "rsM_Responsible_Party", caption: "RSM Responsible Party", minWidth: 180, isVisible: false },
    { field: "client_Contact_Name", caption: "Client Contact Name", minWidth: 200, isVisible: false },
    { field: "salT_Contact", caption: "SALT Contact", minWidth: 150, isVisible: false },
    { field: "tfL_Name", caption: "TFL Name", minWidth: 150, isVisible: false },
    { field: "responsible_Party_for_Response", caption: "Responsible Party for Response", minWidth: 200, isVisible: false},
    { field: "estimated_Tax_Amount_Due", caption: "Estimated Tax Amount Due", minWidth: 180, isVisible: true },
    { field: "estimated_Penalty_Due", caption: "Estimated Penalty Due", minWidth: 180, isVisible: false },
    { field: "estimated_Interest_Due", caption: "Estimated Interest Due", minWidth: 180, isVisible: false },
    { field: "estimated_Total_Amount_Due", caption: "Estimated Total Amount Due (Tax + Penalties + Interest)", minWidth: 250, isVisible: true },
    { field: "estimated_Total_Amount_Due_Calculated", caption: "Estimated Total Amount Due (Tax + Penalties + Interest)-Calculated", minWidth: 250, isVisible: true },
    { field: "actual_Tax_Amount_Due", caption: "Actual Tax Amount Due", minWidth: 180, isVisible: true },
    { field: "actual_Penalty_Due", caption: "Actual Penalty Due", minWidth: 180, isVisible: false },
    { field: "actual_Interest_Due", caption: "Actual Interest Due", minWidth: 180, isVisible: false },
    { field: "actual_Total_Amount_Due", caption: "Actual Total Amount Due (Tax + Penalties + Interest)", minWidth: 250, isVisible: true },
    { field: "rsM_Receipt_Date", caption: "RSM Receipt Date", minWidth: 180, isVisible: false },
    { field: "due_Date_of_Response", caption: "Due Date of Response", minWidth: 180, isVisible: false },
    { field: "date_on_Letter_or_Notice", caption: "Date on Letter or Notice", minWidth: 180, isVisible: false },
    { field: "filing_Period_Start", caption: "Filing Period Start", minWidth: 180, isVisible: false },
    { field: "filing_Period_End", caption: "Filing Period End", minWidth: 180, isVisible: false },
    { field: "extension_Request_Date", caption: "Extension Request Date", minWidth: 180, isVisible: false },
    { field: "new_Due_Date", caption: "New Due Date", minWidth: 180, isVisible: false },
    { field: "clientID_MDM", caption: "ClientID (MDM)", minWidth: 180, isVisible: false },
    { field: "master_Client_MDM", caption: "Master Client (MDM)", minWidth: 180, isVisible: false },
    { field: "taxID", caption: "TaxID", minWidth: 150, isVisible: false },
    { field: "notice_ID", caption: "Notice ID #", minWidth: 180, isVisible: false },
    { field: "jurisdiction_Locality", caption: "Jurisdiction (Locality)", minWidth: 180, isVisible: false },
    { field: "rsM_Response_Status", caption: "RSM Response Status", minWidth: 180, isVisible: false },
    { field: "rsM_Resolution", caption: "RSM Resolution", minWidth: 180, isVisible: false },
    { field: "notes_to_RSM", caption: "Notes to RSM", minWidth: 200, isVisible: false },
    { field: "attachments", caption: "Attachments", minWidth: 150, isVisible: false },
    { field: "created_by", caption: "Created by", minWidth: 180, isVisible: false },
    { field: "created_date", caption: "Created date", minWidth: 180, isVisible: false },
    { field: "modified_by", caption: "Modified by", minWidth: 180, isVisible: false },
    { field: "modified_date", caption: "Modified date", minWidth: 180, isVisible: false },
    { field: "comments", caption: "Comment: Comments added in iSheet for that record", minWidth: 250, isVisible: false },
  ];  


  export const SyncAllSitesColumnsConfig = [
    { field: "hqSiteId", caption: "Site ID", minWidth: 80 },
    { field: "hqClientName", caption: "Site Name", minWidth: 300 },
    { field: "type", caption: "Sync Type", minWidth: 260 },
    { field: "lastModified", caption: "Sync Date", minWidth: 190,
      cellRender: (e:any) => cellRenderForDate(e.data.lastModified),
    },
    { field: "lastModifiedBy", caption: "Synced By", minWidth: 100 },
    { field: "status", caption: "Sync Status", minWidth: 100 },
  ];


