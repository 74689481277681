import { GetCentralControversyTrackerDTO, CentralControversyTracker, GetAllSitesInfoDTO } from "../../api-client-nswag/taxportal-client";

export const constants = {
    ACTIVE: "ACTIVE",
    PAGE_TITLE: "Central Controversy Tracker",
  };
  export interface CentralControversyTrackerData {
    pending: boolean;
    CentralControversyTrackerData: CentralControversyTracker[];
    totalCount: number | undefined;
  }

  export interface SyncCentralControversyTrackerData {
    pending: boolean;
    AllSitesInfo: GetAllSitesInfoDTO[] | undefined;
    totalCount?: number | 0;
  }
  //GetAllSitesResponse