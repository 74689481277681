import React from "react";

interface PageToggleProps {
  buttonNames: string[]; 
  activeButton: string; 
  onButtonClick: (name: string) => void; 
}

const PageToggle: React.FC<PageToggleProps> = ({ buttonNames, activeButton, onButtonClick }) => {
  const buttonStyle: React.CSSProperties = {
    cursor: "pointer",
    padding: "12px 24px",
    transition: "color 0.3s, transform 0.3s, box-shadow 0.3s",
    marginLeft: "20px",
    color: "#333",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    border: "none",
    backgroundColor: "transparent",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  };

  const activeButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    color: "#009cde",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  };

  const activeUnderlineStyle = (isActive: boolean): React.CSSProperties => ({
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#009cde",
    transform: isActive ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s",
    zIndex: -1,
  });

  return (
    <div>
      {buttonNames.map((buttonName) => (
        <button
          key={buttonName} 
          onClick={() => onButtonClick(buttonName)} 
          style={activeButton === buttonName ? activeButtonStyle : buttonStyle} 
        >
          {buttonName}
          <div style={activeUnderlineStyle(activeButton === buttonName)}></div>
        </button>
      ))}
    </div>
  );
};

export default PageToggle;
