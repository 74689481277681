import React, { useEffect, useState, useContext, useRef } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Toolbar,
  Item,
  MasterDetail,
  Selection,
} from "devextreme-react/data-grid";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { Template } from "devextreme-react";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import { BatchSitesSelectedProps, constants } from "./UpgradeSitesGrids.types";
import { dataGridConfig, UPGRADE_SITES_SCREENS } from "../../../constants";
import { HQSiteChangesDTO, HQSiteDetailsDTO } from "../../../api-client-nswag/taxportal-client";
import { useSnackbar } from "../../../hooks";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { executeDownload } from "../../../util/Utils";
import { upsertData } from "./atoms/actions";
import { SitesChangesDetail } from "./SitesChangesDetail";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import "./BatchSitesSelected.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

type DatagridRefType = DataGrid;
const {
  BUTTON_CONFIRM,
  BUTTON_BACK,
  BUTTON_CANCEL,
  SITES_SELECTED_TITLE,
  BUTTON_DOWNLOAD_EXCEL,
  FILE_COMPARISON_NAME,
  EXCEL_FILE_EXTENSION,
  ALERT_MESSAGE,
} = constants;
const BatchSitesSelected = ({
  selectedSitesData,
  setSelectedSites,
  selectedSites,
  templateSelected,
  batchGridData,
  setActiveScreen,
  isBatchUpgrade,
  setPageNumber,
  pageNumber,
}: BatchSitesSelectedProps) => {
  const [selectedChanges, setSelectedChanges] = useState<HQSiteChangesDTO[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [changesWStatus, setChangesWStatus] = useState<HQSiteChangesDTO[]>([]);
  const [changesDeployed, setChangesDeployed] = useState<number>(0);
  const [selectedToDownload, setSelectedToDownload] = useState<HQSiteChangesDTO[]>([]);
  const [hideBackButton, setHideBackButton] = useState(true);
  const { user } = useContext(OidcIdentityContext);
  const userName = user?.profile.preferred_username!;
  const { showSnackbar } = useSnackbar();
  const [selectedSitesList, setselectedSitesList] = useState<any[]>(selectedSitesData);
  const [avaliableSitesList, setavailableSitesList] = useState<string[]>([]);
  const [selected_hq_siteid, setselected_hq_siteid] = useState<string[]>([]);
  const isAllowApplyChanges =
    selectedChanges.length && selectedSitesList.length && selectedChanges.length <= changesDeployed ? true : false;
  const modifySelectedChanges = (newData: HQSiteChangesDTO, isToDownload = false) => {
    const method = !isToDownload ? setSelectedChanges : setSelectedToDownload;
    executeModifiySelected(method, newData);
  };
  const removingSelectedChanges = (rowKey: number) => {
    removeSelected(setSelectedChanges, rowKey);
    removeSelected(setSelectedToDownload, rowKey);
  };

  const executeModifiySelected = (
    setterMethod: (value: React.SetStateAction<HQSiteChangesDTO[]>) => void,
    newData: HQSiteChangesDTO
  ) => {
    setterMethod((prevChanges) => {
      const newChangesData = [...prevChanges];
      return upsertData(newChangesData, newData, "siteId");
    });
  };

  const removeSelected = (method: (value: React.SetStateAction<HQSiteChangesDTO[]>) => void, rowKey: number) => {
    method((prevChanges) => {
      const newChangesData = [...prevChanges];
      return newChangesData.filter((change: HQSiteChangesDTO) => change.siteId !== rowKey);
    });
  };
  const handleApplyChanges = () => {
    setActiveScreen(UPGRADE_SITES_SCREENS.UPGRADE_SITES);
    setPageNumber(pageNumber);
    showSnackbar({ message: SnackbarMessage.PROCEED_TO_BATCH_UPGRADE });
    setIsPending(true);
    const dataToSend = setDataToUpgrade();
    for (const data of dataToSend) {
      const { siteId, ...rest } = data;
      const datatoSendBU = selectedSitesList.map((item: any) => ({
        siteId: item.hqSiteId,
        ...rest,
      }));
      executeUpgrade(datatoSendBU);
    }
  };
  const executeUpgrade = async (dataToSend: HQSiteChangesDTO[]) => {
    try {
      const promises = dataToSend.map(async (data) => {
        try {
          const resultChanges = await taxPortalClientApi.taxPortal_ProceedToUpgradeSites(
            templateSelected,
            [data],
            userName,
            isBatchUpgrade
          );

          await setChangesWStatus(resultChanges);
        } catch (error) {
          showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
      });

      setIsPending(false);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const setDataToUpgrade = () => {
    const existingIds = new Set(selectedChanges.map((change) => change.siteId));

    const missingObjects: HQSiteChangesDTO[] = selectedSites
      .filter((siteId) => !existingIds.has(siteId))
      .map((siteId) => ({ siteId, siteChanges: [] }));

    return [...selectedChanges, ...missingObjects];
  };
  const goBackAction = () => {
    setSelectedSites([]);
    setPageNumber(pageNumber);
    setActiveScreen(UPGRADE_SITES_SCREENS.UPGRADE_SITES);
  };

  const renderDetail = (props: any) => {
    const parentKey = props.key;
    const parentComponent = props.component;
    return (
      <SitesChangesDetail
        parentKey={parentKey}
        parentComponent={parentComponent}
        selectedSites={selectedSites}
        selectedChanges={selectedChanges}
        templateSelected={templateSelected}
        changesWStatus={changesWStatus}
        isPending={isPending}
        modifySelectedChanges={modifySelectedChanges}
        removingSelectedChanges={removingSelectedChanges}
        isBatchUpgrade={isBatchUpgrade}
      />
    );
  };
  const handleSiteClick = (siteId: string) => {
    setselected_hq_siteid((prev: any) => {
      if (prev.includes(siteId)) {
        return prev.filter((id: any) => id !== siteId);
      } else {
        return [...prev, siteId];
      }
    });
  };

  const handleExportButton = async () => {
    if (!selectedToDownload) return;
    showSnackbar({ message: SnackbarMessage.COMPARISON_EXCEL_DOWNLOAD_MESSAGE });
    selectedToDownload.forEach((siteChanges) => {
      getExportedFile(siteChanges);
    });
  };
  const getExportedFile = async (siteChanges: HQSiteChangesDTO) => {
    try {
      const base64String = await taxPortalClientApi.HQTemplateSiteExcelExport(siteChanges, false);
      if (base64String)
        executeDownload(base64String, String(siteChanges.siteId), FILE_COMPARISON_NAME, EXCEL_FILE_EXTENSION);
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.DOWNLOAD_FAILED.replace("%site%", String(siteChanges.siteId)) as SnackbarMessage,
        severity: "error",
      });
    }
  };

  const onSelectionchanged = ({ selectedRowKeys }: SelectionChangedEvent) => {
    setSelectedSites(selectedRowKeys);
  };
  const handleMoveSingleToSelected = () => {
    setselectedSitesList((prev) => [
      ...prev,
      ...avaliableSitesList.filter((site: any) => selected_hq_siteid.includes(site.hqSiteId)),
    ]);
    setavailableSitesList((prev) => prev.filter((site: any) => !selected_hq_siteid.includes(site.hqSiteId)));
    setselected_hq_siteid([]);
  };

  const handleMoveSingleToAvailable = () => {
    setavailableSitesList((prev) => [
      ...prev,
      ...selectedSitesList.filter((site) => selected_hq_siteid.includes(site.hqSiteId)),
    ]);
    setselectedSitesList((prev) => prev.filter((site) => !selected_hq_siteid.includes(site.hqSiteId)));
    setselected_hq_siteid([]);
  };

  const handleMoveAllToSelected = () => {
    setselectedSitesList((prev) => [...prev, ...avaliableSitesList]);
    setavailableSitesList([]);
  };

  const handleMoveAllToAvailable = () => {
    setavailableSitesList((prev) => [...prev, ...selectedSitesList]);
    setselectedSitesList([]);
  };
  useEffect(() => {
    setChangesDeployed(selectedChanges.filter((item) => item.siteChanges && item.siteChanges.length).length);
  }, [selectedChanges]);

  const isItemSelected = (siteid: any) => {
    return selected_hq_siteid.includes(siteid);
  };
  return (
    <>
      <div>
        <h4>{SITES_SELECTED_TITLE}</h4>
      </div>
      <DataGrid
        id="grid-selected"
        keyExpr="latestTemplateId"
        dataSource={batchGridData}
        {...dataGridConfig}
        style={{ width: "100%" }}
        onSelectionChanged={onSelectionchanged}
        selectedRowKeys={selectedSites}
      >
        <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />

        <FilterRow visible />
        <HeaderFilter visible />
        <SearchPanel visible />
        <Scrolling mode="standard" />
        <LoadPanel enabled={false} />

        <Toolbar>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={!isAllowApplyChanges}
              onClick={() => {
                handleExportButton();
              }}
            >
              {BUTTON_DOWNLOAD_EXCEL}
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
        <Paging enabled defaultPageSize={20} />
        <Column dataField="latestTemplateId" caption={"Template ID"} minWidth={70} alignment="left" />
        <Column dataField="latestTemplateName" caption={"Tempalate Name"} minWidth={70} alignment="left" />
        <Column dataField="targetTemplateId" caption={"Target Template ID"} minWidth={70} alignment="left" />
        <Column dataField="targetTemplateName" caption={"Target Template Name"} minWidth={70} alignment="left" />
        <MasterDetail enabled render={renderDetail} />
      </DataGrid>

      <div className="mainToggleList">
        <div className="mainToggleList_inner">
          <div className="leftBox">
            <div className="mainToggleList_header">Available Sites</div>
            <div className="mainToggleList_body">
              {avaliableSitesList.map((item: any) => (
                <div
                  key={item.hqSiteId}
                  className={
                    selected_hq_siteid.includes(item.hqSiteId)
                      ? "selected_sites_single selected_hq"
                      : "selected_sites_single"
                  }
                  onClick={() => handleSiteClick(item.hqSiteId)}
                >
                  <p className="selected_hq_text">{item.hqSiteId}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="arrows">
              <div>
                <KeyboardDoubleArrowRightIcon sx={{ cursor: "pointer" }} onClick={handleMoveAllToSelected} />
              </div>
              <div className="center_arrows">
                <div>
                  <KeyboardArrowRightIcon sx={{ cursor: "pointer" }} onClick={handleMoveSingleToSelected} />
                </div>
                <div>
                  <KeyboardArrowLeftIcon sx={{ cursor: "pointer" }} onClick={handleMoveSingleToAvailable} />
                </div>
              </div>
              <div>
                <KeyboardDoubleArrowLeftIcon sx={{ cursor: "pointer" }} onClick={handleMoveAllToAvailable} />
              </div>
            </div>
          </div>

          <div className="rightBox">
            <div className="mainToggleList_header">Selected Sites </div>
            <div className="mainToggleList_body">
              {selectedSitesList.map((item: any, index: number) => (
                <div
                  className={
                    isItemSelected(item.hqSiteId) ? "selected_sites_single selected_hq" : "selected_sites_single"
                  }
                  onClick={() => handleSiteClick(item.hqSiteId)}
                >
                  <p className="selected_hq_text">{item.hqSiteId}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-buttons-container">
        <div className="alert-msg alert-text">
          <WarningIcon />
          {ALERT_MESSAGE}
        </div>
        <div className="button-group">
          <Button className="footer-button" onClick={goBackAction} variant="outlined">
            {BUTTON_CANCEL}
          </Button>
          {hideBackButton ? (
            <Button
              className="footer-button"
              disabled={!isAllowApplyChanges}
              onClick={handleApplyChanges}
              variant="outlined"
            >
              {BUTTON_CONFIRM}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BatchSitesSelected;
