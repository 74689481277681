import {
  NavigationMenu,
  NavigationMenuListObject,
  NavigationMenuSingleObject,
} from "../../cui/components/navigation/NavigationBar.types";

export const homeNavigationMenuData: NavigationMenu[] = [
  // {
  // 	icon: 'dashboard',
  // 	name: 'Dashboard',
  // 	redirectUrl: '/tpac/dashboard',
  // 	tooltip: ''
  // } as NavigationMenuSingleObject,
  {
    icon: "assignment",
    name: "Create Sites",
    redirectUrl: "/sites",
    tooltip: "Create Sites",
  } as NavigationMenuSingleObject,
  {
    icon: "browser_updated",
    name: "Upgrade Sites",
    redirectUrl: "/upgradeSites",
    tooltip: "Upgrade Sites",
  } as NavigationMenuSingleObject,
  {
    icon: "track_changes",
    name: "Central Controversy Tracker",
    redirectUrl: "/CentralControversyTracker",
    tooltip: "Central Controversy Tracker",
  } as NavigationMenuSingleObject,
  {
    icon: "add_box",
    name: "Create and Setup Sites",
    redirectUrl: "/CreateSitesAutomate",
    tooltip: "Create and Setup Sites",
  } as NavigationMenuSingleObject,
  {
    icon: "task",
    name: "Create task",
    redirectUrl: "/createTask",
    tooltip: "CreateTasks",
  } as NavigationMenuSingleObject,
  {
    icon: "link",
    name: "Update Entities, Projects and Phases",
    redirectUrl: "/updateSites",
    tooltip: "Update Entities, Projects and Phases",
  } as NavigationMenuSingleObject,
  {
    icon: "settings_applications",
    name: "Site Settings",
    redirectUrl: "/siteSettingsManager",
    tooltip: "SiteSettingsManager",
  } as NavigationMenuSingleObject,
  {
    icon: "folder",
    name: "Create Folders",
    redirectUrl: "/createFolder",
    tooltip: "Create Folders",
  } as NavigationMenuSingleObject,
  {
    icon: "airplay",
    name: "Assign Users",
    redirectUrl: "/siteAdminAccess",
    tooltip: "Assign Users",
  } as NavigationMenuSingleObject,
  {
    icon: "account_balance",
    name: "Organizations",
    redirectUrl: "/thirdPartyOrganization",
    tooltip: "Organizations",
  } as NavigationMenuSingleObject,
  {
    icon: "touch_app",
    name: "Update Metadata Location Files",
    redirectUrl: "/updateMetadataLocationFiles",
    tooltip: "Update Metadata Location Files",
  } as NavigationMenuSingleObject,
  {
    icon: "schedule",
    name: "Schedule Files",
    redirectUrl: "/scheduleFiles",
    tooltip: "Schedule Files",
  } as NavigationMenuSingleObject,
  {
    icon: "poll",
    name: "Reports - TP",
    redirectUrl: "/powerBI",
    tooltip: "Reports",
    listObjects: [
      {
        header: "User Inputs",
        links: [
          {
            name: "Information Tracker",
            tooltip: "Information Tracker",
            redirectUrl: "/powerBI-information-tracker",
            icon: "poll",
          },
          {
            name: "Site Information Tracker",
            tooltip: "Site Information Tracker",
            redirectUrl: "/powerBI-site-information-tracker",
            icon: "poll",
          },
        ],
      },
    ],
  } as NavigationMenuListObject,
  {
    icon: "poll",
    name: "Reports - CS",
    redirectUrl: "/powerBI",
    tooltip: "Reports",
    listObjects: [
      {
        header: "User Inputs",
        links: [
          {
            name: "Planning Ideas",
            tooltip: "Planning Ideas",
            redirectUrl: "/Planning-Ideas",
            icon: "poll",
          },
          {
            name: "Project Tracker Lite",
            tooltip: "Project Tracker Lite",
            redirectUrl: "/Project-tracker-Lite",
            icon: "poll",
          },
          {
            name: "Global Direct",
            tooltip: "Global Direct",
            redirectUrl: "/CS_GlobalDirect",
            icon: "poll",
          },
          {
            name: "US Direct",
            tooltip: "US Direct",
            redirectUrl: "/CS_USDirect",
            icon: "poll",
          },
          {
            name: "E-Filing Tracker",
            tooltip: "E-Filing Tracker",
            redirectUrl: "/CS_EFilingTracker",
            icon: "poll",
          },
          {
            name: "Project Tracker Full",
            tooltip: "Project Tracker Full",
            redirectUrl: "/CS_ProjectTrackerFull",
            icon: "poll",
          },
          {
            name: "Cash Tax Dashboard",
            tooltip: "Cash Tax Dashboard",
            redirectUrl: "/CashTaxDashboard",
            icon: "poll",
          },
        ],
      },
    ],
  } as NavigationMenuListObject,
  {
    icon: "public",
    name: "Global Tax Information",
    redirectUrl: "/globaltaxinformation",
    tooltip: "Global Tax Information",
  } as NavigationMenuSingleObject,
  {
    icon: "show_chart",
    name: "Site Metrics",
    redirectUrl: "/siteMetrics",
    tooltip: "Site Metrics",
  } as NavigationMenuSingleObject,
  //   {
  //   	name: 'Inputs',
  //   	icon: 'input',
  //   	tooltip: 'Inputs',
  //   	listObjects: [
  //   		{
  //   			header: 'User Inputs',
  //   			links: [
  //   				{
  //   					name: 'Input template',
  //   					tooltip: '',
  //   					redirectUrl: '/',
  //   					icon: "toc"
  //   				},
  //   				{
  //   					name: 'Input data',
  //   					tooltip: '',
  //   					redirectUrl: '/',
  //   					icon: "table_view"
  //   				},
  //   				{
  //   					name: 'Input types',
  //   					tooltip: '',
  //   					redirectUrl: '/'
  //   				},
  //   			]
  //   		}
  //   	]
  //   } as NavigationMenuListObject
];
