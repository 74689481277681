import { useCallback, useContext, useEffect, useState } from "react";
import { Button, FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import { Modal } from "../Modal/Modal";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useRecoilValue } from "recoil";

import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { updateSites } from "../../../pages/UpdateScreen/UpdateSiteInfo.types";
import { ViewExecutionLogsProps } from "./ViewExecutionLogs.types";
import { dataGridConfig, defaultPageSize } from "../../../constants";
import { convertUTCTimeToLocal, calculateGridHeight } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { getAppName } from "../../commonComponents/AppNameToggle";
import { GetAllSitesDTO, ServiceBusEnum, ServiceMessage, SyncTypeEnum, Username, SelectName, GetSchedulesDTO } from "../../../api-client-nswag/taxportal-client";
import { OidcIdentityContext } from "@rsmus/react-auth/dist/OidcIdentity";
import { FailedToNegotiateWithServerError } from "@microsoft/signalr/dist/esm/Errors";
//import DevExpress from "devextreme";

const initialStateData = { pending: false, fulfilled: false, sites: [], totalCount: 0 };

export const ViewExecutionLogs = ({
  optionsDropdown,
  optionSelected,
  isDisplayModal,
  handleClose,
}: ViewExecutionLogsProps) => {
  const { user, logout } = useContext(OidcIdentityContext);
  const { showSnackbar } = useSnackbar();
  const { showSuccessSnackbar } = useSnackbar();
  const [data, setData] = useState<updateSites>(initialStateData);
  const [dropDown, setDropDown] = useState<number>(optionSelected);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppName);
  const [selectedData, setSelectedData] = useState([]);
  const dropdownOptions = optionsDropdown ?? [];
  const [disableRetry, setDisableRetry] = useState<boolean>(true);

  const [checkBoxesMode, setCheckBoxesMode] = useState<string>("");
  const { INFORMATION_REQUEST, PROJECT_STATUS, DOCUMENT_METADATA, LEGAL_ENTITIES, SYNC_MDM, THIRD_PARTY_ORG } =
    SelectName;

  const options = dropdownOptions.map((option) => ({
    
    name: option.displayName,
    value:
    option.displayName === "Create External Folders"
      ? SyncTypeEnum.CREATE_FOLDER_EXTERNAL
      : SyncTypeEnum[option.jobType as keyof typeof SyncTypeEnum],
  })
);
  const filteredOptions = options
      .filter((change) => (appName === "CS" ? change.name.toLowerCase().includes("- cs") : !change.name.toLowerCase().includes("- cs")))
      .map((obj) => obj);


  const fetchDetails = async (dropDown: number | null) => {
    setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.taxPortal_GetAllSites(
        dropDown!,
        null, //Username.ADMIN,
        appName,
        startIndex,
        defaultPageSize
      );
      setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
      showSnackbar({ message: SnackbarMessage.SCHEDULE_DETAILS });
    } catch (error) {
      setData(initialStateData);
    }
  };
  const handleRefresh = () => fetchDetails(dropDown!);

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: handleRefresh,
  };

  const cellRenderDate = (e: string) => {
    return <div>{convertUTCTimeToLocal(e)}</div>;
  };
  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const handleDropDownChange = (e: SelectChangeEvent<number>) => {
    setDropDown(e.target.value as number);
  };
  const onSelectionchanged = (e: any) => {
    const selected = e.selectedRowsData;
    setSelectedData(selected);
    if (selected.length === 0) {
      setDisableRetry(true);
    }
    else {
      // const hasError = selected.some((row: any) => row.status === "ERROR" || row.status == null);
      // setDisableRetry(hasError ? false : true);
      setDisableRetry(false);
    }
  };

  useEffect(() => {
    setDropDown(optionSelected);
  }, [optionSelected]);

  useEffect(() => {
    if (!!dropDown) fetchDetails(dropDown);
  }, [dropDown, pageNumber]);


  const exportButtonOptions = {
    icon: "exportxlsx",
    onClick: async () => {
      try {
        //userName: user?.profile.preferred_username,
        var response = await taxPortalClientApi
          .taxPortal_ExportToExcelForAllSites(dropDown!,
            null,//Username.ADMIN,
            appName)
          .then((res: any) => {
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              appName + "-" + dropDown! + " Execution Logs.xlsx"
            );
            link.click();
          });
        showSuccessSnackbar({
          message: SnackbarMessage.EXPORT_TO_EXCEL_SUCCESS,
        });
      } catch (error) {
        showSnackbar({
          message: SnackbarMessage.EXPORT_TO_EXCEL_FAIL,
          severity: "error",
        });
      }
    },
  };

  const getEnumKeyByValue = (value: number): string | undefined => {
    return Object.keys(SyncTypeEnum).find(key => SyncTypeEnum[key as keyof typeof SyncTypeEnum] === value);
  }

  const onClickRetrySite = async () => {
    try {
      const schedules: GetSchedulesDTO[] = await taxPortalClientApi.functionScheduler_GetListOfScheduleJobs();
      const filteredSchedules = schedules.filter(x => x.jobType === getEnumKeyByValue(dropDown));
      const eventTypeId = filteredSchedules[0]?.typeNumber;
      const data = selectedData.map(
        (e: GetAllSitesDTO): ServiceMessage => ({
          siteId: e.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType: eventTypeId,
          mdmClientId: e.mdmClientId
        })
      );
      await taxPortalClientApi.taxPortal_InsertTopic(data);

    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };
  return (
    <Modal
      title="Schedule Execution logs"
      maxWidth="xl"
      btnCloseText="Cancel"
      isDisplayModal={isDisplayModal}
      handleClose={handleClose}
    >
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <DataGrid
          height={calculateGridHeight(270)}
          {...dataGridConfig}
          dataSource={data.sites}
          onSelectionChanged={onSelectionchanged}
          noDataText={data.pending ? "Loading..." : "No data"}

          onCellPrepared={(e) => {
            if (e.rowType === "data" && e.columnIndex === 0 && e.cellElement) {

              const disableCheckbox = e.data.status !== "SUCCESS";
              if (disableCheckbox) {
                setCheckBoxesMode('always');
              }
              else {
                setCheckBoxesMode('none');
              }

            }
          }}
        >
          <Selection
            mode="multiple"
            selectAllMode={"page"}
            showCheckBoxesMode={"always"}


          />
          <Scrolling mode="standard" />
          <LoadPanel enabled={false} />
          <SearchPanel visible />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          {/* <Paging enabled={true} defaultPageSize={20} /> */}
          <Scrolling columnRenderingMode="virtual" />
          <Toolbar>
            <Item location="after">
              <Button
                onClick={onClickRetrySite}
                variant="outlined"
                disabled={disableRetry}
              >
                retry
              </Button>
            </Item>
            <Item location="after">
              <FormControl size="medium" variant="outlined" sx={{ width: 400, marginRight: "-15px" }}>
                <Select
                  style={{ height: 36, fontSize: "14px" }}
                  defaultValue={optionSelected}
                  onChange={handleDropDownChange}
                >
                  {filteredOptions.map(({ name, value }) => (
                    <MenuItem key={value} value={value} style={{ fontSize: "14px" }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item
              widget="dxButton"
              options={exportButtonOptions}
              name="alignRight"
            />
            <Item name="searchPanel" location="after" />
            <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
          </Toolbar>

          <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"} />
          <Column dataField="hqClientName" caption={"SiteName"} />
          <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderDate(e.data.siteCreated)}
            dataField="Site Created Date / Time"
            caption={"Site Created Date / Time"}
          />
          <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
          <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderDate(e.data.lastModified)}
            dataField="Last Update Date/Time"
            caption={"Last Update Date/Time"}
          />
        </DataGrid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 0 0 0",
          }}
        >
          <Pagination
            onChange={(e: any, pageNumber: any) => {
              setpageNumber(pageNumber);
            }}
            count={Math.ceil((data.totalCount as number) / defaultPageSize)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </Modal>
  );
};
