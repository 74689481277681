import { useState, useContext } from 'react';
import { OidcIdentityContext } from "@rsmus/react-auth";
import PageToggle from "../../cui/commonComponents/PageToggle";
import { constants } from "./CentralControversyTracker.types";
import { CentralControversyTrackerGrid } from './ControversyTrackerGrid';

import { useRecoilValue } from "recoil";
import { userPermissions } from "../../App";
import './CentralControversyTracker.scss';
import SynchronizeCentralControversyTracker from './SynchronizeCentralControversyTracker';
const { PAGE_TITLE } = constants;

export const CentralControversyTracker = () => {
    const { user } = useContext(OidcIdentityContext);
    const userEmail = user?.profile.preferred_username!;
    const userPermission = useRecoilValue(userPermissions);
    const [activeButton, setActiveButton] = useState("Central Controversy Tracker");

    const handleButtonClick = (name: string) => {
        setActiveButton(name);
    };

    const toggleButtonNames = ["Central Controversy Tracker",];
    if (userPermission.includes("SynchronizeCentralControversyTracker")) {
        toggleButtonNames.push("Synchronization with Central Controversy Tracker");
    }
    return (
        <div className='grid-container'>
            <div className='grid-header'>
                <h2>{PAGE_TITLE}</h2>
                <PageToggle
                    buttonNames={toggleButtonNames}
                    activeButton={activeButton}
                    onButtonClick={handleButtonClick}

                />
            </div>
            {
                activeButton === "Central Controversy Tracker" ? (
                    <CentralControversyTrackerGrid
                        userEmail={userEmail}
                    />
                ) : (
                    <SynchronizeCentralControversyTracker
                        userEmail={userEmail}
                    />
                )
            }
        </div>
    )
}
