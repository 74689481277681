import { useEffect, useRef, useState, useContext } from "react";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  SearchPanel,
  Toolbar,
  Item,
  Paging,
} from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { calculateGridHeight, executeDownload } from "../../../util/Utils";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";

import { SnackbarMessage, useSnackbar } from "../../../hooks";
import { defaultPageSize, UPGRADE_SITES_SCREENS } from "../../../constants";
import { CellRenderDate } from "../../../util/cellRendersAtoms";
import { SitesGridProps, constants, selectedOptionType } from "./UpgradeSitesGrids.types";

import "./UpgradeSites.scss";
import { ModalSiteChangesDetail } from "./ModalSiteChangesDetail";
import { useRecoilValue } from "recoil";
import { getAppName } from "../../commonComponents/UpgradeSitesToggle";

type DatagridRefType = DataGrid;

const {
  BUTTON_REVIEW_CHANGES,
  COLUMNS,
  BUTTON_DOWNLOAD_EXCEL,
  FILE_SITE_UPGRADE_NAME,
  ZIP_FILE_EXTENSION,
  BUTTON_BATCH_UPGRADE,
  BUTTON_RETRY_BATCHUPGRADE,
  STATUSES
} = constants;
const {
  HQ_SITE_ID,
  ORG_ID,
  MDM_MASTER_CLIENT_ID,
  MDM_CLIENT_ID,
  NAME,
  TEMPLATE_NAME_OLD,
  TEMPLATE_NAME_NEW,
  UPGRADE_DATE,
  UPGRADED_BY,
  STATUS,
} = COLUMNS;

export const SitesGrid = ({
  sitesData,
  selectedSites,
  setSelectedSites,
  setSelectedSitesData,
  dropDown,
  setDropDown,
  fetchData,
  toggleSitesGrids,
  setPageNumber,
  setSearchTerm,
  setActiveScreen,
  toggleBatchUpgradeSitesGrids,
  batchGridData,
  setBatchGridData,
  IsBatchUpgrade,
  pageNumber,
}: SitesGridProps) => {
  const { user } = useContext(OidcIdentityContext);
  const userEmail = user?.profile.preferred_username!;
  const dataGridRef = useRef<DatagridRefType>(null);
  const { showSnackbar } = useSnackbar();
  const [dropDownOptions, setDropDownOptions] = useState<selectedOptionType[]>([]);
  const count = Math.ceil(sitesData.totalCount / defaultPageSize);
  const areDropAndSiteSelected = !dropDown || !selectedSites.length;
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [idUpgradeSiteTracks, setIdUpgradeSiteTracks] = useState<number>();
  const [isBatchUpgrade, setIsBatchUpgrade] = useState(false);
  const [isRetryBatchUpgrade, setIsRetryBatchUpgrade] = useState(false);
  const appName = useRecoilValue(getAppName);
  const getDropDownOptions = async () => {
    try {
      const dropDownOpt = await taxPortalClientApi.highQ_GetHQSiteTemplate(appName);
      const dropOptions = dropDownOpt.map(({ id, sitename }) => ({
        value: id,
        label: sitename,
      }));
      setDropDownOptions(dropOptions);
    } catch (error) { }
  };

  const onSelectionchanged = ({ selectedRowKeys, selectedRowsData }: SelectionChangedEvent) => {
    setSelectedSites(selectedRowKeys);
    const validStatuses = selectedRowsData.filter((site) => site.status !== null);
    setIsDownloadEnabled(() => (selectedRowKeys.length && validStatuses.length ? true : false));
    const allSitesValidForRetry = selectedRowsData.every((site) => site.status?.toLowerCase() === STATUSES.ERROR.toLowerCase() ||
      site.status?.toLowerCase() === STATUSES.PARTIALLY_SUCCESS.toLowerCase());
    setIsRetryBatchUpgrade(() => (selectedRowKeys.length > 0 && allSitesValidForRetry))
  };

  const getSitesToDownload = () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    return selectedData.filter((site) => site.status !== null).map((siteData) => siteData.idUpgradeSiteTracks);
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: () => {
      fetchData();
    },
  };

  const handleSeeDetailsButton = (idUpgradeSiteTracks: number) => {
    setIdUpgradeSiteTracks(idUpgradeSiteTracks);
    setIsDisplayModal(true);
  };

  const handleClose = () => {
    setIsDisplayModal(false);
  };

  const getSelectedData = () => {
    return dataGridRef.current?.instance.getSelectedRowsData() || [];
  };
  const selectedRow = getSelectedData();

  const upgradeButtonEnables = (selectedRow: any) => {
    if (!selectedSites.length || !dropDown) {
      return false;
    }

    if (selectedSites.length === 1) {
      return true;
    }
    let nullTemplates = [] as any[];
    let nonNullTemplates = [] as any[];
    selectedRow.forEach((site: any) => {
      if (site.templateNameNew === null) {
        nullTemplates.push(site);
      } else {
        nonNullTemplates.push(site);
      }
    });
    if (nullTemplates.length > 0 && nonNullTemplates.length > 0) {
      const isSameNonNullValue = nonNullTemplates.every(
        (site: any) => site.templateNameNew === nonNullTemplates[0].templateNameNew
      );

      const isSameNullValue = nullTemplates.every(
        (site: any) => site.templateNameOld === nullTemplates[0].templateNameOld
      );

      if (
        isSameNonNullValue &&
        isSameNullValue &&
        nonNullTemplates[0].templateNameNew === nullTemplates[0].templateNameOld
      ) {
        return true;
      }
    }
    if (nonNullTemplates.length > 0 && nullTemplates.length === 0) {
      return nonNullTemplates.every((site: any) => site.templateNameNew === nonNullTemplates[0].templateNameNew);
    }
    if (nullTemplates.length > 0 && nonNullTemplates.length === 0) {
      return nullTemplates.every((site: any) => site.templateNameOld === nullTemplates[0].templateNameOld);
    }
    return false;
  };

  const isButtonEnabled = upgradeButtonEnables(selectedRow);

  const handleInitiateButton = () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const selected = dataGridRef.current!.instance.getSelectedRowKeys();
    setSelectedSitesData(selectedData);
    setSelectedSites(selected);
    toggleSitesGrids(false);
    toggleBatchUpgradeSitesGrids(false);
    setActiveScreen(UPGRADE_SITES_SCREENS.SITES_SELECTED);
  };

  const getTargetLabel = (dropDown: string, dropDownOptions: any[]) => {
    return dropDownOptions.find((item) => item.value === dropDown);
  };

  const getTemplateData = (templates: any[], isNonNull: boolean, label: any) => {
    if (templates.length > 0 && isButtonEnabled) {
      const template = templates[0];
      return [
        {
          latestTemplateName: isNonNull ? template.templateNameNew : template.templateNameOld,
          latestTemplateId: isNonNull ? template.templateIdNew : template.templateIdOld,
          targetTemplateId: label?.value,
          targetTemplateName: label?.label,
        },
      ];
    }
    return [];
  };

  const batchData = (selectedRow: any[], dropDown: string, dropDownOptions: any[]) => {
    const label = getTargetLabel(dropDown, dropDownOptions);
    const nullTemplates = selectedRow.filter((site: any) => site.templateNameNew === null);
    const nonNullTemplates = selectedRow.filter((site: any) => site.templateNameNew !== null);

    if (nonNullTemplates.length > 0) {
      return getTemplateData(nonNullTemplates, true, label);
    } else if (nullTemplates.length > 0) {
      return getTemplateData(nullTemplates, false, label);
    }

    return [];
  };

  const handleInitiateBatchUpgradeButton = () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const selectedRowKeys = dataGridRef.current!.instance.getSelectedRowKeys();

    setSelectedSitesData(selectedData);
    setSelectedSites(selectedRowKeys);
    setActiveScreen(UPGRADE_SITES_SCREENS.BATCH_SITES_SELECTED);
    toggleBatchUpgradeSitesGrids(true);
    IsBatchUpgrade(true);

    const getBatchData = batchData(selectedRow, dropDown, dropDownOptions);
    setBatchGridData(getBatchData);
  };

  const handleDropDownChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
  };

  const onChangePagination = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const renderDetails = ({ row: { data } }: any) => {
    return data.status ? (
      <IconButton
        className="dx-datagrid dx-link"
        aria-label="See Details"
        title="See Details"
        onClick={() => handleSeeDetailsButton(data.idUpgradeSiteTracks)}
      >
        <SearchIcon />
      </IconButton>
    ) : null;
  };

  const handleExportButton = async () => {
    showSnackbar({ message: SnackbarMessage.ZIP_UPGRADE_DOWNLOAD_MESSAGE });
    try {
      const sitesToDownload = getSitesToDownload();
      const base64String = await taxPortalClientApi.HQTemplateSiteUpgradeResultExcelExport(sitesToDownload);
      if (base64String) executeDownload(base64String, "", FILE_SITE_UPGRADE_NAME, ZIP_FILE_EXTENSION);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ZIP_DOWNLOAD_FAILED, severity: "error" });
    }
  };
  const handleRetryBatchUpgrade = async () => {
    try {
      const selectedData = dataGridRef.current!.instance.getSelectedRowsData();

      const apiCalls = selectedData.map(record =>
        taxPortalClientApi.taxPortal_RetryBatchUpgradeUpgradeSites(record.templateIdNew, record.hqSiteId, userEmail)
      );
    }
    catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
    finally {

      fetchData();
    }
  }
  useEffect(() => {
    setDropDown("");
    getDropDownOptions();
  }, [appName]);

  return (
    <div style={{ marginRight: "20px" }}>
      <div className="counter">
        <h4>
          {selectedSites.length} out of {sitesData.totalCount} records selected
        </h4>
      </div>

      <DataGrid
        id="grid-sites"
        ref={dataGridRef}
        keyExpr="hqSiteId"
        allowColumnResizing
        dataSource={sitesData.sites}
        showBorders
        onSelectionChanged={onSelectionchanged}
        height={calculateGridHeight(260)}
        remoteOperations
        noDataText={sitesData.pending ? "Loading..." : "No Data"}
        selectedRowKeys={selectedSites}
      >
        <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
        <Scrolling columnRenderingMode="virtual" />
        <FilterRow visible />
        <HeaderFilter visible />
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <Paging enabled={false} />
        <Toolbar>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={!isDownloadEnabled}
              onClick={() => {
                handleExportButton();
              }}
            >
              {BUTTON_DOWNLOAD_EXCEL}
            </Button>
          </Item>
          <Item location="after">
            <FormControl size="medium" variant="outlined" sx={{ width: 200 }}>
              <FormLabel className="form-label">Select a Template</FormLabel>
              <Select
                style={{ height: 36, fontSize: "14px" }}
                defaultValue={dropDown}
                onChange={handleDropDownChange}
                MenuProps={{
                  style: {
                    maxHeight: 300,
                  },
                }}
              >
                {dropDownOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value} style={{ fontSize: "14px" }}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <Button
              variant="outlined"
              disabled={!isButtonEnabled}
              onClick={() => {
                handleInitiateBatchUpgradeButton();
              }}
            >
              {BUTTON_BATCH_UPGRADE}
            </Button>
          </Item>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={areDropAndSiteSelected}
              onClick={() => {
                handleInitiateButton();
              }}
            >
              {BUTTON_REVIEW_CHANGES}
            </Button>
          </Item>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={isRetryBatchUpgrade === false}
              onClick={() => {
                handleRetryBatchUpgrade();
              }}
            >
              {BUTTON_RETRY_BATCHUPGRADE}
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
        </Toolbar>
        <Column dataField="hqSiteId" caption={HQ_SITE_ID} minWidth={70} alignment="left" />
        <Column dataField="orgId" caption={ORG_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmMasterClientId" caption={MDM_MASTER_CLIENT_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmClientId" caption={MDM_CLIENT_ID} minWidth={100} alignment="left" />
        <Column minWidth={300} dataField="name" caption={NAME} alignment="left" />
        <Column minWidth={300} dataField="templateNameOld" caption={TEMPLATE_NAME_OLD} alignment="left" />
        <Column minWidth={300} dataField="templateNameNew" caption={TEMPLATE_NAME_NEW} alignment="left" />
        <Column
          cellRender={({ data }) => CellRenderDate(data.upgradeDate)}
          dataField="upgradeDate"
          caption={UPGRADE_DATE}
          minWidth={180}
          alignment="left"
        />
        <Column dataField="upgradeBy" caption={UPGRADED_BY} minWidth={180} alignment="left" />
        <Column dataField="status" caption={STATUS} minWidth={180} alignment="left" />
        <Column cellRender={renderDetails} type="buttons" caption="Changes detail" />
      </DataGrid>
      <div className="grid-pagination">
        <Pagination onChange={onChangePagination} page={pageNumber} count={count} variant="outlined" shape="rounded" />
      </div>
      <ModalSiteChangesDetail
        handleClose={handleClose}
        isDisplayModal={isDisplayModal}
        idUpgradeSiteTracks={idUpgradeSiteTracks}
      />
    </div>
  );
};
