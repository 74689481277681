import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { useRecoilValue } from "recoil";
import { DataGrid, Column, Selection, Scrolling } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import IconButton from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

import { ChangesDetail, HQSiteChangesDTO, HQSiteChangesDetailDTO } from "../../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";

import { getCurrentUTCTimestamp } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { upgradeSitesResponseAtom } from "../../../constants";

import {
  ChangesDetailsProps,
  PrevDataDetails,
  SelectedChangeData,
  SelectedDetailsData,
  changesDetailData,
  constants,
} from "./UpgradeSitesGrids.types";
import { ModalReviewChanges } from "./ModalReviewChanges";
import { disableCheckboxes, selectParent, upsertData } from "./atoms/actions";

type DatagridRefType = DataGrid;
const {
  DETAILS_TITLE,
  STATUS_COLUMN,
  UPGRADED_BY_COLUMN,
  DATE_COLUMN,
  STATUSES,
  ALERT_TEXT_CLASS,
  ADDITIONAL_OFFSET,
  BUFFER,
} = constants;
const { SUCCESS, ERROR, PARTIALLY_SUCCESS, PENDING, EMPTY, SPECIAL_STATUS } = STATUSES;
const initialStateData = { pending: true, changes: [] };

export const SitesChangesDetail = ({
  parentKey,
  parentComponent,
  selectedSites,
  selectedChanges,
  templateSelected,
  changesWStatus,
  isPending,
  modifySelectedChanges,
  removingSelectedChanges,
  isBatchUpgrade,
}: ChangesDetailsProps) => {
  const { user } = useContext(OidcIdentityContext);
  const detailsGridRef = useRef<DatagridRefType>(null);
  const [changesDetailData, setChangesDetailData] = useState<changesDetailData>(initialStateData);
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [selectedChangesDetails, setSelectedChangesDetails] = useState<SelectedChangeData>();
  const [selectedDetails, setSelectedDetails] = useState<SelectedDetailsData[]>([]);
  const [upgradedDetails, setUpgradedDetails] = useState<SelectedDetailsData[]>([]);
  const [prevDataDetails, setPrevDataDetails] = useState<PrevDataDetails[]>([]);
  const [disabledChanges, setDisabledChanges] = useState<string[]>([]);
  const [selectedLocalChanges, setSelectedLocalChanges] = useState<string[]>([]);
  const upgradeSitesResponse = useRecoilValue(upgradeSitesResponseAtom);
  const { showSnackbar } = useSnackbar();

  const fetchData = async () => {
    try {
      setChangesDetailData(initialStateData);
      showSnackbar({ message: SnackbarMessage.RETRIEVE_SITE_CHANGES });
      const [{ siteChanges }] = await taxPortalClientApi.highQ_TemplateSiteComparison(
        templateSelected,
        String(parentKey),
        isBatchUpgrade
      );
      if (!siteChanges.length) selectParent(parentComponent, parentKey);
      setDisabledChanges(getDisabledRowKeys(siteChanges));
      setChangesDetailData({ pending: false, changes: siteChanges });
      setSelectedData([]);
    } catch (error) {}
  };

  const getDisabledRowKeys = (siteChanges: HQSiteChangesDetailDTO[]) => {
    return siteChanges
      .filter((change) => (change.status ? change.status.trim().startsWith(SPECIAL_STATUS) : null))
      .map((obj) => obj.comparisonId);
  };

  const onSelectionChanged = ({ selectedRowsData, selectedRowKeys }: SelectionChangedEvent) => {
    if (!selectedRowsData.length) {
      selectParent(parentComponent, parentKey);
      setSelectedDetails([]);
    } else if (!parentComponent.isRowSelected(parentKey)) {
      selectParent(parentComponent, parentKey, true);
    }
    setGeneralSelectedChanges(selectedRowsData);
    setSelectedLocalChanges(selectedRowKeys);
  };

  const getSelected = () => {
    if (parentComponent.isRowSelected(parentKey)) {
      const selected = selectedChanges.find((change: HQSiteChangesDTO) => change.siteId === parentKey);
      const selectedWithDetails = selected ? getSelectedDetails(selected.siteChanges) : null;
      const changes = selectedWithDetails ? selectedWithDetails : null;
      if (!changes) {
        setSelectedData(changesDetailData.changes);
      } else {
        setSelectedData(changes);
      }
    } else {
      removingSelectedChanges(parentKey);
      setSelectedLocalChanges([]);
    }
  };

  const getSelectedDetails = (selected: HQSiteChangesDetailDTO[]) => {
    return selected.map((siteChange) => {
      const updatedChangesDetail = siteChange.changesDetail.map((detail: ChangesDetail) => {
        const newDetail = selectedDetails.find(
          (nd) => nd.comparisonId === siteChange.comparisonId && nd.changeId === detail.changeId
        );
        if (newDetail) {
          return {
            ...detail,
            details: newDetail.details,
          };
        } else {
          const isChngSelected = getDataGrid()!.isRowSelected(siteChange.comparisonId);
          const selDetails = isChngSelected ? detail.details : [];
          return {
            ...detail,
            details: selDetails,
          };
        }
      });

      return {
        ...siteChange,
        changesDetail: updatedChangesDetail,
      };
    });
  };

  const setSelectedData = (selectedData: HQSiteChangesDetailDTO[]) => {
    const selectedRowsData = selectedData.filter((obj) => !disabledChanges.includes(obj.comparisonId));
    const selectedRowskeys = selectedRowsData.map((obj) => obj.comparisonId);
    setGeneralSelectedChanges(selectedRowsData);
    setSelectedLocalChanges(selectedRowskeys);
  };

  const setGeneralSelectedChanges = (selectedData: HQSiteChangesDetailDTO[]) => {
    modifySelectedChanges({ siteId: parentKey, siteChanges: selectedData });
    modifySelectedChanges({ siteId: parentKey, siteChanges: changesDetailData.changes }, true);
  };

  const updateSelectedDetails = () => {
    return getSelectedDetails(getDataGrid()!.getSelectedRowsData());
  };

  const getTopPosition = (element: HTMLElement | null): number => {
    let offset = 0;
    while (element) {
      offset += element.offsetTop;
      element = element.offsetParent as HTMLElement;
    }
    offset = offset - ADDITIONAL_OFFSET;
    return offset < BUFFER ? 0 : offset;
  };

  const onContentReady = () => {
    setSelectedLocalChanges(changesDetailData.changes.map((m) => m.comparisonId));
    if (detailsGridRef.current) {
      detailsGridRef.current.instance.selectAll();
    }
    const parentScrollable = parentComponent.getScrollable();
    const detailGridElement = getDataGrid()!.element();
    if (parentScrollable && detailGridElement) {
      const topPosition = getTopPosition(detailGridElement);
      parentScrollable.scrollTo({ top: topPosition });
    }
    disableCheckboxes(getDataGrid()!, disabledChanges);
  };

  const handleSeeDetailsButton = (selectedChange: HQSiteChangesDetailDTO, changeDetail: ChangesDetail) => {
    const changesSlcted = getChangesDetailSelected(selectedChange.comparisonId);
    const change = { ...selectedChange, changesDetail: [] };
    const selected = changesSlcted ? changesSlcted : change;
    setSelectedChangesDetails({ ...selected, detailSelected: changeDetail });
    setIsDisplayModal(true);
  };

  const getChangesDetailSelected = (comparisonId: string): SelectedChangeData | null => {
    const site = selectedChanges.find((site) => site.siteId === parentKey);
    if (!site) return null;

    const change = site.siteChanges.find((change) => change.comparisonId === comparisonId);
    return change ? change : null;
  };

  const handleClose = () => {
    setIsDisplayModal(false);
  };

  const getDataGrid = () => {
    return detailsGridRef.current ? detailsGridRef.current.instance : undefined;
  };

  const updateDataSource = (comparisonId: string, status: string) => {
    setChangesDetailData((prevData) => {
      const newChange = prevData.changes.map((change) =>
        change.comparisonId === comparisonId ? { ...change, status } : change
      );
      return { ...prevData, changes: newChange };
    });
  };

  const updateStatus = (upgradeSitesResponse: string) => {
    if (upgradeSitesResponse === "") return;
    const { SiteId, ComparisonId, Status } = JSON.parse(upgradeSitesResponse);

    if (SiteId !== parentKey) return;
    if (ComparisonId !== "") {
      updateDataSource(ComparisonId, Status);
      const dataGridComponent = getDataGrid();
      const rowIndex = dataGridComponent!.getRowIndexByKey(ComparisonId);
      updateCell(dataGridComponent, rowIndex, STATUS_COLUMN, Status);
      updateParentStatus(parentKey, PENDING);
    }
  };

  const addDisabledChanges = (disabledChange: string) => {
    setDisabledChanges((prevDisabled) => {
      const newDetailsData = [...prevDisabled];
      if (!newDetailsData.includes(disabledChange)) {
        return [...newDetailsData, disabledChange];
      }
      return prevDisabled;
    });
  };

  const updateParentStatus = (siteId: number, status: string, upgradeDate?: string) => {
    const parentRowIndex = parentComponent.getRowIndexByKey(siteId);
    const newDate = upgradeDate ? upgradeDate : getCurrentUTCTimestamp();
    updateCell(parentComponent, parentRowIndex, DATE_COLUMN, newDate);
    if (upgradeDate) {
      updateCell(parentComponent, parentRowIndex, UPGRADED_BY_COLUMN, user?.profile.preferred_username);
      updateCell(parentComponent, parentRowIndex, STATUS_COLUMN, status);
    }
  };

  const updateCell = (component: any, rowIndex: number, dataField: string, value?: string, disableCell = false) => {
    component.cellValue(rowIndex, dataField, value);
    if (disableCell && value === SUCCESS) disableCheckboxes(getDataGrid()!, disabledChanges);
  };

  const renderDetails = ({
    row: {
      data,
      data: { upgradeWarning, comparisonId, changesDetail, status },
    },
  }: any) => {
    if (!changesDetail) return;
    const isHighlight = upgradeWarning && upgradeWarning.length > 0;
    const colorClass = isHighlight ? ALERT_TEXT_CLASS : "";
    return changesDetail.map((detail: ChangesDetail) => (
      <div className="changeRow">
        <div className={`change ${colorClass}`}>{detail.change}</div>

        <div className="more-info">
          {getDataGrid()!.isRowSelected(comparisonId) ||
          (status === SUCCESS && disabledChanges.includes(comparisonId)) ? (
            <IconButton aria-label="More Info" title="More Info" onClick={() => handleSeeDetailsButton(data, detail)}>
              <SearchIcon />
            </IconButton>
          ) : (
            <div className="disabled-option">
              <SearchIcon />
            </div>
          )}
        </div>
      </div>
    ));
  };

  const getChangesByParentKey = (sites: HQSiteChangesDTO[]) => {
    const emptySiteData = { status: "", upgradeDate: "", siteChanges: [] };
    return sites.find((site) => site.siteId === parentKey) || emptySiteData;
  };

  const updateStatusOnChanges = (updatedChangesStatus?: HQSiteChangesDetailDTO[], status: string = "") => {
    return changesDetailData.changes.map((originalChanges) => {
      if (status !== "") {
        return getDataGrid()!.isRowSelected(originalChanges.comparisonId)
          ? { ...originalChanges, status: status }
          : originalChanges;
      }
      const match = updatedChangesStatus!.find(
        (statusUpdated) => statusUpdated.comparisonId === originalChanges.comparisonId
      );
      if (match) {
        const executedD = isCompletelyExecuted(
          originalChanges.changesDetail,
          match.modifiedDetail,
          originalChanges.comparisonId
        );
        buildDetailsDataWithStatus(
          originalChanges.changesDetail,
          match.modifiedDetail,
          originalChanges.comparisonId,
          match.status
        );

        if (match.status === SUCCESS && executedD) {
          addDisabledChanges(originalChanges.comparisonId);
          disableCheckboxes(parentComponent!, [originalChanges.comparisonId]);
          selectParent(parentComponent!, originalChanges.comparisonId);
        }
        return { ...originalChanges, status: match.status, modifiedDetail: match.modifiedDetail };
      }
      return originalChanges;
    });
  };

  const updateDetailsData = (
    newDetails: SelectedDetailsData | PrevDataDetails,
    setterMethod: Dispatch<SetStateAction<any[]>>
  ) => {
    setterMethod((prevChanges) => {
      const newChangesData = [...prevChanges];
      return upsertData(newChangesData, newDetails, "comparisonId", "changeId");
    });
  };

  const isCompletelyExecuted = (
    originalChanges: ChangesDetail[],
    detailModified: ChangesDetail[],
    comparisonId: string
  ): boolean => {
    const successfulDetails = getSuccessfulDetails();
    const matchingDetails = getMatchingDetails(originalChanges, detailModified, comparisonId);

    const executedDetails = successfulDetails.map((change) => {
      const matchingItem = matchingDetails.find(
        (modified) => `${comparisonId}${modified.changeId}` === `${change.comparisonId}${change.changeId}`
      );
      return matchingItem ? Array.from(new Set([...change.details, ...matchingItem.details!])) : [];
    });

    let totalExecutedCount = 0;
    if (successfulDetails.length) {
      totalExecutedCount = executedDetails.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem ? currentItem.length : 0);
      }, 0);
    } else {
      totalExecutedCount = matchingDetails.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.details ? currentItem.details.length : 0);
      }, 0);
    }
    const totalDetailsCount = originalChanges.reduce((accumulator, currentItem) => {
      return accumulator + (currentItem.details ? currentItem.details.length : 0);
    }, 0);

    return totalExecutedCount === totalDetailsCount;
  };

  const getMatchingDetails = (
    originalChanges: ChangesDetail[],
    detailModified: ChangesDetail[],
    comparisonId: string
  ) => {
    return originalChanges.map((change) => {
      const matchingModified = detailModified.find((modified) => modified.changeId === change.changeId);
      const selectedByComparisonId = getChangesDetailSelected(comparisonId);
      const matchingUpgraded = selectedByComparisonId
        ? selectedByComparisonId.changesDetail.find((detail) => detail.changeId === change.changeId)
        : null;

      if (matchingModified) {
        return { ...change, details: matchingModified.details };
      } else if (matchingUpgraded) {
        return { ...change, details: matchingUpgraded.details ? matchingUpgraded.details : [] };
      } else {
        return { ...change, details: [] };
      }
    });
  };

  const getSuccessfulDetails = () => {
    return prevDataDetails.map((detail) => {
      const details = detail.details.filter((det) => det.status === SUCCESS).map((item) => item.name);
      return { ...detail, details };
    });
  };

  const buildDetailsDataWithStatus = (
    originalChanges: ChangesDetail[],
    detailModified: ChangesDetail[],
    comparisonId: string,
    status: string | null
  ) => {
    originalChanges.map((change) => {
      const matchingModified = detailModified.find((modified) => modified.changeId === change.changeId);
      const selectedByComparisonId = getChangesDetailSelected(comparisonId);
      const matchingUpgraded = selectedByComparisonId
        ? selectedByComparisonId.changesDetail.find((detail) => detail.changeId === change.changeId)
        : null;
      const matchingPrevDetails = prevDataDetails.find(
        (prevDet) => `${prevDet.comparisonId}${prevDet.changeId}` === `${comparisonId}${change.changeId}`
      );

      let formattedDetails = [];
      if (matchingModified) {
        const { details: successDetails, partialDetails: failDetails } = matchingModified;
        formattedDetails = change.details!.map((detail) => processDetail(detail, successDetails, failDetails));
      } else {
        formattedDetails = change.details!.map((detail) =>
          processDefaultDetail(detail, matchingUpgraded, status || "")
        );
      }

      if (matchingPrevDetails) {
        const { details } = matchingPrevDetails;
        const mapFormattedDetails = new Map(formattedDetails.map((det) => [`${det.name}`, det.status]));
        formattedDetails = details.map((detail) => {
          const newStatus = mapFormattedDetails.get(detail.name);
          if (newStatus && newStatus !== PENDING) {
            return { ...detail, status: newStatus };
          }
          return detail;
        });
      }
      const prevDet = { changeId: change.changeId, details: formattedDetails, comparisonId };
      updateDetailsData(prevDet, setPrevDataDetails);
    });
  };

  const processDetail = (detail: string, successDetails: string[] | undefined, failDetails: string[] | undefined) => {
    if (isValidDetail(successDetails, detail)) {
      return assignStatuses(successDetails!, detail, SUCCESS);
    }
    if (isValidDetail(failDetails, detail)) {
      return assignStatuses(failDetails!, detail, ERROR);
    }
    return { name: detail, status: "" };
  };

  const processDefaultDetail = (detail: string, matchingUpgraded: any, status: string | undefined) => {
    const selected = matchingUpgraded?.details || [];
    return assignStatuses(selected, detail, status || "");
  };

  const isValidDetail = (details: string[] | undefined, detail: string) => {
    return details && details.length && details.includes(detail);
  };

  const assignStatuses = (selected: string[], detail: string, status: string = "") => {
    return { name: detail, status: selected.includes(detail) ? status : "" };
  };

  const getParentStatus = (siteChanges: HQSiteChangesDetailDTO[]) => {
    if (siteChanges.every((change) => change.status === SUCCESS)) {
      return SUCCESS;
    }
    if (siteChanges.every((change) => change.status === ERROR)) {
      return ERROR;
    }
    if (siteChanges.some((change) => change.status === ERROR)) {
      return PARTIALLY_SUCCESS;
    }
    return PENDING;
  };

  const setEmptyStatus = () => {
    const changes = updateStatusOnChanges([], EMPTY);
    setChangesDetailData({ pending: false, changes });
  };

  const cellRender = ({ value, data: { upgradeWarning } }: any) => {
    const isHighlight = upgradeWarning && upgradeWarning.length > 0;
    if (isHighlight) {
      return <span className="alert-text">{value}</span>;
    }
    return <span>{value}</span>;
  };

  useEffect(() => {
    if (changesWStatus.length) {
      const changesWS = getChangesByParentKey(changesWStatus);
      const { status, upgradeDate, siteChanges } = changesWS;
      if (siteChanges.length) {
        const statusToSet = status ? status : getParentStatus(siteChanges);
        updateParentStatus(parentKey, statusToSet, upgradeDate);
        setUpgradedDetails(selectedDetails);
        const changes = updateStatusOnChanges(siteChanges);
        setChangesDetailData({ pending: false, changes });
      }
    } else {
      setEmptyStatus();
    }
  }, [changesWStatus]);

  useEffect(() => {
    updateStatus(upgradeSitesResponse);
  }, [upgradeSitesResponse]);

  useEffect(() => {
    const changes = updateStatusOnChanges([], PENDING);
    if (isPending) setChangesDetailData({ pending: false, changes });
  }, [isPending]);

  useEffect(() => {
    disableCheckboxes(getDataGrid()!, disabledChanges);
  }, [disabledChanges, changesDetailData, selectedChanges]);

  useEffect(() => {
    getSelected();
  }, [selectedSites]);

  useEffect(() => {
    modifySelectedChanges({ siteId: parentKey, siteChanges: updateSelectedDetails() });
  }, [selectedDetails]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="master-detail-caption">{DETAILS_TITLE}</div>
      <DataGrid
        key={parentKey}
        id="grid-changes"
        ref={detailsGridRef}
        keyExpr="comparisonId"
        allowColumnResizing
        dataSource={changesDetailData.changes}
        noDataText={changesDetailData.pending ? "Loading..." : "No data"}
        showBorders
        onSelectionChanged={onSelectionChanged}
        remoteOperations
        selectedRowKeys={selectedLocalChanges}
        repaintChangesOnly={true}
        onContentReady={onContentReady}
        wordWrapEnabled
      >
        <Scrolling mode="standard" />
        <Selection mode="multiple" showCheckBoxesMode={"always"} selectAllMode={"page"} allowSelectAll={true}/>
        <Column dataField="module" cellRender={cellRender} width="150px" />
        <Column dataField="change" cellRender={cellRender} />
        <Column dataField="object" cellRender={cellRender} />
        <Column dataField="details" cellRender={renderDetails} caption="Changes detail" />
        <Column dataField="status" cellRender={cellRender} width="100px" />
      </DataGrid>

      <ModalReviewChanges
        selectedChangesDetails={selectedChangesDetails!}
        isDisplayModal={isDisplayModal}
        handleClose={handleClose}
        isReviewOnly={false}
        selectedDetails={selectedDetails}
        setSelectedDetails={setSelectedDetails}
        setDisabledChanges={setDisabledChanges}
        upgradedDetails={upgradedDetails}
        prevDataDetails={prevDataDetails}
        parentComponent={getDataGrid()}
        isBatchUpgrade={isBatchUpgrade}
      />
    </>
  );
};
