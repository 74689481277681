import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Toolbar,
  Item,
  SearchPanel,
  Paging,
  ColumnChooser,
  Position
} from "devextreme-react/data-grid";
import { MenuItem, Pagination, Select, Button, FormControl, FormLabel, Checkbox } from "@mui/material";
import { defaultPageSize, signalRMethods } from "../../constants";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import useSnackbar from "../../hooks/useSnackbar";
import { columnsConfig } from "./ControversyTrackerColumnConfig";
import './CentralControversyTracker.scss';
import { CentralControversyTrackerData } from "./CentralControversyTracker.types";
import { calculateGridHeight } from "../../util/Utils";
import { useEffect, useMemo, useState } from "react";
import { isEmpty, isNull } from "lodash";
import { CentralControversyTrackerUserSiteDTO, ExternalUserSite, ServiceBusEnum, ServiceMessage } from "../../api-client-nswag/taxportal-client";
import { hubConnection } from '../../signalr/signalr-connection';
const initialCentralControversyTrackerData = { pending: true, CentralControversyTrackerData: [], totalCount: 0 };

export const CentralControversyTrackerGrid = ({ userEmail }: { userEmail: string }) => {
  const [gridData, setGridData] = useState<CentralControversyTrackerData>(initialCentralControversyTrackerData);
  const [pageNumber, setPageNumber] = useState(1);
  const { showSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const [isPageNumberReset, setIsPageNumberReset] = useState(false);
  const [userSites, setUserSites] = useState<ExternalUserSite[]>([]);
  const [columns, setColumnsConfig] = useState([])
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [deleteSyncEnabled, setDeleteSyncEnabled] = useState(false);
  const [userCentralControversySites, setUserCentralControversySites] = useState<CentralControversyTrackerUserSiteDTO[]>([]);
 
  const handleRowClick = (data: any) => {

    const url = data;
    window.open(url, "_blank");
  };

  const onChangePagination = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };


  const handleRowPrepared = (e: any) => {
    if (e.rowType === "data") {
      const rowElement = e.rowElement;
      rowElement.title = `Click to view Controversy Tracker Record in Tax Portal: ${e.data.siteName}`;
      rowElement.onmouseover = () => {
        rowElement.classList.add("dx-row-hover");
      };
      rowElement.onmouseout = () => {
        rowElement.classList.remove("dx-row-hover");
      };
    }
  };
  const refreshButtonOptions = {
    icon: "refresh",
    onClick: () => {
      fetchData();
    },
  };
  const totalPages = useMemo(() => {
    return Math.ceil((gridData?.totalCount || 0) / defaultPageSize);
  }, [gridData?.totalCount]);

  const fetchData = async () => {
    setGridData((prev: CentralControversyTrackerData) => {
      return {
        ...prev,
        pending: true,
        CentralControversyTrackerData: []
      }
    });
    try {

      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      setGridData(initialCentralControversyTrackerData);
      const { centralControversyTrackerRecords, totalCount } = await taxPortalClientApi.GetCentralControversyTrackerRecords(
        userEmail,
        startIndex,
        defaultPageSize
      );

      if (centralControversyTrackerRecords) {
        setGridData({
          pending: false,
          CentralControversyTrackerData: centralControversyTrackerRecords,
          totalCount: totalCount
        })
      }
      else {
        setGridData({ ...initialCentralControversyTrackerData, pending: false })
      }
    }
    catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setGridData(initialCentralControversyTrackerData);
    }
  }
  const fetchRecordsBySearchTerms = async () => {
    const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
    setGridData(initialCentralControversyTrackerData);
    try {

      const { centralControversyTrackerRecords, totalCount } = await taxPortalClientApi.GetCentralControversyTrackerRecordsBySearch(
        userEmail,
        searchTerm,
        startIndex,
        defaultPageSize
      );
      if (centralControversyTrackerRecords) {
        setGridData({
          pending: false,
          CentralControversyTrackerData: centralControversyTrackerRecords,
          totalCount: totalCount
        })
      }
      else {
        setGridData({ ...initialCentralControversyTrackerData, pending: false })
      }
    }
    catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setGridData(initialCentralControversyTrackerData);


    }
  };

  // Handle column visibility changes (using onOptionChanged)
  const handleColumnVisibilityChange = (e: any) => {
    columns.forEach((col: any, index: number) => {
      if (e.fullName === "columns[" + index + "].visible") {
        col['isVisible'] = e.value;
      }
    })
    // Save updated configuration to localStorage
    localStorage.setItem('columnsConfig', JSON.stringify(columns));
  };

  useEffect(() => {
    const getSavedColumnsConfig = () => {
      const savedConfig = localStorage.getItem('columnsConfig');
      return savedConfig ? JSON.parse(savedConfig) : columnsConfig;
    };
    setColumnsConfig(getSavedColumnsConfig())
  }, [])


  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      setPageNumber(1);
      setIsPageNumberReset(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      if (isPageNumberReset) {
        fetchRecordsBySearchTerms();
      }
    }
    else {
      setIsPageNumberReset(false);
      fetchData();
    }
  }, [pageNumber, searchTerm, isPageNumberReset]);

  useEffect(() => {
    const fetchUserSites = async () => {
      try {
        const data = await taxPortalClientApi.highQ_GetUserSites(userEmail);
        setUserSites(data);
      } catch (error) {
        console.error("Error fetching user sites:", error);
      }
    };

    fetchUserSites();
  }, [userEmail]);

  useEffect(() => {
    const webhookResponseHandler = async (response: string) => {

      const parsedResponse = JSON.parse(response);

      const matchFound = userSites.some((record) => record.id === parsedResponse.SiteId);

      if (matchFound) {
        fetchData();
      }
    };
    if (hubConnection) {
      hubConnection.on(signalRMethods.constroversy_change, webhookResponseHandler);
    }

    return () => {
      if (hubConnection) {
        hubConnection.off(signalRMethods.constroversy_change, webhookResponseHandler);
      }
    };
  }, [userSites, fetchData]);

  useEffect(() => {
    const fetchUserSites = async () => {
      try {
        const data = await taxPortalClientApi.GetCentralControversyTrackerUserSites(userEmail);
        setUserCentralControversySites(data);
        const siteIds = data.map(site => site.siteId)
        setSelectedSites(siteIds);
        setDeleteSyncEnabled(true);
      } catch (error) {
        console.error("Error fetching user sites:", error);
      }
    };

    fetchUserSites();
  }, [userEmail]);

  const sendRequest = async (sitesSelected: number[]) => {
    const data = sitesSelected.map(
      (siteId: number): ServiceMessage => ({
        siteId: siteId,
        userName: userEmail,
        eventType: ServiceBusEnum.CONTROVERSY_TRACKER_DELETED_RECORDS_SYNC,
        appName: "TP",
      })
    );
    try {
      showSnackbar({ message: SnackbarMessage.CONTROVERSY_TRACKER_DELETED_RECORDS});
      await taxPortalClientApi.taxPortal_InsertTopic(data);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    } 
  };

  const handleDeleteSync = () => {
    if (selectedSites) {
      sendRequest(selectedSites);
    }
  };

  const siteSelectedChange = (e: any) => {
    const updatedSelectedSites = e.target.value as number[];
    setSelectedSites(updatedSelectedSites);

    if (!updatedSelectedSites.length) {
      setDeleteSyncEnabled(false);
    } else {
      setDeleteSyncEnabled(true);
    }
  }

  return (
    <div style={{ marginRight: "20px" }}>
      <DataGrid
        id="grid-centralControversyTracker"
        keyExpr="hqSiteId"
        showBorders
        allowColumnResizing
        dataSource={gridData.CentralControversyTrackerData || []}
        height={calculateGridHeight(270)}
        remoteOperations
        onRowClick={(e: any) => handleRowClick(e.data.recordTPLink)}
        onRowPrepared={handleRowPrepared}
        noDataText={gridData.pending ? "Loading..." : "No Data"}
        onOptionChanged={handleColumnVisibilityChange}
      >
        <Scrolling columnRenderingMode="virtual" />
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <FilterRow visible />
        <HeaderFilter visible />
        <Paging enabled={false} />
        <Toolbar>
        <Item location="after">
            <FormControl size="medium" variant="outlined" sx={{ width: 300 }}>
              <FormLabel className="form-label">Sites</FormLabel>
              <Select
                style={{ height: 36, fontSize: "14px" }}
                multiple
                value={selectedSites}
                onChange={siteSelectedChange}

                MenuProps={{
                  style: {
                    maxHeight: 300,
                  },
                }}
                renderValue={(selected) => {
                  return selected.length === userCentralControversySites.length ? "All Sites Selected" : `${selected.length} Sites Selected`;
                }}
              >
                {userCentralControversySites.map(site => (
                  <MenuItem
                    key={site.siteId}
                    value={site.siteId}
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 10px",
                    }}
                    title={site.siteName}
                  >
                    <Checkbox
                      checked={selectedSites.indexOf(site.siteId) > -1}
                      style={{
                        padding: 0,
                        marginRight: 8,
                      }}
                    />
                    {site.siteName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item>
            <Button
              variant="outlined"
              disabled={!deleteSyncEnabled}
              onClick={handleDeleteSync}
            >
              Delete User Sync
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
          <Item name="columnChooserButton" showText="inMenu" />
        </Toolbar>

        {columns.map((col: any) => (
          <Column
            key={col.field}
            dataField={col.field}
            caption={col.caption}
            minWidth={col.minWidth}
            alignment={"left"}
            visible={col.isVisible}
          />
        ))}
        <ColumnChooser
          height='380px'
          enabled={true}
          mode='select'
          title="Select Columns"
          emptyPanelText="No Columns available"
          allowSearch={true}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />
        </ColumnChooser>
      </DataGrid>

      <div className="grid-pagination">
        <Pagination page={pageNumber} onChange={onChangePagination} count={totalPages} variant="outlined" shape="rounded" />
      </div>
    </div>
  );
};
