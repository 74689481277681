import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DataGrid, Column, Scrolling, Selection } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";

import { dataGridConfig } from "../../../../constants";

import WarningIcon from "@mui/icons-material/Warning";
import {
  changesSelected,
  detailsGridData,
  selectChangesProps,
  SelectedDetailsData,
  constants,
} from "../UpgradeSitesGrids.types";
import { areObjectsEqual, disableCheckbox, disableCheckboxes, selectParent, upsertData } from "./actions";

type DatagridRefType = DataGrid;

const initialStateData = { pending: true, changes: [] };
const { ALERT_MESSAGE, STATUSES } = constants;
const { SUCCESS } = STATUSES;

export const GridSelectChangesData = ({
  selectedChangesDetails,
  selectedDetails,
  setSelectedDetails,
  setDisabledChanges,
  upgradedDetails,
  prevDataDetails,
  parentComponent,
  isBatchUpgrade,
}: selectChangesProps) => {
  const [data, setData] = useState<changesSelected>(initialStateData);
  const [selectedData, setSelectedData] = useState<String[]>([]);
  const reviewDetailsGridRef = useRef<DatagridRefType>(null);
  const [keysToDisable, setKeysToDisable] = useState<string[]>([]);

  const { comparisonId: parentKey, detailSelected, upgradeWarning } = selectedChangesDetails;
  const { changeId, change, details } = detailSelected!;

  const fetchData = async () => {
    try {
      setData(initialStateData);
      const formatedDetails = formatData(details as string[]);
      const prevDetails = getCurrentPrevDetails();
      const dataDetails = prevDetails ? prevDetails.details : formatedDetails;
      setData({ pending: false, changes: dataDetails });
      const selected = setSelectedDataDetails(details as string[]);
      selectAndDisable(selected, dataDetails, details as string[]);
    } catch (error) {}
  };

  const getCurrentPrevDetails = () => {
    return prevDataDetails && prevDataDetails.length
      ? prevDataDetails.find((detail) => `${detail.comparisonId}${detail.changeId}` === `${parentKey}${changeId}`)
      : null;
  };

  const setSelectedDataDetails = (dataDetails: string[]) => {
    const selected = getSelected();
    const prevSelected = getSelected(upgradedDetails!);
    const newSelection = !selected.length ? (!prevSelected.length ? dataDetails : prevSelected) : selected;
    return newSelection;
  };

  const selectAndDisable = (selected: string[], formatedData: detailsGridData[], data: string[]) => {
    const disabled = formatedData.filter(({ status }) => status === SUCCESS).map((item) => item.name);
    const newSelected = selected.filter((sel) => !disabled.includes(sel));
    const selectRest = data.filter((sel) => !disabled.includes(sel));

    const sel = newSelected.length ? newSelected : selectRest;
    if (disabled.length === formatedData.length) disableParent();
    setSelectedData(sel);
    setKeysToDisable(disabled);
  };

  const disableParent = () => {
    disableCheckboxes(parentComponent!, [parentKey]);
    selectParent(parentComponent!, parentKey);
    setDisabledChanges((prevDisabled) => {
      if (!prevDisabled.includes(parentKey)) {
        return [...prevDisabled, parentKey];
      }
      return prevDisabled;
    });
  };

  const formatData = (data: string[]): detailsGridData[] => data.map((detail) => ({ name: detail, status: "" }));

  const onSelectionChanged = ({ currentDeselectedRowKeys, selectedRowKeys }: SelectionChangedEvent) => {
    setSelectedData(selectedRowKeys);
    if (!selectedRowKeys.length) {
      if (countDetails(currentDeselectedRowKeys) < 1) {
        clearAllDetails();
        selectParent(parentComponent!, parentKey);
        return;
      }
    } else {
      selectParent(parentComponent!, parentKey, true);
    }
    const newDetails = { comparisonId: parentKey, changeId, details: selectedRowKeys };
    if (!doesDetailIsAlreadySet(newDetails, selectedDetails!)) {
      updateDetailsData(newDetails, setSelectedDetails);
    }
  };

  const countDetails = (currentDeselectedRowKeys: detailsGridData[]): number => {
    const selDetails = selectedDetails!.filter((detail) => detail.comparisonId === parentKey);
    return selDetails!.reduce((total, item) => total + item.details.length, 0) - currentDeselectedRowKeys.length;
  };

  const doesDetailIsAlreadySet = (obj: SelectedDetailsData, arr: SelectedDetailsData[]): boolean => {
    return arr.some((item) => areObjectsEqual(item, obj));
  };

  const updateDetailsData = (newDetails: SelectedDetailsData, setterMethod: Dispatch<SetStateAction<any[]>>) => {
    setterMethod((prevChanges) => {
      const newChangesData = [...prevChanges];
      return upsertData(newChangesData, newDetails, "comparisonId", "changeId");
    });
  };

  const clearAllDetails = () => {
    setSelectedDetails((prevDetails) => {
      const newDetailsData = [...prevDetails];
      return newDetailsData.filter((detail) => detail.comparisonId !== parentKey);
    });
  };

  const getSelected = (selected: SelectedDetailsData[] = selectedDetails!) => {
    if (!selected.length) return [];
    const foundDetail = selected!.find(
      (detail) => `${detail.comparisonId}${detail.changeId}` === `${parentKey}${changeId}`
    );
    return foundDetail ? foundDetail.details : [];
  };

  const renderDetails = ({
    row: {
      data: { name },
    },
  }: any) => {
    if (upgradeWarning.includes(name)) {
      return <span className="alert-text">{name}</span>;
    }
    return <span>{name}</span>;
  };

  const onContentReady = () => {
    if (keysToDisable.length) {
      const component = reviewDetailsGridRef.current!.instance!;
      disableCheckboxes(component, keysToDisable);
      disableHeaderCheckbox(component);
    }
  };

  const disableHeaderCheckbox = (gridInstance: any) => {
    const headerView = gridInstance.getView("columnHeadersView");
    const cellElement = headerView.getCellElement(0, 0);
    disableCheckbox(cellElement);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DataGrid
        id="grid-review-changes"
        ref={reviewDetailsGridRef}
        keyExpr="name"
        {...dataGridConfig}
        dataSource={data.changes}
        noDataText={data.pending ? "Loading..." : "No data"}
        onSelectionChanged={onSelectionChanged}
        onContentReady={onContentReady}
        selectedRowKeys={selectedData}
        wordWrapEnabled
      >
        {!isBatchUpgrade ? <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} /> : null}
        <Scrolling mode="standard" />
        <Scrolling columnRenderingMode="virtual" />
        <Column dataField="name" caption={change} cellRender={renderDetails} minWidth={70} alignment="left" />
        <Column dataField="status" caption="Status" minWidth={70} alignment="left" />
      </DataGrid>

      <div className="alert-msg-modal alert-text">
        <WarningIcon />
        {ALERT_MESSAGE}
      </div>
    </>
  );
};
