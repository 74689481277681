import { useState, useEffect, useContext, useRef } from "react";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Selection,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import { useRecoilState, useRecoilValue } from "recoil";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { dataGridConfig, defaultPageSize } from "../../constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import useSnackbar from "../../hooks/useSnackbar";
import { createFolder } from "./CreateFolderAtoms";
import {
  GetAllSitesDTO,
  GetAllSitesInfoDTO,
  ServiceBusEnum,
  ServiceMessage,
  SyncTypeEnum,
} from "../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { createFolders } from "./CreateFolders.types";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { calculateGridHeight, convertUTCTimeToLocal } from "../../util/Utils";
import AppNameToggle, { getAppName } from "../../cui/commonComponents/AppNameToggle";
import { isEmpty, isNull } from "lodash";

type DatagridRefType = DataGrid;

export const CreateFolders = () => {
  const { user } = useContext(OidcIdentityContext);
  const [data, setData] = useRecoilState<createFolders>(createFolder);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropDown, setDropDown] = useState("");
  const dataGridRef = useRef<DatagridRefType>(null);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppName);
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>(null);
  const { showSnackbar } = useSnackbar();
  const { showSuccessSnackbar } = useSnackbar();

  const fetchSites = async () => {
    dataGridRef.current!.instance.clearSelection();
    try {
      if (dropDown === "Internal") {
        const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
        setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
        const data = await taxPortalClientApi.taxPortal_GetAllSites(
          SyncTypeEnum.CREATE_FOLDER_INTERNAL,
          null,
          appName !== null ? appName : "PERF",
          startIndex,
          defaultPageSize
        );
        setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
      }
      if (dropDown === "External") {
        const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
        setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });

        const data = await taxPortalClientApi.taxPortal_GetAllSites(
          SyncTypeEnum.CREATE_FOLDER_EXTERNAL,
          null,
          appName !== null ? appName : "PERF",
          startIndex,
          defaultPageSize
        );
        setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
      }
    } catch (error) {
      setData({ pending: false, fulfilled: false, sites: [], totalCount: 0 });
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const requestService = async (eventType: ServiceBusEnum, message: SnackbarMessage) => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType,
        mdmClientId: e.mdmClientId,
      };
    });
    try {
      showSnackbar({ message });
      await taxPortalClientApi.taxPortal_InsertTopic(data);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const handleSyncClick = async () => requestService(ServiceBusEnum.SYNC_MDM, SnackbarMessage.SYNC_MDM);
  const handleClick = async () => {
    if (dropDown === "External") {
      requestService(ServiceBusEnum.EXTERNAL_FOLDER_CREATION, SnackbarMessage.CREATE_FOLDER);
    } else if (dropDown === "Internal") {
      requestService(ServiceBusEnum.INTERNAL_FOLDER_CREATION, SnackbarMessage.CREATE_FOLDER);
    }
    setSelectedRows([]);
  };

  const handleClickForPermissions = async () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
      return {
        siteId: e.hqSiteId,
        userName: user?.profile.preferred_username,
        mdmClientId: e.mdmClientId,
      };
    });
    setSelectedRows([]);
    var response = await taxPortalClientApi.taxPortal_UpdateFolderPermission(data);
    if (response === true) {
      showSuccessSnackbar({ message: SnackbarMessage.FOLDER_PERMISSION_UPDATE });
    } else {
      showSnackbar({
        message: SnackbarMessage.FOLDER_PERMISSION_UPDATE_FAIL,
        severity: "error",
      });
    }
  };

  const handleClickForTaxYear = async () => {
    showSuccessSnackbar({ message: SnackbarMessage.FOLDER_TAXYEAR_UPDATE ,severity: "info"});
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const data = selectedData.map((e: GetAllSitesInfoDTO): ServiceMessage => {
      return {
        siteId: e.hqSiteId,
        mdmClientId: e.mdmClientId,
      };
    }); 
    setSelectedRows([]);
     var response = await taxPortalClientApi.taxPortal_UpdateTaxYearInFolderMetaData(data);
    if (response === true) {
      showSuccessSnackbar({ message: SnackbarMessage.FOLDER_TAXYEAR_PROGRESS ,severity: "info"});
    } else {
      showSnackbar({
        message: SnackbarMessage.FOLDER_TAXYEAR_UPDATE_FAIL,
        severity: "error",
      });
    }
  };

  const onSelectionchanged = (e: { selectedRowsData: any }) => {
    setSelectedRows(e.selectedRowsData);
  };

  const cellRenderForMessage = (e: { data: GetAllSitesInfoDTO }) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };

  const cellRenderForDate = (date: string) => {
    return <div>{convertUTCTimeToLocal(date)}</div>;
  };

  useEffect(() => {
    if (searchTerm === null || searchTerm?.length === 0) {
      fetchSites();
    }
  }, [dropDown, pageNumber, appName, searchTerm]);

  useEffect(() => {
    setDropDown("External");
  }, []);
  const names = ["External"];

  const handleChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
    dataGridRef.current!.instance.clearSelection();
  };
  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      setSearchTerm(null);
      if (dataGridRef.current) {
        dataGridRef.current.instance.clearFilter();
      }
      fetchSites();
    },
  };

  const fetchSitesBySearchTerms = async () => {
    try {
      const syncType =
        dropDown === "Internal"
          ? SyncTypeEnum.CREATE_FOLDER_INTERNAL
          : dropDown === "External"
          ? SyncTypeEnum.CREATE_FOLDER_EXTERNAL
          : null;

      if (!syncType) return;

      setData({ pending: false, fulfilled: true, sites: [], totalCount: 0 });

      const res = await taxPortalClientApi.taxPortal_GetAllSiteSearch(
        syncType,
        appName !== null ? appName : "PERF",
        searchTerm
      );

      setData({
        pending: false,
        fulfilled: true,
        sites: res?.data || [],
        totalCount: res?.totalCount || 0,
      });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  useEffect(() => {
    if (!isNull(searchTerm) && !isEmpty(searchTerm)) {
      fetchSitesBySearchTerms();
    }
    // eslint-disable-next-line
  }, [dropDown, appName, searchTerm]);

  return (
    <div style={{ margin: "16px" }}>
      <div
        style={{
          display: "flex",
          margin: "10px 0 10px 0",
        }}
      >
        <h2>Create Folders</h2>
        <AppNameToggle />
      </div>
      <div>
        <DataGrid
          height={calculateGridHeight(270)}
          onSelectionChanged={onSelectionchanged}
          selectedRowKeys={selectedRows}
          {...dataGridConfig}
          dataSource={data.sites}
          noDataText={data.pending ? "Loading..." : "No data"}
          allowColumnResizing={true}
          ref={dataGridRef}
        >
          <Selection mode="multiple" selectAllMode={"allPages"} showCheckBoxesMode={"onClick"} />
          <Scrolling mode="standard" />
          <LoadPanel enabled={false} />
          <SearchPanel
            placeholder={"Global Search..."}
            onTextChange={(e) => {
              setSearchTerm(e);
            }}
            visible
          />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          {/* <Paging enabled={true} defaultPageSize={20} /> */}
          <Scrolling columnRenderingMode="virtual" />
          <Toolbar>
            {/* <Item location="after">
              <Button onClick={handleSyncClick} disabled={!selectedRows.length} variant="outlined">
                Sync MDM
              </Button>
            </Item> */}
            <Item location="after">
              <FormControl size="small" variant="outlined" sx={{ width: 140 }}>
                <Select  style={{ height: 36, fontSize: "15px" }} defaultValue="External" onChange={handleChange}>
                  {names.map((name) => (
                    <MenuItem key={name} value={name} style={{ fontSize: "14px" }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item location="after">
              <Button variant="outlined" disabled={!(selectedRows.length && appName !== "CS")} onClick={handleClick}>
                Create/Update Folders
              </Button>
            </Item>
            <Item location="after">
              <Button variant="outlined" disabled={!(selectedRows.length && appName !== "CS")} onClick={handleClickForPermissions}>
                Update Permissions
              </Button>
            </Item>
            <Item location="after">
              <Button variant="outlined" disabled={!(selectedRows.length && appName !== "CS")} onClick={handleClickForTaxYear}>
                Update Folder MetaData
              </Button>
            </Item>
            <Item name="searchPanel" location="after" />
            <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
          </Toolbar>
          <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"} />
          <Column dataField="hqClientName" caption={"SiteName"} />
          <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderForDate(e.data.siteCreated)}
            dataField="Site Created Date / Time"
            caption={"Site Created Date / Time"}
          />
          <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
          <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderForDate(e.data.lastModified)}
            dataField="Last Update Date/Time"
            caption={"Last Update Date/Time"}
          />
        </DataGrid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 0 0 0",
          }}
        >
          <Pagination
            onChange={(e: any, pageNumber: any) => {
              setpageNumber(pageNumber);
            }}
            count={Math.ceil((data.totalCount as number) / defaultPageSize)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};
