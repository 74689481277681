import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { GetSiteDBDetails } from "../../../api-client-nswag/taxportal-client";
import { selector, useRecoilValue } from "recoil";
import {
  getSiteCreationResponse,
  getSiteExistResponse,
} from "../SitesWithLegalEntities";
import { isEmpty, isNull } from "lodash";

const isSiteSiteExistSelector = selector({
  key: "getSiteCreationResponse",
  get: ({ get }) => {
    const text = get(getSiteCreationResponse);
    return text;
  },
});
const getSiteExistResponseSelector = selector({
  key: "getSiteExistResponse",
  get: ({ get }) => {
    const text = get(getSiteExistResponse);
    return text;
  },
});

const CustomizeSiteName = (props: any) => {
  const [text, setText] = useState("");
  const [wholeText, setWholeText] = useState("");
  const [isSpecialCharacter, setisSpecialCharacter] = useState(false);
  //const [siteData, setSiteData] = useState<GetSiteDBDetails[]>([]);

  useEffect(() => {
    props.setChecked(true)
    if (text.length === 0) {
      const data = props.dataPermission.map((a: any) => {
        a.hqClientName =
          props.selectedRowsData?.name +
          " (" +
          props.selectedRowsData?.mdmClientId +
          ")";
        return a;
      });
      props.setDataPermission(data);
      setText(props.selectedRowsData?.name.split(" (")[0]);
      const regex = /^[^*:/\\?|'"]+$/
      if (!regex.test(props.selectedRowsData?.name.split(" (")[0] + '-'))
      {     
        props.setBtnContinueDisable(true)
        setisSpecialCharacter(true)
      }
    }
  }, []);
  useEffect(() => {
    const baseName = props.selectedRowsData?.name;
    const cleanText =
      text.endsWith("-") && text === `${baseName}-` ? baseName : text;

    const textWithClientId = `${cleanText} (${props.selectedRowsData?.mdmClientId})`;
    setWholeText(textWithClientId);
    if (wholeText.length > 0) {
      const data = props.dataPermission.map((a: any) => {
        a.hqClientName = `${wholeText}`;
        return a;
      });
      //const isFlag = siteData.find((e) => {});
      props.setDataPermission(data);
    }
  }, [text, props.selectedRowsData?.name, wholeText]);

  const  onChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[^*:/\\?|'"]+$/
    if (regex.test(inputValue))
    {     
      props.setIsFlag(false);
      setisSpecialCharacter(false)
      props.setBtnContinueDisable(false)
    }
    else{
      props.setBtnContinueDisable(true)
      setisSpecialCharacter(true)
    }
    setText(inputValue)
    // const regex = /^[ A-Za-z0-9_.-]*$/;
    // if (regex.test(inputValue)) {
    //   setText(inputValue);
    // }
  };


  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = await taxPortalClientApi.highQ_GetSiteFromTrack(null, null);
  //     setSiteData(data);
  //   };
  //   fetch();
  // }, []);
  const message = useRecoilValue(getSiteExistResponseSelector);
  const flag = useRecoilValue(isSiteSiteExistSelector);
  return (
    <>
      <div>Instructions...</div>
      <div>
        <ul>
          <li>
            {
              "No special characters can be used except (-_!@#$%^&+{}[];><.,()=)"
            }
          </li>
          <li>Duplicate site names are not allowed.</li>
          <li>
            {
              "Site name should not be exceeded 66 characters (Should be <= 66, including client ID.)"
            }
          </li>
          <li>
            {
              "Please keep the MDM master client name and change only if you are setting  up a site for legal entity."
            }
          </li>
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: "16px 0 0 0",
        }}
      >
        <TextField
          inputProps={{ maxLength: 56 }}
          id="outlined-basic"
          variant="outlined"
          style={{ width: "30%" }}
          onChange={onChange}
          value={text}
        />
        <div
          style={{
            fontWeight: "bold",
            color: "#009CDE",
            marginLeft: "16px",
            fontSize: "14px",
          }}
        >
          {66 - wholeText.length} characters left
        </div>
      </div>
      {flag === true ? (
        <div style={{ color: "red", fontSize: "14px" }}>{message}</div>
      ) : null}
            {isSpecialCharacter === true ? <div style={{ color: "red", fontSize: "14px" }}>{"The Sitename contains unallowed special characters. Please remove them to continue."}</div> : null}
    </>
  );
};

export default CustomizeSiteName;
