import { IManageFilesDTO } from "./IManageFilesTypes";

 function findParent(item: any, files: IManageFilesDTO[]): IManageFilesDTO | undefined {
    if (!item.parentId) {
      return undefined;
    }

    function search(files: IManageFilesDTO[]): IManageFilesDTO | undefined {
      for (const file of files) {
        if (String(file.id) === String(item.parentId)) {
          return file;
        }

        if (file.items && file.items.length > 0) {
          const found = search(file.items);
          if (found) return found;
        }
      }
      return undefined;
    }
    return search(files);
  }

  function findiManageParent(item: any): any | undefined {
    if (!item.parent) {
      return undefined;
    }

    return item.parent;
  }
  
  export function getFilePath(item: any, files: IManageFilesDTO[]): string {
    let currentItem = item;
    let path: string[] = [];


    while (currentItem.parentId && currentItem.parentId !== "") {
      if (currentItem.itemType === 'folder') {
        path.unshift(currentItem.text);
      }
      const parent = findParent(currentItem, files);  // Find the paren
      if (!parent) {
        break;
      }
      currentItem = parent;
    }
    if (currentItem.itemType === 'folder') {
      path.unshift(currentItem.text);
    }

    return `/${path.join('/')}`;

  }

  export function getFolderPath(item: any): any {
    let currentItem = item;
    let path: string[] = [];
    const regex = /\((\d+)\)/;
    let workspaceId = null;

    if (currentItem.text) {
      path.unshift(currentItem.text);
    }

    while (currentItem.parent && currentItem.parent !== null) {
      const parent = findiManageParent(currentItem);
      if (!parent) {
        break;
      }

      if (parent.text && regex.test(parent.text)) {
        path.unshift(parent.text);
        workspaceId = parent.key;
        break;
      }

      if (parent.text) {
        path.unshift(parent.text);
      }

      currentItem = parent;
    }
    return {
      path: `%2F${path.join('%2F')}`,
      id: workspaceId
    }
  }

  export function findHighQEntity(item: any, files: IManageFilesDTO[]): { id: string, text: string } | undefined {
    let currentItem = item;

    const regex = /\((\d+)\)/;

    while (currentItem.parentId && currentItem.parentId !== "") {
      if (currentItem.itemType == 'folder' && regex.test(currentItem.text)) {
        const match = currentItem.text.match(regex);
        if (match) {
          const id = match[1];
          return { id, text: currentItem.text };
        }
      }

      const parent = findParent(currentItem, files);
      if (!parent) {
        break;
      }
      currentItem = parent;
    }

    if (currentItem.itemType == 'folder' && regex.test(currentItem.text)) {
      const match = currentItem.text.match(regex);
      if (match) {
        const id = match[1];
        return { id, text: currentItem.text };
      }
    }

    return undefined;
  }