import { useEffect, useRef, useState } from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import IconButton from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

import { ChangesDetail, HQSiteChangesDetailDTO, HQSiteChangesDTO } from "../../../api-client-nswag/taxportal-client";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { executeDownload } from "../../../util/Utils";
import { SnackbarMessage, useSnackbar } from "../../../hooks";

import { ModalReviewChanges } from "./ModalReviewChanges";
import { Modal } from "../Modal/Modal";

import { ModalSitesChangesProps, SelectedChangeData, changesDetailData, constants } from "./UpgradeSitesGrids.types";

type DatagridRefType = DataGrid;
const { DETAILS_TITLE, FILE_SITE_UPGRADE_NAME, EXCEL_FILE_EXTENSION } = constants;
const initialStateData = { pending: true, changes: [] };

export const ModalSiteChangesDetail = ({
  handleClose,
  isDisplayModal,
  idUpgradeSiteTracks,
}: ModalSitesChangesProps) => {
  const detailsGridRef = useRef<DatagridRefType>(null);
  const { showSnackbar } = useSnackbar();
  const [changesDetailData, setChangesDetailData] = useState<changesDetailData>(initialStateData);
  const [isDisplayDetailsModal, setIsDisplayDetailsModal] = useState(false);
  const [selectedChange, setSelectedChange] = useState<SelectedChangeData>();
  const [excelData, setExcelData] = useState<HQSiteChangesDTO>();

  const fetchData = async () => {
    try {
      if (!idUpgradeSiteTracks) {
        setChangesDetailData({ pending: false, changes: [] });
        return;
      }
      setChangesDetailData(initialStateData);
      const changes = await taxPortalClientApi.highQ_GetSitesUpgradeDetailStatus(String(idUpgradeSiteTracks));
      setExcelData(changes);
      const { siteChanges } = changes;
      setChangesDetailData({ pending: false, changes: siteChanges });
    } catch (error) {}
  };

  const handleSeeDetailsButton = (data: HQSiteChangesDetailDTO, changeDetail: ChangesDetail) => {
    setSelectedChange({ ...data, detailSelected: changeDetail });
    setIsDisplayDetailsModal(true);
  };

  const handleExportButton = async () => {
    if (!excelData) return;
    try {
      showSnackbar({ message: SnackbarMessage.DOWNLOAD_MESSAGE });
      const base64String = await taxPortalClientApi.HQTemplateSiteExcelExport(excelData);
      if (base64String)
        executeDownload(base64String, String(excelData!.siteId), FILE_SITE_UPGRADE_NAME, EXCEL_FILE_EXTENSION);
    } catch (error) {
      showSnackbar({
        message: SnackbarMessage.DOWNLOAD_FAILED.replace("%site%", String(excelData!.siteId)) as SnackbarMessage,
        severity: "error",
      });
    }
  };

  const handleCloseDetailsModal = () => {
    setIsDisplayDetailsModal(false);
  };

  const renderDetails = ({ row: { data } }: any) => {
    if (!data.changesDetail) return;
    return data.changesDetail.map((detail: ChangesDetail) => (
      <div className="changeRow">
        <div className="change">{detail.change}</div>

        <div className="more-info">
          <IconButton aria-label="More Info" title="More Info" onClick={() => handleSeeDetailsButton(data, detail)}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    fetchData();
  }, [idUpgradeSiteTracks]);

  return (
    <Modal
      title={DETAILS_TITLE}
      maxWidth="xl"
      btnOpenText="Export to Excel"
      handleContinue={handleExportButton}
      btnCloseText="Cancel"
      isDisplayModal={isDisplayModal}
      handleClose={handleClose}
    >
      <>
        <DataGrid
          id="grid-modal-changes"
          ref={detailsGridRef}
          keyExpr="comparisonId"
          allowColumnResizing
          dataSource={changesDetailData.changes}
          noDataText={changesDetailData.pending ? "Loading..." : "No data"}
          showBorders
          wordWrapEnabled
        >
          <Column dataField="module" width="150px" />
          <Column dataField="change" />
          <Column dataField="object" />
          <Column dataField="details" cellRender={renderDetails} caption="Changes detail" />
          <Column dataField="status" width="100px" />
        </DataGrid>

        <ModalReviewChanges
          selectedChangesDetails={selectedChange!}
          isDisplayModal={isDisplayDetailsModal}
          handleClose={handleCloseDetailsModal}
          isReviewOnly
          isBatchUpgrade
        />
      </>
    </Modal>
  );
};
