import { useEffect, useState } from "react";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { HQSiteDetailsDTO } from "../../api-client-nswag/taxportal-client";

import useSnackbar from "../../hooks/useSnackbar";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { defaultPageSize, UPGRADE_SITES_SCREENS } from "../../constants";
import { SitesSelected } from "../../cui/components/ManageSitesComponents/SitesSelected";
import { SitesGrid } from "../../cui/components/ManageSitesComponents/SitesGrid";
import { constants, upgradeSites } from "./UpgradeSites.types";
import UpgradeSitesToggle, { getAppName } from "../../cui/commonComponents/UpgradeSitesToggle";
import { useRecoilValue } from "recoil";
import BatchSitesSelected from "../../cui/components/ManageSitesComponents/BatchSitesSelected";

const initialStateData = { pending: true, sites: [], totalCount: 0 };
const { PAGE_TITLE } = constants;

export const UpgradeSites = () => {
  const { showSnackbar } = useSnackbar();
  const [sitesData, setSitesData] = useState<upgradeSites>(initialStateData);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [selectedSitesData, setSelectedSitesData] = useState<HQSiteDetailsDTO[]>([]);
  const [dropDown, setDropDown] = useState<string>("");
  const [hideSelectedGrid, setHideSelectedGrid] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [activeScreen, setActiveScreen] = useState("Upgrade_Sites");
  const [IsBatchScreenVisible, setIsBatchScreenVisible] = useState(false);
  const [batchGridData, setBatchGridData] = useState<any[] | undefined>([]);
  const [isBatchUpgrade, setIsBatchUpgrade] = useState(false);
  const appName = useRecoilValue(getAppName);
  const toggleSitesGrids = (hideSelected: boolean) => {
    setHideSelectedGrid(hideSelected);
  };

  const toggleBatchUpgradeSitesGrids = (IsBatchVisible: boolean) => {
    setIsBatchScreenVisible(IsBatchVisible);
  };
  const IsBatchUpgrade = (batchUpgrade: boolean) => {
    setIsBatchUpgrade(batchUpgrade);
  };
  const fetchData = async () => {
    setSitesData((prevData: upgradeSites) => {
      return { ...prevData, pending: true, sites: [] };
    });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      setSitesData(initialStateData);
      const { sitesUpgradeList, totalCount } = await taxPortalClientApi.highQ_GetSitesUpgradeByStatus(
        null,
        null,
        startIndex,
        defaultPageSize,
        appName
      );
      setSitesData({ pending: false, sites: sitesUpgradeList, totalCount });
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
    }
  };

  const fetchSitesBySearchTerms = async () => {
    setSitesData({ pending: true, sites: [], totalCount: 0 });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const { sitesUpgradeList, totalCount } = await taxPortalClientApi.highQ_GetSitesUpgradeBySearch(
        null,
        null,
        searchTerm,
        appName,
        startIndex,
        defaultPageSize
      );
      if (sitesUpgradeList.length) {
        setSitesData({ pending: false, sites: sitesUpgradeList, totalCount });
      } else {
        setSitesData({ ...initialStateData, pending: false });
      }
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      setSitesData(initialStateData);
    }
  };
  const renderGrids = () => {
    if (activeScreen === UPGRADE_SITES_SCREENS.UPGRADE_SITES && hideSelectedGrid) {
      {
        return (
          <SitesGrid
            sitesData={sitesData}
            selectedSites={selectedSites}
            setSelectedSites={setSelectedSites}
            setSelectedSitesData={setSelectedSitesData}
            dropDown={dropDown}
            setDropDown={setDropDown}
            fetchData={fetchData}
            toggleSitesGrids={toggleSitesGrids}
            setPageNumber={setPageNumber}
            setSearchTerm={setSearchTerm}
            setActiveScreen={setActiveScreen}
            toggleBatchUpgradeSitesGrids={toggleBatchUpgradeSitesGrids}
            batchGridData={batchGridData}
            setBatchGridData={setBatchGridData}
            setIsBatchUpgrade={setIsBatchUpgrade}
            IsBatchUpgrade={IsBatchUpgrade}
            pageNumber={pageNumber}
          />
        );
      }
    }
    if (activeScreen === UPGRADE_SITES_SCREENS.SITES_SELECTED && !hideSelectedGrid && !IsBatchScreenVisible) {
      return (
        <SitesSelected
          selectedSitesData={selectedSitesData}
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          templateSelected={dropDown}
          toggleSitesGrids={toggleSitesGrids}
          setActiveScreen={setActiveScreen}
          isBatchUpgrade={isBatchUpgrade}
        />
      );
    }
    if (activeScreen === UPGRADE_SITES_SCREENS.BATCH_SITES_SELECTED && IsBatchScreenVisible) {
      return (
        <BatchSitesSelected
          selectedSitesData={selectedSitesData}
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
          templateSelected={dropDown}
          setActiveScreen={setActiveScreen}
          toggleSitesGrids={toggleSitesGrids}
          batchGridData={batchGridData}
          setBatchGridData={setBatchGridData}
          isBatchUpgrade={isBatchUpgrade}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />
      );
    }
  };
  useEffect(() => {
    if (searchTerm) {
      fetchSitesBySearchTerms();
    }
  }, [searchTerm, appName, pageNumber]);

  useEffect(() => {
    if (hideSelectedGrid && !searchTerm) fetchData();
  }, [hideSelectedGrid, pageNumber, searchTerm, appName]);

  return (
    <div style={{ margin: "16px" }}>
      <div
        style={{
          display: "flex",
          margin: "10px 0 10px 0",
        }}
      >
        <h2>{PAGE_TITLE}</h2>
        {activeScreen === "Upgrade_Sites" ? <UpgradeSitesToggle /> : null}
      </div>
      {renderGrids()}
    </div>
  );
};
