import { TreeViewProps } from "../../cui/components/TreeView/TreeViewTypes";
import { Descriptions } from "../../api-client-nswag/taxportal-client";
import { ItemClickEvent } from "devextreme/ui/tree_view";
const { IMANAGE_FILES, TAXPORTAL_FOLDERS } = Descriptions;

export interface IManageFilesDTO {
  id?: string;
  text?: string;
  siteid?: string;
  showMoreLimit?: number;
  files?: IManageFilesDTO[];
  icon?: string;
  expanded?: boolean;
  hasItems?: boolean;
  parentId?: string;
  itemPath?: string;
  itemType?: string;
  items?: IManageFilesDTO[];
  level?: number;
  itemData?: IManageFilesDTO;
}

export interface IManageFilesState {
  pending: boolean;
  fulfilled: boolean;
  data: IManageFilesDTO[];
}

const treeProps: TreeViewProps = {
  id: "iManage",
  title: IMANAGE_FILES,
  width: 600,
  height: "50vh",
  searchMode: "contains",
  searchEnabled: true,
  showCheckBoxesMode: "normal",
  selectionMode: "multiple",
  selectByClick: true,
};

export const iManageProps: TreeViewProps = {
  ...treeProps,
  selectedCounter: "0",
};

export const taxPortalProps: TreeViewProps = {
  ...treeProps,
  id: "TaxPortal",
  title: TAXPORTAL_FOLDERS,
  selectionMode: "single",
};

export interface IManageComponentProps {
  onItemExpanded: (e: ItemClickEvent<any>) => void;
  showMoreLabel: (element: any) => void;
  filesToMove: IManageFilesState;
  foldersToShow: IManageFilesState;
  siteID: number;
  onSourceBoolChange: (isSource: boolean, isTPSource: boolean) => void;
}

export enum IManageFilesVars {
  SHOW_MORE_MIN_LIMIT = 3,
  CONFLICT_ERROR = 409,
}

export interface EditFileState {
  id: number;
  newFileName: string | null;
}
export interface DialougeProps {
  open: boolean;
  handleClose: () => void;
  handleContinue: () => void;
}
