import { atom } from "recoil";

export const dataGridConfig = {
  renderAsync: false,
  showBorders: true,
  repaintChangesOnly: true,
  hoverStateEnabled: true,
  columnAutoWidth: false,
  showRowLines: false,
  showColumnLines: true,
  allowColumnResizing: true,
  columnMinWidth: 100,
};

export const permissionFlag = {
  permission: true,
};

export const defaultPageSize = 20;

export const signalRMethods = {
  info: "info",
  error: "error",
  success: "success",
  ir_success: "irReport",
  ps_success: "psReport",
  constroversy_change: "ControversyChange"
};

export const refreshFlagAtom = atom({
  key: "refreshFlag",
  default: false,
});

export const upgradeSitesResponseAtom = atom({
  key: "upgradeSitesResponse",
  default: "",
});

export const dateCellPicker = {
  DATE_OPTION: "date" as "date",
  DATE_TIME_OPTION: "datetime" as "datetime",
  DATE_TIME_FORMAT: "MMM d, yy hh:mm:ss a",
  DATE_FORMAT: "MMM d, yyyy",
};

export const alert_Dialog_Discription =
  "You are about to change the source of the file transfer. Doing so will clear your current selection. Do you wish to continue?";

export const IMANAGEFILE_TITLE = "Select files from iManage to copy to Tax Portal";

export const IMANAGEFILE_SWAP_TITLE = "Select files from Tax Portal to copy to iManage";
export const UPGRADE_SITES_SCREENS = {
  UPGRADE_SITES: "Upgrade_Sites",
  SITES_SELECTED: "SitesSelected",
  BATCH_SITES_SELECTED: "BatchSitesSelected",
};
